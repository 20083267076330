import { Modal, Tabs, Tab  } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EditUserRemainingSessions from "./components/EditUserRemainingSessions";
import EditUserRoleForm from "./components/EditUserRoleForm";

const UserEditionModal = ({ show, handleClose, user, toReload, setToReload }) => {
  const { t } = useTranslation("admin");

  const reload = () => {
    setToReload(!toReload);
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("edit_user")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="profile" id="uncontrolled-tab" className="mb-3">
          <Tab eventKey="date" title={t("edit_user_role")}>
            <EditUserRoleForm user={user} reload={reload} handleClose={handleClose} />
          </Tab>
          <Tab eventKey="link" title={t("edit_remaining_sessions")}>
            <EditUserRemainingSessions user={user} reload={reload} handleClose={handleClose} />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default UserEditionModal;
