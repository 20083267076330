import moment from "moment";
import "moment/locale/fr";
import { Card, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import "./SubscriptionCard.scss";

const SubscriptionCard = () => {
  const { t } = useTranslation("profile");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [noDuplicateCourses, setNoDuplicateCourses] = useState(null);
  const [userProgression, setUserProgression] = useState(null);

  const getTotalProgession = (arrayOfProgressStates) => {
    const arrayOfProgressions = arrayOfProgressStates.map(
      (state) => state.progression
    );
    const totalValueOfProgression = arrayOfProgressions.reduce(
      (total, currentValue) => total + currentValue,
      0
    );
    const finalPercetentageOfAdvancement = Math.round(
      totalValueOfProgression / arrayOfProgressions.length
    );

    setUserProgression(finalPercetentageOfAdvancement);
  };

  useEffect(() => {
    let uniqCoursesSubscriptions = [];

    if (currentUser && currentUser.subscriptions && currentUser.subscriptions.length > 0) {
      currentUser.subscriptions.map((subscription) => {
        subscription.learning_path.courses &&
          subscription.learning_path.courses.map((course) =>
            uniqCoursesSubscriptions.push({
              ...course,
            })
          );
      });
    }

    const getUniqueListBy = (arr, key) => {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    };

    setNoDuplicateCourses(getUniqueListBy(uniqCoursesSubscriptions, "id"));
  }, [currentUser]);

  useEffect(() => {
    let toKeepProgressStates = [];
    if (noDuplicateCourses && noDuplicateCourses?.length > 0) {
      noDuplicateCourses.map((course) => {
        let courseProgressState = currentUser.progress_states.find(
          (state) =>
            Number(state.user_id) === Number(currentUser.id) &&
            Number(state.course_id) === Number(course.id)
        );
        courseProgressState && toKeepProgressStates.push(courseProgressState);
      });
    }
    getTotalProgession(toKeepProgressStates);
  }, [noDuplicateCourses]);

  return (
    <Card className="subscription-card p-4 mt-3" style={{ minHeight: "100%" }}>
      <h4 className="mb-4 subscription-title">{t("my_subscription")} 🎯</h4>
      <h6 className="mb-3 text-center">
        <span className="bolded-span">{t("start")} : </span>
        {moment(currentUser.created_at).locale("fr").format("DD MMMM YYYY")}
      </h6>
      {currentUser && currentUser.account_limit_validity_date && (
        <h6 className="mb-3 text-center">
          <span className="bolded-span">{t("end")} : </span>
          {moment(currentUser.account_limit_validity_date)
            .locale("fr")
            .format("DD MMMM YYYY")}
        </h6>
      )}
      <div>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <h6 className="text-center">{t("my_personal_advancement")} :</h6>
          <p className="advancement-percent small ml-1 mb-2">
            {`${userProgression || 0} %`}{" "}
            {`${userProgression && userProgression > 0 ? "🔥" : "😕"}`}
          </p>
        </div>
        <div className="d-flex flex-column align-items-center mt-3">
          <ProgressBar
            className="w-100 progressbar-subscription"
            now={userProgression || 0}
          />
        </div>
      </div>
    </Card>
  );
};

export default SubscriptionCard;
