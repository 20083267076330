import useFetch from "hooks/useFetch";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BootcampSessionLine from "./components/BootcampSessionLine";

const ChooseABootcampSessionToManage = () => {
  const { get, destroy, isLoading } = useFetch();
  const { t } = useTranslation("admin");
  const [bootcampSessions, setBootcampSessions] = useState(null);
  const [toReload, setToReload] = useState(false);

  const getBootcampSessions = async () => {
    const response = await get("/admin/bootcamp_sessions");
    setBootcampSessions(response);
  };

  const reload = () => {
    setToReload(!toReload);
  };

  const handleDelete = (sessionId) => {
    destroy(`/admin/bootcamp_sessions/${sessionId}`, reload, true);
  };

  useEffect(() => {
    getBootcampSessions();
  }, []);

  useEffect(() => {
    getBootcampSessions();
  }, [toReload]);

  console.log(bootcampSessions);

  return (
    <div>
      <h3 className="text-center">{t("manage_bootcamp")}</h3>
      {bootcampSessions && !isLoading ? (
        <Table striped bordered hover responsive className="my-4">
          <thead>
            <tr>
              <th>{t("id")}</th>
              <th>{t("company")}</th>
              <th>{t("start_date")}</th>
              <th>{t("end_date")}</th>
              <th>{t("update")}</th>
              <th>{t("delete")}</th>
            </tr>
          </thead>
          <tbody>
            {bootcampSessions &&
              bootcampSessions.map((bootcampSession) => (
                <BootcampSessionLine
                  key={bootcampSession.id}
                  bootcampSession={bootcampSession}
                  handleDelete={handleDelete}
                  reload={reload}
                />
              ))}
          </tbody>
        </Table>
      ) : null}
      {isLoading && <div className="text-center my-5">{t("loading")}</div>}
    </div>
  );
};

export default ChooseABootcampSessionToManage;
