import MentoringSessionCard from "components/MentoringSessionCard/MentoringSessionCard";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Container, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const MentorIndex = () => {
  const { t } = useTranslation("profile");
  const { get, isLoading } = useFetch();
  const [mentors, setMentors] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const getMentors = async () => {
      const response = await get("/users?is_teacher=true&minimal=true");
      console.log(response);
      setMentors(response);

    };
    getMentors();
  }, []);

  const displaySelectedMentorProfile = (event, mentor_id) => {
    if (mentor_id === undefined) return;
    setSelectedMentor(mentor_id);
    history.push({
      pathname: `/displaymentor/${mentor_id}`,
      state: { selectedMentor: mentor_id },
    });
  };

  useEffect(() => {
    console.log(mentors);
  }, [mentors]);

  return (
    <div>
      <h1 className="d-flex justify-content-center my-3">
        {t("discover_our_mentors")} 👨‍🏫
      </h1>
      <Container>
        <Row>
          {mentors && mentors.length > 0 ? (
            mentors.map((mentor) => (
              <Card
                key={mentor.id}
                className="my-3 mx-2"
                style={{ width: "20rem", cursor: "pointer" }}
                onClick={(e) => displaySelectedMentorProfile(e, mentor.id)}
              >
                <Card.Img
                  style={{ height: "250px", objectFit: "cover" }}
                  variant="top"
                  src={
                    mentor.picture_url
                      ? mentor.picture_url
                      : "https://png.pngtree.com/png-vector/20190710/ourmid/pngtree-user-vector-avatar-png-image_1541962.jpg"
                  }
                />
                <Card.Body>
                  <Card.Title
                    style={{ fontWeight: "bolder", fontSize: "1.4rem" }}
                  >
                    {mentor.first_name} {mentor.last_name}
                  </Card.Title>
                  <Card.Title
                    className="my-3"
                    style={{ fontSize: "1rem", fontWeight: "bolder" }}
                  >
                    {mentor.mentor_profile.job}
                  </Card.Title>
                  <Card.Text>{mentor.mentor_profile.presentation}</Card.Text>
                  <Card.Text className="mt-3">
                    {mentor.skills.map((skill) => (
                      <span
                        key={skill.id}
                        className="badge badge-warning my-2 mx-1"
                        style={{
                          fontSize: "0.9rem",
                          borderRadius: 0,
                          backgroundColor: "#fff7df",
                        }}
                      >
                        {skill}
                      </span>
                    ))}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p className="mx-auto">{t("admin:loading")}</p>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default MentorIndex;
