import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { useSelector } from "react-redux";
import LearningPathCard from "components/LearningPathCard/LearningPathCard";
import "./LearningPaths.scss";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Loading from "components/Loading";
import * as authActions from "stores/authentication/authActions";
import SideMenu from "components/SideMenu/SideMenu";
import { useComponentWillMount } from "hooks/useComponentWillMount";
import { useHistory } from "react-router";
import CategoryLearningPath from "./CategoryLearningPath/CategoryLearningPath";

const LearningPaths = ({ currentLanguageId }) => {
  const { data, get, isLoading } = useFetch();
  const [input, setInput] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isCompany = useSelector((state) => state.auth.isCompany);
  const history = useHistory();

  const handleCategoryFilter = (list) => {
    setCategoryList(list);
  };

  useEffect(() => {
    if (currentUser && !isCompany) {
      get(
        `/learning_paths?single=false&categories=${categoryList.join(
          ", "
        )}&current_user=${currentUser.id}&language=${currentLanguageId}`
      );
    } else {
      get(
        `/learning_paths?single=false&categories=${categoryList.join(
          ", "
        )}&language=${currentLanguageId}`
      );
    }
  }, [categoryList, currentUser, currentLanguageId]);

  const updateDeviceSize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateDeviceSize);
    updateDeviceSize();
  }, []);

  useComponentWillMount(() =>
    window.removeEventListener("resize", updateDeviceSize)
  );

  return (
    <div className="LearningPaths">
      <Row style={{ minHeight: "90vh" }}>
        <Col className="col-lg-3 sidemenu d-flex flex-column justify-content-center align-items-center">
          <SideMenu
            setInput={setInput}
            bottomComponent={
              <CategoryLearningPath
                handleCategoryFilter={handleCategoryFilter}
              />
            }
          />
        </Col>

        {(isLoading && <Loading isMobile={isMobile} />) || (
          <Col className="col-lg-8 mx-auto">
            <Row className="mx-3 p-0">
              {(data &&
                data
                  .filter((value) => {
                    if (input === "") {
                      return value;
                    } else if (
                      value.title.toLowerCase().includes(input.toLowerCase())
                    ) {
                      return value;
                    }
                  })
                  .map((path) => (
                    <LearningPathCard
                      key={path.id}
                      learningPath={path}
                      imgHeight="200px"
                    />
                  ))) ||
                (!isLoading && <h3>{t("common:noResult")}</h3>)}
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default LearningPaths;
