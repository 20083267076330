import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CourseCard from "components/CourseCard/CourseCard";
import "./Courses.scss";
import Loading from "components/Loading";
import { IoMdSchool } from "react-icons/io";
import { Row, Col } from "react-bootstrap";
import ButtonSecondary from "components/ButtonSecondary/ButtonSecondary";
import { fetchCurrentUser } from "stores/authentication/authMiddleware";
import Cookies from "js-cookie";
import SideMenu from "components/SideMenu/SideMenu";

const Courses = ({ currentLanguageId }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { data, error, get, isLoading } = useFetch();
  const { t } = useTranslation();
  const [input, setInput] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const history = useHistory();

  const handleClick = () => {
    history.push("/learning_paths");
  };

  const handleCategoryFilter = (list) => {
    setCategoryList(list);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    const company = Cookies.get("company");
    fetchCurrentUser(token);
    if (currentUser && !company) {
      get(
        `/learning_paths?single=true&categories=${categoryList.join(
          ", "
        )}&current_user=${currentUser.id}&language=${currentLanguageId}`
      );
    } else {
      get(
        `/learning_paths?single=true&categories=${categoryList.join(
          ", "
        )}&language=${currentLanguageId}`
      );
    }
  }, [categoryList, currentUser, currentLanguageId]);

  return (
    <div className="Courses m-0 p-0">
      <Row style={{minHeight: "90vh"}}>
        <Col className="col-lg-3 sidemenu d-flex flex-column justify-content-center align-items-center">
          <SideMenu
            handleCategoryFilter={handleCategoryFilter}
            setInput={setInput}
          />
        </Col>
        {isLoading && <Loading />}
        <Col className="col-lg-8 mx-auto">
          <Row className="d-flex justify-content-center mx-3 p-0">
            {(data &&
              data
                .filter((value) => {
                  if (input === "") {
                    return value;
                  } else if (
                    value.title.toLowerCase().includes(input.toLowerCase())
                  ) {
                    return value;
                  }
                })
                .map((course) => (
                  <CourseCard
                    key={course.id}
                    course={course}
                    imgHeight="200px"
                  />
                ))) ||
              (!isLoading && <h3>{t("common:noResult")})</h3>)}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Courses;
