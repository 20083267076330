import { useState } from "react";
import { AiFillCloseCircle, AiFillEdit } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import CompanyManagementModal from "./CompanyManagementModal";

const CompanyManagementLine = ({ company, handleDelete, reload }) => {
  const { t } = useTranslation("admin");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <tr key={company.id}>
      <td>{company.id}</td>
      <td>{company.name}</td>
      <td>
        {company.description
          ? company.description
          : t("no_description_for_this_company")}
      </td>
      <td>{company.email ? company.email : t("no_email_for_this_company")}</td>
      <td>
        {company.is_bootcamp ? (
          <span className="badge badge-success">{t("yes")}</span>
        ) : (
          <span className="badge badge-danger">{t("no")}</span>
        )}
      </td>
      <td>
        <AiFillEdit
          size={30}
          style={{ color: "orange" }}
          onClick={handleShow}
        />
        <CompanyManagementModal
          show={show}
          handleClose={handleClose}
          company={company}
          handleDelete={handleDelete}
          reload={reload}
        />
      </td>
      <td>
        {" "}
        <AiFillCloseCircle
          size={30}
          style={{ color: "red" }}
          onClick={() => handleDelete(company.id)}
        />{" "}
      </td>
    </tr>
  );
};

export default CompanyManagementLine;
