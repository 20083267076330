import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const CompanyRoute = ({ component: Component, ...rest }) => {
  const isLogged = useSelector((state) => state.auth.isLogged);
  const isCompany = useSelector((state) => state.auth.isCompany);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged && isCompany ? <Component {...props} /> : <Redirect to="/profile/edit" />
      }
    />
  );
};

export default CompanyRoute;