import { Nav, Accordion, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { GoTriangleRight } from "react-icons/go";
import { NavLink } from "react-router-dom";
import "../../Admin/components/Sidebar.scss";

const CompanySidebar = () => {
  const { t } = useTranslation("company");
  return (
    <Nav className="Sidebar flex-column">
      <h2 className="mt-5 ml-2 mb-5">{t("company_dashboard")}</h2>
      <NavLink to="/company/statistics" className="ml-4">
        {t("show_statistics")}
      </NavLink>
      <NavLink to="/company/users" className="ml-4">
        {t("show_employees")}
      </NavLink>
      <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {t("manage_employees")} <GoTriangleRight />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <NavLink className="ml-5" to="/company/users/add">
              <small>- {t("add_users_to_company")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/company/users/requests">
              <small>- {t("see_company_user_requests")}</small>
            </NavLink>
            <br />
          </>
        </Accordion.Collapse>
      </Accordion>
    </Nav>
  );
};

export default CompanySidebar;
