import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import { Badge, Container, Row, Button } from "react-bootstrap";
import useFetch from "hooks/useFetch";
import CardAccordion from "components/CardAccordion/CardAccordion";
import MarkdownConverter from "components/MarkdownConverter/MarkdownConverter";
import moment from "moment";
import "moment/locale/fr";

const ShowOneMentoringSession = () => {
  const { t } = useTranslation("admin");
  const { get, destroy } = useFetch();
  const [session, setSession] = useState({});
  const location = useLocation();
  const history = useHistory();
  const sessionId = location.pathname.split("/").pop();

  useEffect(() => {
    const getSessionInformations = async () => {
      const data = await get(`/admin/mentoring_sessions/${sessionId}`);
      setSession(data);
    };
    getSessionInformations();
  }, []);

  const handleDelete = async () => {
    await destroy(`/admin/mentoring_sessions/${session.id}`);
    history.goBack();
  };

  return (
    <section>
      {session && session.id && (
        <div>
          <Row className="d-flex flex-column align-items-center mt-3 mb-5">
            <h3>
              {t("participants")} {session.student.first_name}{" "}
              {session.student.last_name} {" & "} {session.mentor.first_name}{" "}
              {session.mentor.last_name}
            </h3>
            <Badge
              pill
              className={
                session.finished
                  ? "badge-success text-white"
                  : "badge-warning text-white"
              }
            >
              {session.finished ? t("session_done") : t("coming_soon")}
            </Badge>
          </Row>
          <Container>
            <Row className="ml-1 d-flex justify-content-between">
              <div>
                {session && session.session_date && (
                  <p>
                    {t("expected_date")} :{" "}
                    <span className="font-weight-bold">
                      {moment(session.session_date)
                        .locale("fr")
                        .format("DD MMMM YYYY")}
                    </span>{" "}
                    à{" "}
                    <span className="font-weight-bold">
                      {moment(session.session_date).locale("fr").format("HH") +
                        " h " +
                        moment(session.session_date).locale("fr").format("mm")}
                    </span>
                  </p>
                )}
              </div>
            </Row>
            <div className="my-3">
              <CardAccordion
                header={t("your_session_description")}
                content={<p>{session.description}</p>}
              />
            </div>
            <div className="my-3">
              {(session && session.report && session.report.length > 0 && (
                <CardAccordion
                  header={t("your_session_report")}
                  content={<MarkdownConverter text={session.report} />}
                />
              )) || <p>{t("no_report_yet")}</p>}
            </div>
          </Container>
        </div>
      )}
      <div className="d-flex justify-content-center">
        {session && session.finished === false && (
          <Button
            className="btn btn-danger text-white mx-auto"
            onClick={handleDelete}
          >
            {t("profile:delete_session")} {<span className="ml-1">🗑️</span>}
          </Button>
        )}
      </div>
    </section>
  );
};

export default ShowOneMentoringSession;
