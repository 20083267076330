import { Button, Card, Image, Row } from "react-bootstrap";
import ArsenioLogo from "../assets/arseniologo.jpeg";
import moment from "moment";
import "moment/locale/fr";
import { HiOutlineTrash } from "react-icons/hi";
import { useSelector } from "react-redux";
import useFetch from "hooks/useFetch";
import { useEffect } from "react";

const MessageCard = ({ message, company, reload }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { destroy } = useFetch();

  const handleDelete = (id, userId) => {
    if (userId === null)
      destroy(`/company/company_user_request_messages/${id}`, reload);
    else if (currentUser.id === userId)
      destroy(`/admin/company_user_request_messages/${id}`, reload);
  };

  console.log(message);

  return (
    <>
      {currentUser && message && (
        <Card
          style={{
            border: "1px solid #CDCDCD",
            borderRadius: "5px",
            position: "relative",
            left: `${message.user_id === null ? 0 : "20%"}`,
            width: "80%",
          }}
          className="my-4"
        >
          <Card.Body>
            {(currentUser.role === "admin" && currentUser.id === message.user_id && message.user_id !== null  ||
              currentUser.id === message?.company?.id && currentUser.role !== "admin") && (
                <Button
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    top: " 10px",
                    right: "10px",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    border: "none",
                  }}
                  onClick={() => handleDelete(message.id, message.user_id)}
                >
                  <HiOutlineTrash size={20} style={{ color: "black" }} />
                </Button>
              )}
            {message.company_id === null ||
              (message.company === null && (
                <Row className="my-1 align-items-center p-2">
                  <Image
                    src={ArsenioLogo}
                    roundedCircle
                    className="path-avatar mr-4"
                  />
                  <Card.Text>Arsenio</Card.Text>
                </Row>
              ))}
            {message.user_id === null && (
              <Row className="my-1 align-items-center p-2">
                <Image
                  src={company.avatar}
                  roundedCircle
                  className="path-avatar mr-4"
                />
                <Card.Text>{company.name}</Card.Text>
              </Row>
            )}
            <Row className="justify-content-between p-2">
              <Card.Text>{message.message}</Card.Text>
              <small>
                {moment(message.created_at).locale("fr").format("DD MMMM YYYY")}{" "}
                à{" "}
                {moment(message.created_at).locale("fr").format("HH") +
                  " h " +
                  moment(message.created_at).locale("fr").format("mm")}
              </small>
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default MessageCard;
