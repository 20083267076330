import { Card, Col, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import MentoringSessionCard from "components/MentoringSessionCard/MentoringSessionCard";
import useDeviceResize from "hooks/useDeviceResize";
import moment from "moment";
import "moment/locale/fr";

import "./MentoringCard.scss";

const MentoringCard = () => {
  const { t } = useTranslation("profile");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userInitiatedLogout = useSelector(
    (state) => state.auth.userInitiatedLogout
  );
  const { get, isLoading } = useFetch();
  const [studentSessions, setStudentSessions] = useState([]);
  const [nextSession, setNextSession] = useState(null);
  const history = useHistory();
  const { isMobile, checkOnDeviceResize, removeResizeListener } =
    useDeviceResize();

  const getMentoringSession = async () => {
    if (userInitiatedLogout) return;
    
    const sessions = await get(
      `/users/${currentUser.id}/mentoring_sessions?role=${
        currentUser && currentUser.role === "mentor" ? "mentor" : "student"
      }`
    );
    setStudentSessions(sessions);
  };

  const findNextSession = () => {
    const nextSession = studentSessions.find(
      (session) =>
        moment(session.session_date).isSameOrAfter(moment(), "day") &&
        session.session_date !== null
    );
    setNextSession(nextSession);
  };

  useEffect(() => {
    if (studentSessions && studentSessions.length > 0) {
      findNextSession();
    }
  }, [studentSessions]);

  useEffect(() => {
    getMentoringSession();
    checkOnDeviceResize();
  }, []);

  removeResizeListener();

  return (
    <Card className="MentoringCard p-4 mt-3" style={{ minHeight: "100%" }}>
      <h4 className="mentoring-card-title ml-3 mb-4 ">
        {t("my_mentoring_session")} 🧑‍🏫
      </h4>
      {currentUser && currentUser.role === "mentor" ? (
        <Row className="text-center d-flex align-items-center">
          <Col sm={12} lg={6}>
            {(!isLoading &&
              studentSessions &&
              studentSessions.length > 0 &&
              nextSession && (
                <div
                  className={
                    isMobile
                      ? "not-default-link my-2 ml-5 mx-auto"
                      : "not-default-link my-2 ml-5"
                  }
                  key={nextSession.id}
                >
                  <p className="font-weight-bold mt-1 mx-3">
                    <span className="font-weight-normal">
                      {t("next_session")} :
                    </span>
                  </p>
                  <Link
                    to={`/profile/${currentUser.id}/mentoring/${nextSession.id}`}
                  >
                    <MentoringSessionCard session={nextSession} />
                  </Link>
                </div>
              )) ||
              (isLoading && <p className="m-3">{t("admin:loading")}</p>)}
            {!isLoading &&
              (nextSession === undefined ||
                nextSession === null ||
                nextSession.length < 1) && (
                <p className="m-3">{t("any_session_in_the_future")} 🗓️</p>
              )}
          </Col>
          <Col sm={12} lg={6}>
            <Button
              className="buttonMentoringSession w-75 mt-4"
              onClick={() => history.push("/profile/mymentoringsessions")}
            >
              {t("see_all_of_my_sessions")}
            </Button>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm={12} lg={12} className="mb-3 text-center">
            <Row>
              <Col sm={12} lg={6}>
                {currentUser && currentUser.role !== "mentor" && (
                  <p className="font-weight-bold mt-1 mx-3 mb-1">
                    {currentUser.remaining_session}{" "}
                    <span className="font-weight-bold">
                      {t("remaining_sessions")}
                    </span>
                  </p>
                )}
                <p className="font-weight-bold mt-1 mx-3">
                  <span className="font-weight-normal">
                    {t("next_session")} :
                  </span>
                </p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center">
              <Col sm={12} lg={6} className="mx-auto">
                {(!isLoading &&
                  studentSessions &&
                  studentSessions.length > 0 &&
                  nextSession && (
                    <div
                      className={
                        isMobile
                          ? "not-default-link my-2 ml-5 mx-auto"
                          : "not-default-link my-2 ml-5"
                      }
                      key={nextSession.id}
                    >
                      <Link
                        to={`/profile/${currentUser.id}/mentoring/${nextSession.id}`}
                      >
                        <MentoringSessionCard session={nextSession} />
                      </Link>
                    </div>
                  )) ||
                  (isLoading && <p className="m-3">{t("admin:loading")}</p>)}
                {!isLoading &&
                  (nextSession === undefined ||
                    nextSession === null ||
                    nextSession.length < 1) && (
                    <p className="m-3">{t("any_session_in_the_future")} 🗓️</p>
                  )}
              </Col>
              <Col sm={12} lg={6}>
                {currentUser && currentUser.role !== "mentor" && (
                  <Button
                    className="buttonMentoringSession w-75"
                    onClick={() =>
                      history.push(
                        `/profile/${currentUser.id}/mentoringsession/new`
                      )
                    }
                  >
                    {t("organize_session")}
                  </Button>
                )}
                <Button
                  className="buttonMentoringSession w-75 mt-4"
                  onClick={() => history.push("/profile/mymentoringsessions")}
                >
                  {t("see_all_of_my_sessions")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default MentoringCard;
