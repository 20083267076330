import useFetch from "hooks/useFetch";
import { Modal, Form, Table, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";

const CompanyManagementModal = ({ show, handleClose, company, reload }) => {
  const { t } = useTranslation("admin");
  const { put } = useFetch();
  const [newCompany, setNewCompany] = useState(company);
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const handleSwitch = () => setIsSwitchOn(!isSwitchOn);

  const handleChange = (name) => (e) => {
    setNewCompany({ ...newCompany, [name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...newCompany,
    };
    const response = await put(`/admin/companies/${company.id}`, data);
    if (response) {
      reload();
      handleClose();
    }
  };

  const handleDeleteUser = async (e, userId) => {
    e.preventDefault();
    const response = await put(`/admin/users/${userId}`, { company_id: null });
    if (response) {
      reload();
    }
  };

  useEffect(() => {
    setNewCompany({ ...company, is_bootcamp: isSwitchOn });
  }, [isSwitchOn]);

  useEffect(() => {
    setIsSwitchOn(company.is_bootcamp);
  }, [company]);

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="modal-80w mx-auto">
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "20px" }}>
          {t("update_this_company")} : {company.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
          {company && (
            <Form className="w-75">
              <div className="my-3">
                <Form.Label>{t("name")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("name")}
                  defaultValue={company.name}
                  onChange={handleChange("name")}
                />
              </div>
              <div className="my-3">
                <Form.Label>{t("description")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("description")}
                  defaultValue={company.description}
                  onChange={handleChange("description")}
                />
              </div>
              <div className="my-3">
                <Form.Label>{t("email")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("email")}
                  defaultValue={company.email}
                  onChange={handleChange("email")}
                />
              </div>
              <div className="my-3">
                <Form.Label>{t("is_bootcamp?")}</Form.Label>
                <Form.Check
                  type="switch"
                  id="switchEnabled"
                  inline
                  checked={isSwitchOn}
                  onChange={handleSwitch}
                  className="ml-3 pt-2"
                />
              </div>
              <div className="text-center">
                <button
                  className="my-3 btn btn-warning text-white"
                  onClick={(e) => handleSubmit(e)}
                >
                  {t("send")}
                </button>
              </div>
            </Form>
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4">
          <Container>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{t("first_name")}</th>
                  <th>{t("last_name")}</th>
                  <th>{t("email")}</th>
                  <th>{t("delete")}</th>
                </tr>
              </thead>
              <tbody>
                {company.users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td>
                      <AiFillCloseCircle
                        style={{ color: "red" }}
                        size={30}
                        onClick={(e) => handleDeleteUser(e, user.id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyManagementModal;
