import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import AdvancementCard from "./components/AdvancementCard/AdvancementCard";
import SubscriptionCard from "./components/SubscriptionCard/SubscriptionCard";
import MentoringCard from "./components/MentoringCard/MentoringCard";
import Cookies from "js-cookie";
import { fetchCurrentUser } from "stores/authentication/authMiddleware";
import { useEffect } from "react";
import "./ProfileDashboard.scss";

const ProfileDashboard = () => {
  const { t } = useTranslation("profile");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userInitiatedLogout = useSelector(
    (state) => state.auth.userInitiatedLogout
  );
  const dispatch = useDispatch();

  const fetchUser = async () => {
    const token = Cookies.get("token");
    if (token) {
      dispatch(fetchCurrentUser(token));
    }
  };

  useEffect(() => {
    if (userInitiatedLogout) {
      return;
    }
    fetchUser();
  }, [userInitiatedLogout]);

  return (
    <div>
      {currentUser && (
        <>
          <h3 className="page-title text-center">{t("dashboard_of")}</h3>
          {currentUser && currentUser.role !== "mentor" && (
            <>
              <Row className="mt-5">
                <Col lg={8} md={12} sm={12}>
                  <AdvancementCard />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <SubscriptionCard />
                </Col>
              </Row>
            </>
          )}
          <Row className="my-4">
            <Col sm={12}>
              <MentoringCard />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default ProfileDashboard;
