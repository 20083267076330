import useFetch from "hooks/useFetch";
import { useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AdminUsersSectionFilter = ({
  filterValue,
  setFilterValue,
  setFilteredName,
  setSelectedBootcamp,
  setBootcamps,
  bootcamps,
}) => {
  const { t } = useTranslation("admin");
  const { get } = useFetch();

  const keepCompanyIfIsBootcamp = (companiesList) => {
    return companiesList.filter((company) => company.is_bootcamp);
  };

  const getBootcamp = async () => {
    const response = await get("/admin/companies");
    const finalCompaniesList = keepCompanyIfIsBootcamp(response);
    setBootcamps(finalCompaniesList);
  };

  useEffect(() => {
    getBootcamp();
  }, []);

  useEffect(() => {
    if (filterValue === "bootcamp") {
      setSelectedBootcamp(null);
      getBootcamp();
    }
  }, [filterValue]);

  return (
    <Form className="px-3 w-100 display-flex row">
      <div className="w-25 mr-3">
        <Form.Control
          as="select"
          onChange={(e) => setFilterValue(e.target.value)}
        >
          <option value="null">{t("add_filter")}</option>
          <option value="name">{t("filter_by_name")}</option>
          <option value="bootcamp">{t("filter_by_bootcamp")}</option>
        </Form.Control>
      </div>
      <div className="w-50">
        {filterValue && filterValue === "name" && (
          <Form.Control
            type="text"
            placeholder={t("filter_by_name")}
            onChange={(e) => setFilteredName(e.target.value)}
          />
        )}
        {filterValue && filterValue === "bootcamp" && (
          <Form.Control as="select" onChange={(e) => setSelectedBootcamp(e.target.value)}>
            <option value="no">{t("filter_by_bootcamp")}</option>
            {bootcamps && bootcamps.map((bootcamp) => (
              <option key={bootcamp.id} value={bootcamp.id}>{bootcamp.name}</option>
            ))}
          </Form.Control>
        )}
      </div>
    </Form>
  );
};

export default AdminUsersSectionFilter;
