import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

const FlashMessage = () => {
  const { addToast } = useToasts();
  const category = useSelector((state) => state.flash.category);
  const title = useSelector((state) => state.flash.title);
  const content = useSelector((state) => state.flash.content);
  const displayFlash = useSelector((state) => state.flash.display);

  const notify = () => {
    addToast(content, {
      appearance: `${category}`,
      autoDismiss: true,
    });
  };

  useEffect(() => {
    displayFlash && notify();
  }, [displayFlash]);

  return <>{console.log(`${displayFlash}`)}</>;
};

export default FlashMessage;
