import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import GeneralesInformationsForm from "./components/GeneralesInformationsForm";

const AddMentorProfile = () => {
  const { t } = useTranslation("admin");

  return (
    <div>
      <h4 className="text-center">{t("add_mentor_profile")}</h4>
      <div className="mt-4">
        <Card>
          <Card.Header className="LearningPathCard__title">
            {t("generales_informations")}
          </Card.Header>
          <Card.Body className="py-0">
            <GeneralesInformationsForm />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default AddMentorProfile;
