import useFetch from "hooks/useFetch";
import UserLine from "pages/Company/components/SeeEmployees/UserLine";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Container, Table, Pagination, Form } from "react-bootstrap";
import debounce from "lodash/debounce";
import "./GetUsers.scss";

const GetUsers = () => {
  const { error, isLoading, get } = useFetch();
  const [users, setUsers] = useState([]);
  const [actualPage, setActualPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [userSearch, setUserSearch] = useState("");
  const [notFound, setNotFound] = useState(false);
  const { t } = useTranslation();

  const getUsers = async () => {
    const response = await get(`/company/users?page=${actualPage}&per_page=20`);

    if (!response.total_pages) {
      setUsers([]);
      return;
    }

    setTotalPages(response.total_pages);
    setUsers(response.users);
  };

  const getASpecificUser = async (name) => {
    const response = await get(`/company/users?name=${name}`);
    if (response.user === null) {
      setNotFound(true);
      return;
    }
    setUsers([response.user]);
  };

  const handlePageChange = (page) => {
    setActualPage(page);
  };

  const handleSearch = (e) => {
    setUserSearch(e.target.value);
    setNotFound(false);
  };

  const delayedSearch = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.length >= 3) {
        getASpecificUser(searchTerm);
      }

      if (searchTerm.length === 0) {
        getUsers();
      }
    }, 500),
    []
  );

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    delayedSearch(userSearch);
  }, [userSearch, delayedSearch]);

  useEffect(() => {
    getUsers();
  }, [actualPage]);

  return (
    <div className="mx-auto">
      <Container>
        {error && <h4>{error}</h4>}
        {(isLoading && !userSearch && (
          <p className="text-center">{t("admin:loading")}</p>
        )) || (
          <>
            {users && users.length > 0 ? (
              <div>
                <div className="text-center my-5">
                  <h3>{t("company:show_employees")}</h3>
                </div>
                <Form.Control
                  className="col-8 mx-auto mb-4"
                  type="text"
                  placeholder={t("company:search_an_user")}
                  value={userSearch}
                  onChange={(e) => handleSearch(e)}
                />
                {notFound ? (
                  <p className="text-center">{t("company:user_not_found")}</p>
                ) : (
                  <Table striped bordered hover>
                    <thead className="text-center">
                      <tr>
                        <th></th>
                        <th>{t("admin:first_name")}</th>
                        <th>{t("admin:last_name")}</th>
                        <th>{t("common:show_user")}</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {!notFound &&
                        users.map((user) => {
                          return user && <UserLine key={user.id} user={user} />;
                        })}
                    </tbody>
                  </Table>
                )}
              </div>
            ) : (
              <p className="text-center">{t("company:no_users_found")}</p>
            )}
            {totalPages && (
              <div className="d-flex justify-content-center">
                <Pagination className="pagination-orange">
                  {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                      key={index}
                      active={index + 1 === actualPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              </div>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default GetUsers;
