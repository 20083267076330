import React from "react";
import Searchbar from "../../components/Searchbar/Searchbar";
import { useTranslation } from "react-i18next";

const SideMenu = ({ setInput, bottomComponent }) => {
  const { t } = useTranslation();

  return (
    <aside className="h-100">
      <div>
        <h5 className="mt-4 mb-0 bold">🔍 {t("common:recent_themes")}</h5>
        <Searchbar getInput={setInput} />
      </div>
      {bottomComponent}
    </aside>
  );
};

export default SideMenu;
