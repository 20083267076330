import SignatureCanvas from "react-signature-canvas";
import { useRef, forwardRef, useImperativeHandle } from "react";
import "./SignatureComponent.scss";

const SignatureComponent = forwardRef((props, ref) => {
  const canvasRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getSignature: () => {
      return canvasRef.current.getTrimmedCanvas().toDataURL("image/png");
    },
    getCanvas: () => {
      return canvasRef.current;
    }
  }));

  return (
    <SignatureCanvas
      penColor="black"
      canvasProps={{className: "sigCanvas" }}
      backgroundColor="rgba(245, 245, 245, 0.8)"
      ref={canvasRef}
    />
  );
});

SignatureComponent.displayName="SignatureComponent";

export default SignatureComponent;
