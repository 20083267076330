import { useState } from "react";
import useFetch from "hooks/useFetch";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserForm from "../../AddPathSubscriptionForUser/UserForm";
import CompanyForm from "./components/CompanyForm";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const UpdateCompanyUsers = () => {
  const { error, patch } = useFetch();
  const { t } = useTranslation();
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const updateUserCompanyId = (userId, companyId) => {
    patch(
      `/admin/users/${userId}`,
      { company_id: companyId },
      displaySuccess(t("admin:user_company_updated")),
      displayError(t("admin:user_company_not_updated"))
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateUserCompanyId(userId, companyId);
  };

  return (
    <div>
      {error && <h4>{error}</h4>}
      <h3 className="d-flex row justify-content-center my-3">
        {t("admin:add_users_to_company")}
      </h3>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} lg={6}>
            <CompanyForm handleChange={(e) => setCompanyId(e.target.value)} />
          </Col>
          <Col sm={12} lg={6}>
            <UserForm handleChange={(e) => setUserId(e.target.value)} noLabel={true} />
          </Col>
        </Row>
        <div className="d-flex row justify-content-center">
          <Button
            type="submit"
            className="ButtonPrimary w-50"
            onClick={handleSubmit}
          >
            {t("admin:user_subscription")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateCompanyUsers;
