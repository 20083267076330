import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import MDEditor from "@uiw/react-md-editor";
import { Form, Button } from "react-bootstrap";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import { useDispatch } from "react-redux";
import { capitalize } from "tools/capitalize";

const CreateNewDefinition = () => {
  const { t } = useTranslation("admin");
  const { get, post } = useFetch();
  const [title, setTitle] = useState("");
  const [definition, setDefinition] = useState("");
  const [languageId, setLanguageId] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [languageError, setLanguageError] = useState(false);
  const [glossaryTypeError, setGlossaryTypeError] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const dispatch = useDispatch();

  const glossaryTypes = [
    {
      id: 0,
      name: "bim",
    },
    { id: 1, name: "btp" },
  ];

  useEffect(() => {
    const getLanguages = async () => {
      const data = await get("/admin/languages");
      setLanguages(data);
    };
    getLanguages();
  }, []);

  useEffect(() => {
    checkIfSelectedLanguageExist();
  }, [languageId]);

  useEffect(() => {
    checkIfSelectedTypeExist();
  }, [selectedType]);

  const handleChange = (event, errorHandler, selectedState) => {
    errorHandler(false);
    selectedState(event.target.value);
  };

  const checkIfSelectedLanguageExist = () => {
    let existingLanguagesIds = [];
    languages.map((language) => {
      existingLanguagesIds.push(language.id);
    });
    if (!existingLanguagesIds.includes(Number(languageId)))
      setLanguageError(true);
    return;
  };

  const checkIfSelectedTypeExist = () => {
    let existingTypesNames = [];
    glossaryTypes.map((type) => {
      existingTypesNames.push(type.name);
    });

    if (!existingTypesNames.includes(selectedType)) setGlossaryTypeError(true);
    return;
  };

  const handleSubmit = async () => {
    const data = {
      title: title,
      definition: definition,
      language_id: languageId,
      definition_type: selectedType
    };
    await post("/admin/glossaries", data, afterNewDefinitionSubmission);
  };

  const afterNewDefinitionSubmission = () => {
    dispatch(displaySuccess(t("new_definition_created")));
    setTitle("");
    setDefinition("");
    setLanguageId("");
    setSelectedType("");
  };

  return (
    <div>
      <h3 className="text-center mt-5">{t("add_definition")}</h3>
      <h6 className="mt-5">
        {t("define_title")} -{" "}
        <small className="text-secondary">{t("use_markdown")}</small>
      </h6>
      <MDEditor
        value={title}
        onChange={setTitle}
        className="mb-5"
        height={250}
      />
      <h6 className="mt-5">
        {t("define_definition")} -{" "}
        <small className="text-secondary">{t("use_markdown")}</small>
      </h6>
      <MDEditor
        value={definition}
        onChange={setDefinition}
        className="mb-5"
        height={400}
      />
      <div className="mb-4">
        <Form.Control
          as="select"
          style={{ border: languageError ? "1px solid red" : "" }}
          className="mt-3 mb-0"
          onChange={(e) => handleChange(e, setLanguageError, setLanguageId)}
        >
          <option>{t("choose_a_language")}</option>
          {languages.map((language) => (
            <option key={language.id} value={language.id}>
              {language.name}
            </option>
          ))}
        </Form.Control>
        {languageError === true && (
          <small className="text-danger">{t("not_good_language")}</small>
        )}
      </div>
      <div className="mb-4">
        <Form.Control
          as="select"
          style={{ border: glossaryTypeError ? "1px solid red" : "" }}
          className="mt-3 mb-0"
          onChange={(e) =>
            handleChange(e, setGlossaryTypeError, setSelectedType)
          }
        >
          <option>{t("choose_a_type")}</option>
          {glossaryTypes.map((type) => (
            <option key={type.id} value={type.name}>
              {capitalize(type.name)}
            </option>
          ))}
        </Form.Control>
        {glossaryTypeError === true && (
          <small className="text-danger">{t("not_good_type")}</small>
        )}
      </div>
      {title &&
        title.length > 3 &&
        definition &&
        definition.length > 5 &&
        !languageError && !glossaryTypeError && (
          <div className="text-center my-4">
            <Button className="btn-warning text-white" onClick={handleSubmit}>
              {t("create_new_word")}
            </Button>
          </div>
        )}
    </div>
  );
};

export default CreateNewDefinition;
