import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const StantardLearningPathForm = ({ learningPaths, noLabel, handleChange }) => {
  const { t } = useTranslation("admin");
  return (
    <div className="my-3">
      {noLabel === false && <h6>{t("choose_a_learningPaths")}</h6>}
      <Form.Control as="select" className="my-3" onChange={handleChange}>
        <option>{t("choose_a_learningPaths")}</option>
        {learningPaths?.map((learningPath) => (
          <option key={learningPath.id} value={learningPath.id}>
            {learningPath.id} - {learningPath.title}
          </option>
        ))}
      </Form.Control>
    </div>
  );
};

export default StantardLearningPathForm;