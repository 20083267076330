import { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCurrentUser,
  fetchCurrentCompany,
  fetchCurrentUserMinimalsDetails,
} from "stores/authentication/authMiddleware";
import { ToastProvider } from "react-toast-notifications";
import * as authActions from "stores/authentication/authActions";
import i18n from "./i18n";
import { withTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Login from "pages/public/Login/Login";
import Profile from "pages/private/Profile/Profile";
import Lesson from "pages/private/Lesson/Lesson";
import Navigation from "components/layouts/Navigation/Navigation";
import FlashMessage from "components/layouts/FlashMessage";
import PrivateRoute from "components/PrivateRoute";
import PublicRoute from "components/PublicRoute";
import CompanyRoute from "components/CompanyRoute";
import AdminRoute from "components/AdminRoute";
import Admin from "pages/Admin/Admin";
import Company from "pages/Company/Company";
import PageNotFound from "pages/PageNotFound";
import LearningPaths from "pages/public/LearningPaths/LearningPaths";
import Courses from "pages/public/Courses/Courses";
import LearningPathShow from "pages/public/LearningPaths/LearningPathShow";
import ShowCourse from "pages/public/Courses/ShowCourse/ShowCourse";
import Subscription from "pages/private/Subscription/Subscription";
import RecoverPassword from "pages/public/RecoverPassword/RecoverPassword";
import ForgotPassword from "pages/public/ForgotPassword/ForgotPassword";
import Chapter from "pages/private/Chapter/Chapter";
import AdminLogin from "pages/public/AdminLogin/AdminLogin";
import AOS from "aos";
import "aos/dist/aos.css";
import flagEN from "assets/flagEN.png";
import flagFR from "assets/flagFR.png";
import useFetch from "hooks/useFetch";
import MentorProfile from "pages/private/MentorProfile/MentorProfile";
import MentorIndex from "pages/private/MentorProfile/MentorIndex";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";
import ArticlesIndex from "pages/private/Articles/ArticlesIndex/ArticlesIndex";
import ArticleReader from "pages/private/Articles/ArticleReader/ArticleReader";
import GeneralTermsOfUse from "components/GeneralTermsOfUse/GeneralTermsOfUse";
import HomePage from "pages/public/HomePage/HomePage";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-gb";

const App = () => {
  const dispatch = useDispatch();
  const { get, data } = useFetch();
  const [currentLanguageId, setCurrentLanguageId] = useState(
    localStorage.getItem("i18n-language").split("-")[0].toLowerCase() === "fr"
      ? 1
      : 2
  );
  const [languages, setLanguages] = useState(() =>
    get("/admin/languages?shorten=true").then((data) => setLanguages(data))
  );
  const { t } = useTranslation("common");

  const languageDropdownOptions = [
    { label: flagFR, value: "fr" },
    { label: flagEN, value: "en" },
  ];

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLanguageId(
      languages.find(
        (language) =>
          language.abbreviation.toLowerCase() ===
          localStorage.getItem("i18n-language").split("-")[0].toLowerCase()
      ).id
    );

    moment.locale(lang === "fr" ? "fr" : "en-gb");
  };

  const autoLogin = async () => {
    const token = Cookies.get("token");
    const companyToken = Cookies.get("company");
    const companyId = Cookies.get("company_id");
    if (!token) {
      dispatch(authActions.loginFail());
      return;
    }
    if (token && !companyToken) {
      dispatch(fetchCurrentUserMinimalsDetails(token));
    } else if (token && companyToken) {
      dispatch(fetchCurrentCompany(token, companyId));
    }
  };

  useEffect(() => {
    autoLogin();
    AOS.init({ once: true, duration: 400 });

    AOS.refresh();
  }, []);

  return (
    <ErrorBoundary translator={t}>
      <ToastProvider placement="bottom-right">
        <section className="App">
          <Router>
            <ErrorBoundary translator={t}>
              <Navigation
                changeLanguage={changeLanguage}
                languageDropdownOptions={languageDropdownOptions}
                currentLanguageId={currentLanguageId}
              />
            </ErrorBoundary>
            <FlashMessage />

            <ErrorBoundary translator={t}>
              <Switch>
                <Route
                  restricted={false}
                  render={(props) => <HomePage />}
                  path="/"
                  exact
                />
                <PublicRoute restricted component={Login} path="/login" exact />
                <PublicRoute
                  restricted
                  component={RecoverPassword}
                  path="/password/edit"
                  exact
                />
                <PublicRoute
                  restricted
                  component={ForgotPassword}
                  path="/password"
                  exact
                />
                <Route
                  render={(props) => (
                    <LearningPaths currentLanguageId={currentLanguageId} />
                  )}
                  path="/learning_paths"
                  exact
                />
                <PublicRoute
                  component={LearningPathShow}
                  path="/learning_paths/:pathSlug"
                />
                <Route
                  render={(props) => (
                    <Courses currentLanguageId={currentLanguageId} />
                  )}
                  path="/courses"
                  exact
                />
                <PublicRoute component={ShowCourse} path="/courses/:id" exact />
                <PublicRoute component={AdminLogin} path="/admin/login" exact />
                <PrivateRoute
                  component={Profile}
                  extraProps={{ currentLanguageId: currentLanguageId }}
                  path="/profile"
                  {...(<Redirect to="/profile/dashboard" />)}
                />
                <PrivateRoute
                  component={Subscription}
                  path="/subscription"
                  exact
                />
                <AdminRoute component={Admin} path="/admin" />
                <CompanyRoute component={Company} path="/company" />
                <PrivateRoute
                  component={Lesson}
                  path="/courses/:courseId/chapters/:chapterId/lessons/:lessonId"
                />
                <PrivateRoute component={Chapter} path="/chapters" exact />
                <PrivateRoute
                  component={MentorIndex}
                  path="/mentors/index"
                  exact
                />
                <PrivateRoute
                  component={MentorProfile}
                  path="/displaymentor/:id"
                />
                <PrivateRoute
                  component={ArticlesIndex}
                  extraProps={{ currentLanguageId: currentLanguageId }}
                  path="/articles"
                  exact
                />
                <PrivateRoute component={ArticleReader} path="/articles/:id" />
                <PublicRoute restricted={false} component={PageNotFound} />
              </Switch>
            </ErrorBoundary>
          </Router>
        </section>
      </ToastProvider>
    </ErrorBoundary>
  );
};

export default withTranslation()(App);
