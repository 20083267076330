import CustomToggle from "components/CustomToggle/CustomToggle";
import { Card, Accordion, Row, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CardAccordion = ({ header, content }) => {
  const { t } = useTranslation("profile");
  return (
    <Accordion>
      <Card>
        <Card.Header className="LearningPathCard__title">
          {header}
        </Card.Header>
        <Card.Body className="py-0">
          <Row className="d-flex row justify-content-center">
            <CustomToggle as={Button} eventKey="0">
              <p style={{ fontSize: "20px" }}>{t("see")} 👀</p>
            </CustomToggle>
          </Row>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="pb-0">
              {content}
            </Card.Body>
          </Accordion.Collapse>
        </Card.Body>
      </Card>
    </Accordion>
  );
};

export default CardAccordion;
