import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const UpdateGlossaryLanguage = ({ definition, handleClose, reload }) => {
  const [languageId, setLanguageId] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [languageError, setLanguageError] = useState(false);
  const { get, patch } = useFetch();
  const { t } = useTranslation("admin");

  useEffect(() => {
    const getLanguages = async () => {
      const data = await get("/admin/languages");
      setLanguages(data);
    };
    getLanguages();
  }, []);

  useEffect(() => {
    checkIfSelectedLanguageExist();
  }, [languageId]);

  const checkIfSelectedLanguageExist = () => {
    let existingLanguagesIds = [];
    languages.map((language) => {
      existingLanguagesIds.push(language.id);
    });
    if (!existingLanguagesIds.includes(Number(languageId)))
      setLanguageError(true);
    return;
  };

  const handleChange = (event) => {
    setLanguageError(false);
    setLanguageId(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await patch(
      `/admin/glossaries/${definition.id}`,
      { language_id: languageId },
      displaySuccess(t("glossary_language_updated")),
      displayError(t("glossary_language_not_updated"))
    );
    handleClose();
    reload();
  };

  return (
    <div className="mb-4">
      <Form.Control
        as="select"
        style={{ border: languageError ? "1px solid red" : "" }}
        className="mt-3 mb-0"
        onChange={handleChange}
      >
        <option>{t("choose_a_language")}</option>
        {languages.map((language) => (
          <option key={language.id} value={language.id}>
            {language.name}
          </option>
        ))}
      </Form.Control>
      {languageError === true && (
        <small className="text-danger">{t("not_good_language")}</small>
      )}
      {!languageError && (
        <div className="text-center mt-4">
          <Button className="btn-warning text-white" onClick={handleSubmit}>
            {t("edit_language")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default UpdateGlossaryLanguage;
