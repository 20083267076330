import { useEffect } from "react";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const CourseForm = ({ handleChange }) => {
  const { data, error, isLoading, get } = useFetch();
  const { t } = useTranslation("admin");

  useEffect(() => {
    get("/admin/courses");
  }, []);

  return (
    <div className="my-4">
      {error && <h4>{error}</h4>}
      {(isLoading && t("loading")) ||
        (data && (
          <div>
            <h6>{t("admin:choose_course")}</h6>
            <Form.Control as="select" className="my-3" onChange={handleChange}>
              <option>{t("admin:choose_course")}</option>
              {data.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.id} - {course.title}
                </option>
              ))}
            </Form.Control>
          </div>
        ))}
    </div>
  );
};

export default CourseForm;
