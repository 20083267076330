import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";

const ChooseBootcampToManagePresences = () => {
  const { t } = useTranslation("admin");
  const { get } = useFetch();
  const [companies, setCompanies] = useState(null);

  const getCompanies = async () => {
    const response = await get("/admin/companies?is_bootcamp=true");
    setCompanies(response);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <div>
      <h3 className="text-center">{t("manage_bootcamp_presences")} </h3>
      {companies && (
        <Table striped bordered hover className="my-4">
          <thead>
            <tr>
              <th>{t("name")}</th>
              <th>{t("show_participants")}</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company, index) => (
              <tr key={index}>
                <td>{company.name}</td>
                <td className="text-center">
                  <Link
                    to={`/admin/bootcamp/${company.id}/users`}
                  >
                    <AiFillEye size={20} style={{ color: "black" }} />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default ChooseBootcampToManagePresences;
