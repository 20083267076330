import { AiFillEye, AiOutlineEdit } from "react-icons/ai";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import UserForm from "../../AddPathSubscriptionForUser/UserForm";
import CompanyForm from "../UpdateCompanyUsers/components/CompanyForm";
import { displaySuccess, displayError } from "stores/flashmessages/flashMiddleware";
import useFetch from "hooks/useFetch";
import { Link } from "react-router-dom";

const CompanyRequestLine = ({
  request,
  reload,
  setAllCompaniesIds,
  noLabel,
}) => {
  const { t } = useTranslation("admin");
  const { patch } = useFetch();
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    event.preventDefault();
    patch(
      `/admin/users/${userId}`,
      { company_id: companyId },
      displaySuccess(t("admin:user_company_updated")),
      displayError(t("admin:user_company_not_updated"))
    );
    patch(
      `/admin/company_user_requests/${request.id}`,
      { state: "solved" },
      handleClose
    );
    reload();
  };

  return (
    <>
      <tr>
        <td>{request.company.name} </td>
        <td>{request.email} </td>
        <td>{request.first_name} </td>
        <td>{request.last_name} </td>
        <td>
          {request.state === "pending"
            ? t("company:pending") + " ⏳"
            : t("company:solved") + " 🔥"}{" "}
        </td>
        <td className="text-center">
          {request.state === "pending" ? (
            <AiOutlineEdit
              size={30}
              style={{ color: "orange" }}
              onClick={handleShow}
            />
          ) : (
            <p>{t("already_solved")}</p>
          )}
        </td>
        <td className="text-center">
          <Link to={`/admin/company/usersrequests/${request.id}`} params={{ id: request.id }}>
            <AiFillEye size={20} style={{ color: "black" }} />
          </Link>
        </td>
      </tr>
      <Modal
        dialogClassName="modal-80w mx-auto"
        aria-labelledby="custom-modal-styling"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("manage_company_user_request_for")} {request.first_name}{" "}
            {request.last_name} {t("for")} {request.company.name}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} lg={6}>
                <CompanyForm
                  handleChange={(e) => setCompanyId(e.target.value)}
                  setAllCompaniesIds={setAllCompaniesIds}
                  noLabel={noLabel}
                />
              </Col>
              <Col sm={12} lg={6}>
                <UserForm handleChange={(e) => setUserId(e.target.value)} noLabel={false} />
              </Col>
            </Row>
            <div className="d-flex row justify-content-center">
              {companyId !== null && userId !== null && (
                <Button
                  type="submit"
                  className="ButtonPrimary w-50"
                  onClick={handleSubmit}
                >
                  {t("user_subscription")}
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompanyRequestLine;
