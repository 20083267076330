import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import MultiSelectSkills from "../MultiSelectSkills";
import {
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  Button,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import "./EditProfile.scss";

const EditProfile = ({ onSubmit, onCompanySubmit }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isCompany = useSelector((state) => state.auth.isCompany);
  const [firstName, setFirstName] = useState(currentUser?.first_name);
  const [lastName, setLastName] = useState(currentUser?.last_name);
  const [description, setDescription] = useState(currentUser?.description);
  const [linkedIn, setLinkedIn] = useState(currentUser?.linkedin_address);
  const [name, setName] = useState(currentUser?.name);
  const [teacherSkills, setTeacherSkills] = useState([]);
  const { t } = useTranslation();
  const [job, setJob] = useState(currentUser?.job);
  const { data, get, put } = useFetch();
  const optionSkills =
    data && !isCompany
      ? data.map((skill) => ({ key: skill.id, label: skill.title }))
      : "";

  const updateTeacherSkills = (skills) => {
    setTeacherSkills(skills);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      first_name: firstName,
      last_name: lastName,
      description: description,
      linkedin_address: linkedIn,
      job: job,
      category_ids: teacherSkills,
    });
  };

  const handleCompanySubmit = (event) => {
    event.preventDefault();
    onCompanySubmit({
      name: name,
      description: description,
    });
  };

  useEffect(() => {
    get("/categories");
  }, []);

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser?.first_name);
      setLastName(currentUser?.last_name);
      setDescription(currentUser?.description);
      setLinkedIn(currentUser?.linkedin_address);
      setName(currentUser?.name);
      setJob(currentUser?.job);
      setTeacherSkills(currentUser?.categories);
    }
  }, [currentUser]);

  return (
    <div className="EditProfile">
      <h3>{t("profile:editheader")}</h3>
      {currentUser && (currentUser?.first_name || currentUser?.name) && (
          <Form onSubmit={!isCompany ? handleSubmit : handleCompanySubmit}>
            {!isCompany && (
              <div>
                <FormGroup>
                  <FormLabel className="styled-label">{t("profile:firstname")}</FormLabel>
                  <FormControl
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="input-styled"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel className="styled-label">{t("profile:lastname")}</FormLabel>
                  <FormControl
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="input-styled"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel className="styled-label">{t("profile:description")}</FormLabel>
                  <FormControl
                    as="textarea"
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="input-styled"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel className="styled-label">{t("profile:linkedin")}</FormLabel>
                  <FormControl
                    value={linkedIn}
                    onChange={(e) => setLinkedIn(e.target.value)}
                    className="input-styled"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel className="styled-label">{t("profile:job")}</FormLabel>
                  <FormControl
                    value={job}
                    onChange={(e) => setJob(e.target.value)}
                    className="input-styled"
                  />
                </FormGroup>
                {currentUser?.role === "teacher" && optionSkills && (
                  <MultiSelectSkills
                    optionSkills={optionSkills}
                    updateTeacherSkills={updateTeacherSkills}
                    currentUser={currentUser}
                    className="input-styled"
                  />
                )}
              </div>
            )}
            {isCompany && (
              <>
                <FormGroup>
                  <FormLabel className="styled-label">{t("profile:company_name")}</FormLabel>
                  <FormControl
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="input-styled"
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel className="styled-label">{t("profile:description")}</FormLabel>
                  <FormControl
                    as="textarea"
                    required
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="input-styled"
                  />
                </FormGroup>
              </>
            )}
            <div className="text-center">
              <Button type="submit" className="submit-profile my-3">
                {t("profile:update_my_profile")}
              </Button>
            </div>
          </Form>
        )}
    </div>
  );
};

export default EditProfile;
