import { Form, Row } from "react-bootstrap";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";

const ChooseTagsForArticle = ({
  tag,
  allExistingArticleTags,
  articleId,
  reload,
}) => {
  const { post, destroy } = useFetch();
  const [articleTagsIds, setArticleTagsIds] = useState(
    (allExistingArticleTags &&
      allExistingArticleTags.map((articleTag) => articleTag.tag_id)) ||
      []
  );
  const [checked, setChecked] = useState(
    articleTagsIds.includes(Number(tag.id)) && "checked"
  );

  const findExistingArticleTag = (tagId) => {
    return allExistingArticleTags.find(
      (articleTag) => Number(articleTag.tag_id) === Number(tagId)
    );
  };

  const handleChecked = (event) => {
    if (event.target.checked) {
      post(
        "/admin/article_tags",
        {
          article_id: articleId,
          tag_id: event.target.value,
        },
        reload
      );
      setArticleTagsIds([...articleTagsIds, Number(event.target.value)]);
      setChecked("checked");
    } else {
      const toDeleteArticleTag = findExistingArticleTag(event.target.value);
      destroy(`/admin/article_tags/${toDeleteArticleTag?.id}`);
      setArticleTagsIds(
        articleTagsIds.filter((tag) => tag.tag_id != event.target.value)
      );
      setChecked("");
    }
  };

  useEffect(() => {
    setArticleTagsIds(allExistingArticleTags.map((tag) => tag.tag_id));
  }, []);

  return (
    <Form.Check
      inline
      className="m-2"
      type="checkbox"
      label={tag.name}
      value={tag.id}
      checked={checked}
      onChange={handleChecked}
    />
  );
};

export default ChooseTagsForArticle;
