import MDEditor from "@uiw/react-md-editor";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import useFetch from "hooks/useFetch";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const UpdateGlossaryDefinition = ({definition, handleClose, reload}) => {
  const [newDefinition, setNewDefinition] = useState("");
  const { t } = useTranslation("admin");
  const { patch } = useFetch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    await patch(
      `/admin/glossaries/${definition.id}`,
      { definition: newDefinition },
      displaySuccess(t("glossary_definition_updated")),
      displayError(t("glossary_definition_not_updated"))
    );
    handleClose();
    reload();
  };

  return (
    <div>
      <h6 className="mt-5">
        {t("define_definition")} -{" "}
        <small className="text-secondary">{t("use_markdown")}</small>
      </h6>
      <MDEditor
        value={newDefinition}
        onChange={setNewDefinition}
        className="mb-1"
        height={250}
      />
      <small>{t("before_modification")} : {definition.definition}</small>
      {newDefinition && newDefinition.length > 0 && (
        <div className="text-center my-4">
          <Button className="btn-warning text-white" onClick={handleSubmit}>
            {t("edit_definition")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default UpdateGlossaryDefinition;
