import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";

export const findTokenAfterLinkedinAuth = () => {
  let location = useLocation();
  const token = Cookies.get("token");

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  if (!token && location.search.includes("token")) {
    let query = useQuery();
    Cookies.set("token", query.get("token"));
  }
};
