import { Row, Col, Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SideMenu from "components/SideMenu/SideMenu";
import TagsFilter from "../TagsFilter/TagsFilter";
import useDeviceResize from "hooks/useDeviceResize";
import useFetch from "hooks/useFetch";
import Loading from "components/Loading";
import ArticleCard from "components/ArticleCard/ArticleCard";
import NoArticles from "assets/cactus.png";
import "./ArticlesIndex.scss";
import { useSelector } from "react-redux";
import { is } from "date-fns/locale";

const ArticlesIndex = ({ currentLanguageId }) => {
  const { t } = useTranslation("common");
  const [searched, setSearched] = useState("");
  const [articles, setArticles] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const { isMobile, checkOnDeviceResize, removeResizeListener } =
    useDeviceResize();
  const { isLoading, get } = useFetch();
  const isCompany = useSelector((state) => state.auth.isCompany);

  const getTags = async () => {
    const response = await get("/tags");
    setTags(response);
  };

  const getArticles = async () => {
    if (currentLanguageId && currentLanguageId !== null && !isCompany) {
      const response = await get(`/articles?language_id=${currentLanguageId}`);
      setArticles(response);
    } else if (!isCompany) {
      const response = await get("/articles");
      setArticles(response);
    } else if (isCompany && currentLanguageId && currentLanguageId !== null) {
      const response = await get(`/company/articles?language_id=${currentLanguageId}`);
      setArticles(response);
    } else if (isCompany) {
      const response = await get("/company/articles");
      setArticles(response);
    }
  };

  const filterByKeywords = () => {
    if (searched.length > 0) {
      const filtered = articles.filter(
        (article) =>
          article.title.toLowerCase().includes(searched.toLowerCase()) ||
          article.description.toLowerCase().includes(searched.toLowerCase())
      );
      setArticles(filtered);
    } else {
      getArticles();
    }
  };

  const filterByTags = () => {
    if (selectedTags.length > 0) {
      const filtered = articles.filter((article) => {
        const articleTags = article.tags.map((tag) => tag.id);
        return selectedTags.every((tag) => articleTags.includes(Number(tag)));
      });
      setArticles(filtered);
    } else {
      getArticles();
    }
  };

  useEffect(() => {
    checkOnDeviceResize();
    getArticles();
    getTags();
  }, []);

  useEffect(() => {
    getArticles();
    getTags();
  }, [currentLanguageId]);

  useEffect(() => {
    filterByTags();
  }, [selectedTags]);

  useEffect(() => {
    filterByKeywords();
  }, [searched]);

  removeResizeListener();

  return (
    <div>
      <div className="ArticlesPage">
        <Row style={{ minHeight: "90vh" }}>
          <Col className="col-lg-3 sidemenu d-flex flex-column justify-content-center align-items-center">
            <SideMenu
              setInput={setSearched}
              bottomComponent={
                <TagsFilter
                  tags={tags}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                />
              }
            />
          </Col>
          {isLoading && <Loading isMobile={isMobile} />}
          {!isLoading && articles && articles.length > 0 && (
            <Col className="col-lg-8 mx-auto">
              <h4 className="page-articles-title text-center mb-4">
                {t("discover_our_memos")}{" "}
              </h4>
              <Row className="mx-3 p-0">
                {articles?.map((article) => (
                  <ArticleCard key={article.id} article={article} />
                ))}
              </Row>
            </Col>
          )}
          {!isLoading && articles && articles.length === 0 && (
            <Col className="col-lg-8 mx-auto text-center">
              <h4 className="page-articles-title text-center mb-4">
                {t("discover_our_memos")}{" "}
              </h4>
              <p className="my-2">{t("no_article_avalaible")}</p>
              <Image src={NoArticles} className="no-articles-image" />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ArticlesIndex;
