import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const isLogged = useSelector((state) => state.auth.isLogged);
  const isCompany = Cookies.get("company");
  return (
    <>
      {isCompany && isCompany !== undefined ? (
        <Route
          {...rest}
          render={(props) =>
            isLogged && restricted ? (
              <Redirect to="/profile/company/" />
            ) : (
              <Component {...props} />
            )
          }
        />
      ) : (
        <Route
          {...rest}
          render={(props) =>
            isLogged && restricted ? (
              <Redirect to="/profile/dashboard" />
            ) : (
              <Component {...props} />
            )
          }
        />
      )}
    </>
  );
};

export default PublicRoute;
