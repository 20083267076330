import React from "react";
import { useHistory, Link } from "react-router-dom";
import "./LearningPathCard.scss";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Badge,
  Accordion,
  Button,
  Row,
  ProgressBar,
} from "react-bootstrap";
import ButtonPrimary from "components/ButtonPrimary/ButtonPrimary";
import { useTranslation } from "react-i18next";
import defaultcover from "assets/covers/defaultcover.svg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CustomToggle from "../CustomToggle/CustomToggle";
import { capitalize } from "tools/capitalize";

const LearningPathCard = ({ learningPath, subscribed, imgHeight }) => {
  const { title, price_in_cents, id, description, courses, categories, slug } =
    learningPath;
  const currentUser = useSelector((state) => state.auth.currentUser);
  let route = useLocation();
  const profileCoursesPath = "/profile/mycourses";

  const history = useHistory();
  const { t } = useTranslation();

  const handleLearnMore = () => {
    history.push(`/learning_paths/${slug}`);
  };

  const getProgress = (course) => {
    if (route.pathname === profileCoursesPath) {
      return course.progress_states.find(
        (progress) => progress.user_id === currentUser.id
      );
    } else if (route.pathname !== profileCoursesPath) {
      const selectedUserId = route.pathname.split("/")[3];
      return course.progress_states.find(
        (progress) => Number(progress.user_id) === Number(selectedUserId)
      );
    }
  };

  const handleChapter = (courseId) => {
    history.push(`/chapters?courseId=${courseId}`);
  };

  const giveMeGoodImageURL = (learningPath) => {
    if (route.pathname === "/profile/mycourses" && learningPath.large_image_url)
      return learningPath.large_image_url;
    if (
      route.pathname === "/profile/mycourses" &&
      learningPath.large_image_url === null
    )
      return defaultcover;
    if (route.pathname !== "/profile/mycourses" && learningPath.image_url)
      return learningPath.image_url;
    if (
      route.pathname !== "/profile/mycourses" &&
      learningPath.image_url === null
    )
      return defaultcover;
  };

  return (
    <Accordion
      className={`${
        route.pathname === profileCoursesPath
          ? "CourseCard m-3 col-lg-8 col-sm-12"
          : "CourseCard m-3 col-lg-5 col-sm-12"
      }`}
      data-aos="fade-up"
    >
      <Card>
        <Card.Img
          variant="top"
          src={giveMeGoodImageURL(learningPath)}
          alt="IMAGE"
          style={{ height: `${imgHeight}` }}
        />

        <Card.Header className="LearningPathCard__title">{title}</Card.Header>
        <Card.Body className="d-flex flex-column align-items-center py-0">
          <Row>
            {!subscribed && description != null && (
              <CustomToggle
                eventKey="0"
                className="btn btn-warning btn-sm mr-3"
              >
                💡 {capitalize(t("admin:description"))}
              </CustomToggle>
            )}
            <CustomToggle as={Button} eventKey="1">
              📖 {capitalize(t("company:course"))}
            </CustomToggle>
            {!subscribed && (
              <button
                className="btn btn-warning mx-1"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "#ff8a00",
                }}
                onClick={handleLearnMore}
              >
                ✨ {t("learn_more")}
              </button>
            )}
          </Row>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{!subscribed && <h6>{description}</h6>}</Card.Body>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <ListGroup className="list-group-flush">
                <p>{t("includes_courses")} :</p>
                {courses.map((course) => (
                  <ListGroupItem key={course.id}>
                    <h6>{course.title}</h6>
                    {subscribed && (
                      <div className="d-flex flex-column align-items-center">
                        <p className="small m-1">
                          {`${
                            route.pathname === profileCoursesPath
                              ? t("your_progress")
                              : t("profile:progression")
                          } : ${getProgress(course).progression || 0} %`}{" "}
                          {`${getProgress(course).progression ? "🔥" : "😕"}`}
                        </p>
                        <ProgressBar
                          className="w-100"
                          variant={
                            getProgress(course).progression === 100
                              ? "success"
                              : "warning"
                          }
                          now={
                            getProgress(course).progression
                              ? getProgress(course).progression
                              : 0
                          }
                        />
                        {route.pathname === profileCoursesPath && (
                          <ButtonPrimary
                            handleClick={() => handleChapter(course.id)}
                            label={t("common:access_to_courses")}
                            sizeClass="sm py-1 px-2 my-2"
                          />
                        )}
                      </div>
                    )}
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Card.Body>
          </Accordion.Collapse>
        </Card.Body>
      </Card>
    </Accordion>
  );
};

export default LearningPathCard;
