import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Accordion,
  Card,
  Button,
  ListGroup,
  Image,
} from "react-bootstrap";
import "./Chapter.scss";
import defaultCover from "assets/covers/defaultcover.svg";
import { useSelector, useDispatch } from "react-redux";
import { displayWarning } from "stores/flashmessages/flashMiddleware";
import { useTranslation } from "react-i18next";
import { verifyIfUserAccountLimitDateAreExpired } from "helpers/verifyIfUserAccountLimitDateAreExpired";
import Loading from "components/Loading";
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter";
import BookmarkBanner from "../../../components/BookmarkBanner/BookmarkBanner";

const Chapter = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { isLoading, get } = useFetch();
  const { t } = useTranslation("common");
  let query = useQuery();
  let courseId = query.get("courseId");
  const [course, setCourse] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [existingBookmark, setExistingBookmark] = useState(false);
  const [bookmark, setBookmark] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const getCourse = async () => {
    const response = await get(`/courses/${courseId}`);
    setCourse(response);
  };

  const getChapters = async () => {
    const response = await get(`/courses/${courseId}/chapters`);
    setChapters(response);
  };

  const getLastBookmarkedLessonForCourse = async () => {
    const response = await get(`/courses/${courseId}/last_lesson_bookmark`);
    if (response) {
      console.log(response);
      if (response && response.lesson_id) {
        setExistingBookmark(true);
        setBookmark(response);
      }
      return;
    }
  };

  useEffect(() => {
    getChapters();
    getCourse();
    getLastBookmarkedLessonForCourse();
  }, []);

  useEffect(() => {
    if (currentUser) {
      verifyIfUserAccountLimitDateAreExpired(
        currentUser,
        t,
        dispatch,
        displayWarning,
        history
      );
    }
  }, [currentUser]);

  const handleLesson = (chapterId, currentLessonId) => {
    history.push(
      `/courses/${courseId}/chapters/${chapterId}/lessons/${currentLessonId}`
    );
  };

  const handleRedirectFromBookmark = () => {
    history.push(
      `/courses/${courseId}/chapters/${bookmark.chapter_id}/lessons/${bookmark.lesson_id}`
    );
  };

  return (
    <div className="d-flex flex-column">
      <div style={{ position: "relative", minHeight: "80px" }}>
        {existingBookmark ? (
          <BookmarkBanner
            onClose={() => setExistingBookmark(false)}
            onRedirect={handleRedirectFromBookmark}
          />
        ) : null}
      </div>
      <Container>
        {isLoading && (
          <div className="d-flex justify-content-center my-5">
            <Loading />
          </div>
        )}
        {!isLoading && (
          <Row className="my-4">
            <Col md={3} lg={3} className="d-none d-md-block mb-5">
              {course && (
                <Card className="left-chapter-card">
                  <Image
                    src={course.image_url ? course.image_url : defaultCover}
                    className="left-chapter-card-image"
                    roundedCircle
                  />
                  <p className="course-title text-center my-3">
                    {course.title}
                  </p>
                  <p className="course-description">
                    {" "}
                    {course.description && course.description}
                  </p>
                </Card>
              )}
            </Col>
            <Col sm={12} md={9} lg={9} className="right-chapter-column">
              {chapters &&
                chapters.map((chapter, index) => (
                  <Accordion
                    key={chapter.id}
                    defaultActiveKey={chapters.length === 1 ? "0" : null}
                  >
                    <Card className="right-chapter-card mt-0">
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="none"
                          eventKey={index.toString()}
                          className="course-toggler"
                        >
                          <Row>
                            <p className="id-number">{index + 1}</p>{" "}
                            <p className="chapter-title">
                              {capitalizeFirstLetter(chapter.title)}
                            </p>
                          </Row>
                          <p className="chapter-description mb-0">
                            {chapter.description && chapter.description}
                          </p>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body style={{ padding: 0 }}>
                          {chapter.lessons.map((lesson) => (
                            <ListGroup.Item
                              key={lesson.id}
                              className="lesson-list-element"
                              onClick={() =>
                                handleLesson(chapter.id, lesson.id)
                              }
                            >
                              {capitalizeFirstLetter(lesson.title)}
                            </ListGroup.Item>
                          ))}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                ))}
              <div className="go-courses-button-section">
                <button
                  className="go-courses-button"
                  onClick={() => history.push("/profile/mycourses")}
                >
                  {t("profile:go_to_my_courses")}
                </button>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Chapter;
