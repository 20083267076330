import "./ForgotPassword.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logincover from "assets/covers/right-side-login.png";
import leftSidePicture from "assets/covers/left-side-login.png";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  Button,
  Container,
  Image,
} from "react-bootstrap";
import useFetch from "hooks/useFetch";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  const { post } = useFetch();
  const dispatch = useDispatch();

  const forgot = async (e) => {
    e.preventDefault();
    const data = {
      user: {
        email: email,
      },
    };
    await post("/password", data);
    dispatch(displaySuccess(t("password:reset_send")));
  };

  return (
    <Container fluid className="ForgotPassword">
      <Row style={{ minHeight: "100%" }}>
        <Col
          xs={12}
          md={6}
          className="ForgotPassword_col d-flex flex-column justify-content-center col-lg-6 col-md-12"
        >
          <Image
            src={leftSidePicture}
            alt="left side login page picture"
            className="left-side-picture"
            height={700}
          />
          <div className="forgot-text-block">
            <h2 className="forgot-password-title p-3">{t("password:title")}</h2>
            <Form
              className="form-div d-flex flex-column"
              onSubmit={forgot}
            >
              <FormControl
                className="p-4 mb-1 mt-3"
                type="email"
                placeholder={t("login:placeholderemail")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <div className="text-center">
                <button
                  type="submit"
                  className="submit-email-btn w-75"
                  size="lg"        
                >
                  {t("password:submit")}
                </button>
              </div>
              <p className="already-account mt-3">
                {t("register:accountquestion")}
                <Link to="/login">
                  <span> {t("register:loginnow")}</span>
                </Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col
          md={6}
          className="ForgotPassword_col d-none d-lg-block text-center"
        >
          <Image
            src={logincover}
            alt="Illustration forgot password page"
            height={800}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
