import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const StandardArticleForm = ({ articles, noLabel, handleChange }) => {
  const { t } = useTranslation("admin");
  return (
    <div className="my-3">
      {noLabel === false && <h6>{t("choose_an_article")}</h6>}
      <Form.Control as="select" className="my-3" onChange={handleChange}>
        <option>{t("choose_an_article")}</option>
        {articles?.map((article) => (
          <option key={article.id} value={article.id}>
            {article.id} - {article.title}
          </option>
        ))}
      </Form.Control>
    </div>
  );
};

export default StandardArticleForm;
