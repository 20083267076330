import { Card, Image } from "react-bootstrap";
import ArsenioLogo from "assets/arseniologo.png";
import "./LittleCourseCard.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";


const LittleCourseCard = ({ course }) => {
  const { t } = useTranslation("profile");
  const history = useHistory();

  const handleChapter = (courseId) => {
    history.push(`/chapters?courseId=${courseId}`);
  };

  return (
    <Card className="LittleCourseCard">
      {course.progressState && (
        <>
          <Card.Body>
            <div className="picture-section text-center">
              <Image
                src={course.image_url ? course.image_url : ArsenioLogo}
                roundedCircle
                className="course-picture"
              />{" "}
            </div>
            <div className="course-title-section">
              <p>{course.title}</p>
            </div>
            <div className="state-section">
              <p>
                {course.progressState.progression === 100
                  ? t("finished")
                  : t("not_finished")}
              </p>
            </div>
          </Card.Body>
          <Card.Footer>
            <div className="course-button-section">
              <button className="course-button" onClick={() => handleChapter(course.id)}>
                {t("common:access_to_courses")}{" "}
              </button>
            </div>
          </Card.Footer>
        </>
      )}
    </Card>
  );
};

export default LittleCourseCard;
