import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Sidebar.scss";
import { Nav, Accordion, Button } from "react-bootstrap";
import { GoTriangleRight } from "react-icons/go";

const Sidebar = () => {
  const { t } = useTranslation("admin");

  return (
    <Nav className="Sidebar flex-column">
      <h2 className="mt-5 ml-2 mb-5">{t("dashboard_admin")}</h2>
      <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {t("users_section")} <GoTriangleRight />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <NavLink className="ml-5" to="/admin/statistics">
              <small>- {t("admin:see_statistics")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/specificuser">
              <small>- {t("admin:see_users_courses_statistics")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/users">
              <small>-   {t("manage_users")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/approvals">
              <small>- {t("manage_approvals")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/newpathsubscriptions">
              <small>- {t("add_path_subscription")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/newarticlesubscriptions">
              <small>- {t("add_article_subscription")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/mentoringsessions">
              <small>- {t("manage_mentoring_sessions")}</small>
            </NavLink>
            <br />

          </>
        </Accordion.Collapse>
      </Accordion>
      <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {t("courses_section")} <GoTriangleRight />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <NavLink className="ml-5" to="/admin/courses">
              <small>- {t("all_courses")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/import">
              <small>- {t("import_course")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/import/edit">
              <small>- {t("admin:edit_course")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/learning_paths">
              <small>- {t("manage_learning_paths")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/categories">
              <small>- {t("manage_categories")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/newpathsubscriptionsforcompanyorbootcampusers">
              <small>- {t("add_path_for_company_or_bootcamp_users")}</small>
            </NavLink>
            <br />
          </>
        </Accordion.Collapse>
      </Accordion>
      <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {t("manage_mentor_profile")} <GoTriangleRight />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <NavLink className="ml-5" to="/admin/mentorprofile/new">
              <small>- {t("add_mentor_profile")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/mentorprofile/update">
              <small>- {t("update_mentor_profile")}</small>
            </NavLink>
            <br />
          </>
        </Accordion.Collapse>
      </Accordion>
      <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {t("admin:manage_company")} <GoTriangleRight />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <NavLink className="ml-5" to="/admin/company/create">
              <small>- {t("admin:create_company")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/companies/management">
              <small>- {t("admin:manage_company")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/company/users/new">
              <small>- {t("admin:add_users_to_company")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/company/usersrequests">
              <small>- {t("admin:manage_company_user_request")}</small>
            </NavLink>
            <br />
          </>
        </Accordion.Collapse>
      </Accordion>
      <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {t("admin:manage_bootcamp")} <GoTriangleRight />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <NavLink className="ml-5" to="/admin/bootcamps/new">
              <small>- {t("admin:create_bootcamp")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/bootcamps/management">
              <small>- {t("admin:manage_bootcamp")}</small>
            </NavLink>
            <br />
          </>
        </Accordion.Collapse>
      </Accordion>
      <NavLink to="/admin/teachers" className="ml-4">
        {t("manage_teachers")}
      </NavLink>
      <NavLink to="/admin/bootcamppresences" className="ml-4">
        {t("manage_bootcamp_presences")}
      </NavLink>
      <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {t("admin:manage_glossary")} <GoTriangleRight />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <NavLink className="ml-5" to="/admin/glossary/new">
              <small>- {t("add_definition")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/glossary/manage">
              <small>- {t("edit_or_delete_definition")}</small>
            </NavLink>
            <br />
          </>
        </Accordion.Collapse>
      </Accordion>
      <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {t("admin:manage_articles")} <GoTriangleRight />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <NavLink className="ml-5" to="/admin/articles">
              <small>- {t("manage_articles")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/articles/new">
              <small>- {t("add_new_article")}</small>
            </NavLink>
            <br />
            <NavLink className="ml-5" to="/admin/articles/update">
              <small>- {t("update_article")}</small>
            </NavLink>
            <br />
          </>
        </Accordion.Collapse>
      </Accordion>
      <Accordion>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          {t("admin:manage_tags")} <GoTriangleRight />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <>
            <NavLink className="ml-5" to="/admin/tags">
              <small>- {t("manage_tags")}</small>
            </NavLink>
            <br />
          </>
        </Accordion.Collapse>
      </Accordion>
    </Nav>
  );
};

export default Sidebar;
