import React from "react";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import "./CustomToggle.scss";

const CustomToggle = ({ children, eventKey }) => {
  return (
    <button
      type="button"
      onClick={useAccordionToggle(eventKey)}
      className="custom-toggle btn btn-sm mx-1"
    >
      {children}
    </button>
  );
};

export default CustomToggle;
