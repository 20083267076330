import SignatureComponent from "components/SignatureComponent/SignatureComponent";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
import Cookies from "js-cookie";
import useFetch from "hooks/useFetch";
import {
  fetchCurrentUser,
  fetchCurrentUserMinimalsDetails,
} from "stores/authentication/authMiddleware";
import Loading from "components/Loading";
import "./ManageSignature.scss";

const ManageSignature = () => {
  const { t } = useTranslation("profile");
  const { put, patch, isLoading } = useFetch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [newUserSignaturePicture, setNewUserSignaturePicture] = useState(null);
  const [actualUserSignaturePicture, setActualUserSignaturePicture] =
    useState(null);
  const [isWantToEditSignature, setIsWantToEditSignature] = useState(false);
  const canvas = useRef(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    event.preventDefault();
    setNewUserSignaturePicture(canvas.current.getSignature());
  };

  const dispatchActionToUpdateCurrentUserState = () => {
    const token = Cookies.get("token");
    token && dispatch(fetchCurrentUserMinimalsDetails(token));
  };

  const updateCurrentUserSignaturePicture = async () => {
    if (newUserSignaturePicture) {
      await put(
        "/profile",
        {
          encoded_signature_picture: newUserSignaturePicture,
        },
        setIsWantToEditSignature(false),
        displaySuccess(t("user_signature_picture_updated")),
        displayError(t("user_signature_picture_not_updated"))
      );
      resetCanvas();
      dispatchActionToUpdateCurrentUserState();
    }
  };

  const resetCanvas = () => {
    canvas?.current?.getCanvas()?.clear();
    setNewUserSignaturePicture(null);
  };

  const handleButtonClick = (e) => {
    if (isWantToEditSignature) {
      handleClick(e);
    }

    if (!isWantToEditSignature) {
      setIsWantToEditSignature(true);
    }
  };

  useEffect(() => {
    currentUser?.encoded_signature_picture
      ? setActualUserSignaturePicture(currentUser.encoded_signature_picture)
      : null;
  }, [currentUser]);

  useEffect(() => {
    updateCurrentUserSignaturePicture();
  }, [newUserSignaturePicture]);

  const currentUserAlreadyHaveASignature = (
    <Card className="signature-card">
      {(isLoading && (
        <div className="d-flex flex-row jusitfy-content-center align-items-center w-100">
          <Loading />
        </div>
      )) || (
        <Row>
          {(!isWantToEditSignature && (
            <Col sm={12} md={6}>
              <p>{t("your_actual_signature_is")} :</p>
              <img src={actualUserSignaturePicture} alt="signature" />
            </Col>
          )) || (
            <Col sm={12} md={6}>
              <p>{t("you_can_sign_here")} :</p>
              <SignatureComponent
                ref={canvas}
                actualSignature={actualUserSignaturePicture}
              />
            </Col>
          )}
          <Col
            sm={12}
            md={6}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <button
              className="update-signature-button text-white"
              onClick={(e) => handleButtonClick(e)}
            >
              {isWantToEditSignature
                ? t("save_signature")
                : t("edit_signature")}
            </button>

            {isWantToEditSignature && (
              <button
                className="reset-signature-button text-white"
                onClick={resetCanvas}
              >
                {t("reset_for_draw_another_signature")}
              </button>
            )}
          </Col>
        </Row>
      )}
    </Card>
  );

  const currentUserDoesntHaveASignatureYet = (
    <Card className="signature-card">
      <Col sm={12} md={12}>
        <p>{t("you_can_sign_here")} :</p>
        <SignatureComponent
          ref={canvas}
          actualSignature={actualUserSignaturePicture}
        />
        <Row className="mt-3 w-100 mx-auto ">
          <Col sm={12} md={6} className="my-3">
            <button
              className="save-signature-button text-white"
              onClick={handleClick}
            >
              {t("save_signature")}
            </button>
          </Col>
          <Col sm={12} md={6} className="my-3">
            <button
              className="reset-signature-button text-white"
              onClick={resetCanvas}
            >
              {t("reset_for_draw_another_signature")}
            </button>
          </Col>
        </Row>
      </Col>
    </Card>
  );

  return (
    <div>
      <h3 className="text-center">{t("manage_my_signature")}</h3>
      <Row className="text-center my-4">
        {actualUserSignaturePicture
          ? currentUserAlreadyHaveASignature
          : currentUserDoesntHaveASignatureYet}
      </Row>
    </div>
  );
};

export default ManageSignature;
