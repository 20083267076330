import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link
} from "react-router-dom";

export default class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.log("Erreur : ", error);
  }

  render() {
    return this.state.hasError ? (
      <div style={{ height: "50vh", marginTop: "15%" }}>
        <div className="mb-3 d-flex align-items-center justify-content-center">
          <h3 style={{ fontWeight: "bold", fontSize: "40px" }}>
            {this.props.translator("error_catched")}
          </h3>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <Router>
            <Switch>
              <Link to="/">
                <Button
                  className="btn btn-warning text-white"
                  style={{ padding: "5px 20px" }}
                  onClick={(e) => this.setState({ hasError: false })}
                >
                  {this.props.translator("go_home")}
                </Button>
              </Link>
            </Switch>
          </Router>
        </div>
      </div>
    ) : (
      this.props.children
    );
  }
}
