import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { get } from "js-cookie";
import useFetch from "hooks/useFetch";
import "./ArticleReader.scss";
import { Container, Button, Image } from "react-bootstrap";
import MarkdownConverter from "components/MarkdownConverter/MarkdownConverter";
import { useSelector } from "react-redux";
import NotPermitted from "pages/NotPermitted/NotPermitted";

const ArticleReader = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { get } = useFetch();
  const { t } = useTranslation("article");
  const location = useLocation();
  const [selectedArticleId, setSelectedArticleId] = useState(
    location.pathname.split("/")[2]
  );
  const [chapters, setChapters] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(1);
  const [readyToRead, setReadyToRead] = useState(
    location?.state?.readyToDisplay
  );
  const [isLoading, setIsLoading] = useState(true);

  const checkIfUserCanRead = () => {
    return (
      currentUser.articles_subscriptions_ids?.find(
        (id) => Number(id) == Number(selectedArticleId)
      ) !== undefined
    );
  };

  const getArticleChapters = async () => {
    const response = await get(
      `/articles/${selectedArticleId}/article_chapters`
    );
    setChapters(response);
  };

  useEffect(() => {
    getArticleChapters();
  }, [selectedArticleId]);

  useEffect(() => {
    if (currentUser) {
      setReadyToRead(checkIfUserCanRead());
      setIsLoading(false);
    }
  }, [currentUser]);

  return (
    <div>
      {readyToRead &&
        chapters &&
        chapters.length > 0 &&
        chapters.map(
          (chapter) =>
            chapter.position == currentPosition && (
              <div key={chapter.id}>
                <div className="article_chapter_header">
                  <MarkdownConverter text={chapter.title} />
                </div>
                <div className="article_chapter_content">
                  <Container>
                    <MarkdownConverter text={chapter.content} />
                    <div className="text-center my-4">
                      {currentPosition !== 1 && (
                        <Button
                          className="next_chapter_btn btn btn-lg text-white"
                          onClick={() =>
                            setCurrentPosition(currentPosition - 1)
                          }
                        >
                          {t("go_to_previous_chapter")}{" "}
                        </Button>
                      )}
                      {currentPosition !== chapters.length && (
                        <Button
                          className="next_chapter_btn btn btn-lg text-white"
                          onClick={() =>
                            setCurrentPosition(currentPosition + 1)
                          }
                        >
                          {t("go_to_next_chapter")}{" "}
                        </Button>
                      )}
                    </div>
                  </Container>
                </div>
              </div>
            )
        )}

      {isLoading && (
        <p className="text-center my-4">{t("admin:loading")} </p>
      )}
      {!readyToRead && !isLoading && (
        <div className="not-permitted-section">
          <NotPermitted location={"profile"} redirection={"/profile/dashboard"} />
        </div>
      )}
    </div>
  );
};

export default ArticleReader;
