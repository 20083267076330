import { Row, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import useDeviceResize from "hooks/useDeviceResize";
import useFetch from "hooks/useFetch";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const EditUserRemainingSessions = ({ user, reload, handleClose }) => {
  const [remainingSessions, setRemainingSessions] = useState("");
  const { t } = useTranslation("admin");
  const {
    isMobile,
    checkOnDeviceResize,
    removeResizeListener,
  } = useDeviceResize();
  const { patch } = useFetch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    await patch(
      `/admin/users/${user.id}`,
      { remaining_session: remainingSessions },
      displaySuccess(t("remaining_sessions_updated")),
      displayError(t("remaining_sessions_not_updated"))
    );
    handleClose();
    reload();
  };

  useEffect(() => {
    checkOnDeviceResize();
  }, []);

  removeResizeListener();

  return (
    <Row className="d-flex justify-content-center">
      <Form onSubmit={handleSubmit}>
        <Form.Group
          className={!isMobile ? "input-group mb-3" : "mb-3"}
          controlId="BasicFormRemainingSession"
        >
          <Form.Control
            type="text"
            onChange={(e) => setRemainingSessions(e.target.value)}
          />
          <div>
            <Button
              variant="warning"
              className={!isMobile ? "text-white ml-3" : "text-white mt-3"}
              type="submit"
            >
              {t("edit_remaining_sessions")}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </Row>
  );
};

export default EditUserRemainingSessions;
