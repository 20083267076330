import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { Button, Form } from "react-bootstrap";
import StandardCompanyForm from "components/StantardCompanyForm";
import StandardArticleForm from "components/StandardArticleForm";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import { useDispatch } from "react-redux";
import StandardUserForm from "components/StantardUserForm";

const AddArticleSubscription = () => {
  const { t } = useTranslation("admin");
  const dispatch = useDispatch();
  const { get, post, isLoading } = useFetch();
  const [forCompany, setForCompany] = useState(true);
  const [forUser, setForUser] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allArticles, setAllArticles] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [companyChoiceError, setCompanyChoiceError] = useState(false);
  const [articleChoiceError, setArticleChoiceError] = useState(false);
  const [userChoiceError, setUserChoiceError] = useState(false);

  const getAllCompanies = async () => {
    const response = await get("/admin/companies");
    setAllCompanies(response);
  };

  const getAllArticles = async () => {
    const response = await get("/admin/articles");
    setAllArticles(response);
  };

  const getAllUsers = async () => {
    const response = await get("/admin/users");
    setAllUsers(response);
  };

  const checkForCompanyChoiceError = (event) => {
    const allCompaniesIds = allCompanies.map((company) => Number(company.id));
    if (allCompaniesIds.includes(Number(event.target.value)))
      setCompanyChoiceError(false);
    else setCompanyChoiceError(true);
  };

  const checkForArticleChoiceError = (event) => {
    const allArticlesIds = allArticles.map((article) => Number(article.id));
    if (allArticlesIds.includes(Number(event.target.value)))
      setArticleChoiceError(false);
    else setArticleChoiceError(true);
  };

  const checkForUserChoiceError = (event) => {
    const allUsersIds = allUsers.map((user) => Number(user.id));
    if (allUsersIds.includes(Number(event.target.value)))
      setUserChoiceError(false);
    else setUserChoiceError(true);
  };

  const handleChangeCompany = (e) => {
    checkForCompanyChoiceError(e);
    setSelectedCompany(e.target.value);
  };

  const handleChangeArticle = (e) => {
    checkForArticleChoiceError(e);
    setSelectedArticle(e.target.value);
  };

  const handleChangeUser = (e) => {
    checkForUserChoiceError(e);
    setSelectedUser(e.target.value);
  };

  const handleSubscriptionRecipient = () => {
    if (forUser) {
      setSelectedUser(null);
      setForCompany(true);
      setForUser(false);
    } else {
      setSelectedCompany(null);
      setForCompany(false);
      setForUser(true);
    }
  };

  const handleSubmitForCompany = (event) => {
    event.preventDefault();
    post(
      `/admin/article_subscriptions?company_id=${selectedCompany}&article_id=${selectedArticle}`,
      null,
      dispatch(displaySuccess(t("admin:article_subscription_for_company_done")))
    );
  };

  const handleSubmitForUser = (event) => {
    event.preventDefault();
    post(
      "/admin/article_subscriptions",
      { article_id: selectedArticle, user_id: selectedUser },
      dispatch(displaySuccess(t("admin:article_subscription_for_user_done")))
    );
  };

  useEffect(() => {
    getAllUsers();
    getAllCompanies();
    getAllArticles();
  }, []);

  return (
    <div className="text-center">
      <h3 className="text-center my-3">{t("add_article_subscription")}</h3>
      <Form>
        <Form.Check
          inline
          label={t("add_a_user_subscription")}
          type="checkbox"
          checked={forUser}
          onChange={handleSubscriptionRecipient}
          className="my-3"
        />

        <div>
          {forCompany && (
            <StandardCompanyForm
              companies={allCompanies}
              noLabel={true}
              handleChange={handleChangeCompany}
            />
          )}
          {forCompany && selectedCompany && companyChoiceError && (
            <span className="text-danger">{t("choose_a_valid_company")}</span>
          )}
        </div>

        <div>
          {forUser && !isLoading && allUsers.length > 0 && (
            <StandardUserForm
              users={allUsers}
              noLabel={true}
              handleChange={handleChangeUser}
            />
          )}

          {forUser && allUsers.length === 0 && (
            <p className="text-left">{t("loading")}</p>
          )}

          {forUser && selectedUser && userChoiceError && (
            <span className="text-danger">{t("choose_a_valid_user")}</span>
          )}
        </div>

        <div>
          <StandardArticleForm
            articles={allArticles}
            noLabel={true}
            handleChange={handleChangeArticle}
          />
          {articleChoiceError && (
            <span className="text-danger">{t("choose_a_valid_article")}</span>
          )}
        </div>

        {forCompany &&
          selectedCompany &&
          !companyChoiceError &&
          selectedArticle && (
            <Button
              className="btn btn-warning text-white"
              onClick={handleSubmitForCompany}
            >
              {t("add_all_employees_to_this_article")}{" "}
            </Button>
          )}

        {forUser && selectedUser && !userChoiceError && selectedArticle && (
          <Button
            className="btn btn-warning text-white"
            onClick={handleSubmitForUser}
          >
            {t("add_this_user_to_this_article")}{" "}
          </Button>
        )}
      </Form>
    </div>
  );
};

export default AddArticleSubscription;
