import MarkdownConverter from "components/MarkdownConverter/MarkdownConverter";
import { Card } from "react-bootstrap";
import "./GlossaryLine.scss";

const GlossaryLine = ({ glossary }) => {
  const text = `${glossary.title} \n \n ${glossary.definition}`;
  return (
    <Card className="glossary-line">
      <MarkdownConverter text={text} />
    </Card>
  );
};

export default GlossaryLine;
