import useFetch from "hooks/useFetch";
import { Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "./BootcampSessionModal.scss";

const BootcampSessionModal = ({ show, handleClose, session, reload }) => {
  const { t } = useTranslation("admin");
  const { put } = useFetch();
  const [newStartDate, setNewStartDate] = useState(session.start_date);
  const [newEndDate, setNewEndDate] = useState(session.end_date);
  const [newNotionUrl, setNewNotionUrl] = useState(session.notion_url);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      start_date: newStartDate,
      end_date: newEndDate,
      notion_url: newNotionUrl,
    };
    const response = await put(`/admin/bootcamp_sessions/${session.id}`, data);
    if (response) {
      handleClose();
      reload();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-80w mx-auto"
      id="adminBootcampModal"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "20px" }}>
          {t("update_bootcamp_session_for")} : {session.company.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center align-items-center">
          <Form className="w-75">
            <div className="my-3">
              <Form.Label>{t("start_date")}</Form.Label>
              <Form.Control
                type="date"
                placeholder=""
                defaultValue={session.start_date}
                onChange={(e) => setNewStartDate(e.target.value)}
              />
            </div>
            <div className="my-3">
              <Form.Label>{t("end_date")}</Form.Label>
              <Form.Control
                type="date"
                placeholder=""
                defaultValue={session.end_date}
                onChange={(e) => setNewEndDate(e.target.value)}
              />
            </div>
            <div className="my-3">
              <Form.Label>{t("notion_url")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("notion_url")}
                defaultValue={session.notion_url}
                onChange={(e) => setNewNotionUrl(e.target.value)}
              />
            </div>
            <div className="text-center">
              <button
                className="my-3 btn btn-warning text-white"
                onClick={(e) => handleSubmit(e)}
              >
                {t("send")}
              </button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BootcampSessionModal;
