import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Card, Col, Container, Image, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import noavatar from "assets/noavatar.jpg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const MentorProfile = ({ selectedMentor, show, handleClose }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("profile");
  const { get, isLoading } = useFetch();
  const [mentorProfile, setMentorProfile] = useState({});
  const [mentorExperiences, setMentorExperiences] = useState([]);
  const [mentorSkills, setMentorSkills] = useState([]);
  const half = Math.ceil(mentorSkills.length / 2);
  const location = useLocation();
  const route = useLocation().pathname.split("/")[1];

  const getMentorProfile = async () => {
    const data = await get(
      `/users/${currentUser.id}/mentor_profiles/${selectedMentor}`
    );
    setMentorProfile(data[0]);
  };

  useEffect(() => {
    if (selectedMentor === undefined) {
      selectedMentor = location.state.selectedMentor;
    }
    getMentorProfile();
  }, []);

  useEffect(() => {
    getMentorProfile();
  }, [selectedMentor]);

  useEffect(() => {
    const getMentorExperiences = async () => {
      const data = await get(
        `/users/${currentUser.id}/mentor_experiences?mentor_profile_id=${mentorProfile.id}`
      );
      setMentorExperiences(data);
    };

    const getMentorSkills = async () => {
      const data = await get(
        `/users/${currentUser.id}/mentor_skills?mentor_profile_id=${mentorProfile.id}`
      );
      setMentorSkills(data);
    };

    getMentorExperiences();
    getMentorSkills();
  }, [mentorProfile]);

  return (
    <>
      {mentorProfile &&
        Object.values(mentorProfile).length > 0 &&
        !isLoading &&
        route !== "displayMentor" && (
          <Modal
            dialogClassName="modal-80w mx-auto"
            aria-labelledby="custom-modal-styling"
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {t("discover_profile_of")} {mentorProfile.mentor.first_name}{" "}
                {mentorProfile.mentor.last_name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={12} lg={3} className="text-center">
                  <Image
                    src={
                      (mentorProfile.mentor.picture_url &&
                        mentorProfile.mentor.picture_url) ||
                      noavatar
                    }
                    alt="Real avatar"
                    className="user-avatar-navbar mx-4 w-50"
                    style={{
                      height: "200px",
                      width: "200px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    roundedCircle
                  />
                </Col>
                <Col sm={12} lg={9}>
                  <div className="">
                    <p
                      className="font-weight-bold mb-0 mt-3"
                      style={{ fontSize: "28px", backgroundColor: "red" }}
                    >
                      {mentorProfile.mentor.first_name}{" "}
                      {mentorProfile.mentor.last_name}
                    </p>
                    <p className="" style={{ fontSize: "20px" }}>
                      {mentorProfile.job}
                    </p>
                  </div>
                  <Card style={{ border: "none" }}>
                    <Card.Body>
                      <p
                        className="font-weight-bold mb-1"
                        style={{ fontSize: "24px" }}
                      >
                        ✨ {t("in_a_few_words")}...
                      </p>
                      <p>{mentorProfile.presentation}</p>
                    </Card.Body>
                  </Card>
                  <Card style={{ border: "none" }}>
                    <Card.Body className="pb-0">
                      <p
                        className="font-weight-bold mb-1"
                        style={{ fontSize: "24px" }}
                      >
                        🔥 {t("admin:skills")}...
                      </p>
                      <Row>
                        <Col sm={12} lg={6}>
                          <ul className="p-1">
                            {mentorSkills.slice(0, half).map((skill) => (
                              <li
                                key={skill.id}
                                style={{ listStyleType: "none" }}
                              >
                                {skill.skill}
                                <hr />
                              </li>
                            ))}
                          </ul>
                        </Col>
                        <Col sm={12} lg={6}>
                          <ul className="p-1">
                            {mentorSkills.length % 2 === 0
                              ? mentorSkills.slice(-half).map((skill) => (
                                  <li
                                    key={skill.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    {skill.skill}
                                    <hr />
                                  </li>
                                ))
                              : mentorSkills.slice(-half + 1).map((skill) => (
                                  <li
                                    key={skill.id}
                                    style={{ listStyleType: "none" }}
                                  >
                                    {skill.skill}
                                    <hr />
                                  </li>
                                ))}
                          </ul>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                  <Card style={{ border: "none" }}>
                    <Card.Body>
                      <p
                        className="font-weight-bold mb-2"
                        style={{ fontSize: "24px" }}
                      >
                        ⌛ {t("experiences")}...
                      </p>
                      <div>
                        {mentorExperiences.map((exp) => (
                          <div key={exp.id}>
                            <p
                              className="font-weight-bold"
                              style={{ fontSize: "18px" }}
                            >
                              {exp.society_name}
                            </p>
                            <p className="mb-1">💼 {exp.job}</p>
                            <small>📍 {exp.localisation}</small>
                            <p className="mt-3">{exp.job_description}</p>
                            <hr className="mt-3" />
                          </div>
                        ))}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        )}
      {mentorProfile &&
        Object.values(mentorProfile).length > 0 &&
        !isLoading &&
        route === "displaymentor" && (
          <Container className="mt-3">
            <Row>
              <Col sm={12} lg={3} className="text-center">
                <Image
                  src={
                    (mentorProfile.mentor.picture_url &&
                      mentorProfile.mentor.picture_url) ||
                    noavatar
                  }
                  alt="Real avatar"
                  className="user-avatar-navbar mx-4"
                  style={{
                    height: "200px",
                    width: "200px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  roundedCircle
                />
              </Col>
              <Col sm={12} lg={9}>
                <p
                  className="font-weight-bold mb-0 mt-3 mx-4"
                  style={{ fontSize: "28px" }}
                >
                  {mentorProfile.mentor.first_name}{" "}
                  {mentorProfile.mentor.last_name}
                </p>
                <p className="mx-4" style={{ fontSize: "20px" }}>
                  {mentorProfile.job}
                </p>
                <Card
                  style={{
                    border: "1 px solid black",
                    borderRadius: 10,
                    marginTop: 10,
                  }}
                >
                  <Card.Body>
                    <p
                      className="font-weight-bold mb-1"
                      style={{ fontSize: "24px" }}
                    >
                      ✨ {t("in_a_few_words")}...
                    </p>
                    <p>{mentorProfile.presentation}</p>
                  </Card.Body>
                </Card>
                <Card
                  style={{
                    border: "1 px solid #000",
                    borderRadius: 10,
                    marginTop: 10,
                  }}
                >
                  <Card.Body className="pb-0">
                    <p
                      className="font-weight-bold mb-1"
                      style={{ fontSize: "24px" }}
                    >
                      🔥 {t("admin:skills")}...
                    </p>
                    <Row>
                      <Col sm={12} lg={6}>
                        <ul className="p-1">
                          {mentorSkills.slice(0, half).map((skill) => (
                            <li
                              key={skill.id}
                              style={{ listStyleType: "none" }}
                            >
                              {skill.skill}
                              <hr />
                            </li>
                          ))}
                        </ul>
                      </Col>
                      <Col sm={12} lg={6}>
                        <ul className="p-1">
                          {mentorSkills.length % 2 === 0
                            ? mentorSkills.slice(-half).map((skill) => (
                                <li
                                  key={skill.id}
                                  style={{ listStyleType: "none" }}
                                >
                                  {skill.skill}
                                  <hr />
                                </li>
                              ))
                            : mentorSkills.slice(-half + 1).map((skill) => (
                                <li
                                  key={skill.id}
                                  style={{ listStyleType: "none" }}
                                >
                                  {skill.skill}
                                  <hr />
                                </li>
                              ))}
                        </ul>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Card
                  style={{
                    border: "1 px solid black",
                    borderRadius: 10,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <Card.Body>
                    <p
                      className="font-weight-bold mb-2"
                      style={{ fontSize: "24px" }}
                    >
                      ⌛ {t("experiences")}...
                    </p>
                    <div>
                      {mentorExperiences.map((exp, index) => (
                        <div key={exp.id}>
                          <p
                            className="font-weight-bold"
                            style={{ fontSize: "18px" }}
                          >
                            {exp.society_name}
                          </p>
                          <p className="mb-1">💼 {exp.job}</p>
                          <small>
                            {" "}
                            <span style={{ fontSize: 20 }}>📍</span>{" "}
                            {exp.localisation}
                          </small>
                          <p className="mt-3">{exp.job_description}</p>
                          {index !== mentorExperiences.length - 1 && (
                            <hr className="mt-3" />
                          )}
                        </div>
                      ))}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
    </>
  );
};

export default MentorProfile;
