import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import useFetch from "hooks/useFetch";
import ProfileDashboard from "../components/ProfileDashboard/ProfileDashboard";
import MyCourses from "../components/MyCourses/MyCourses";
import EditProfile from "../components/EditProfile/EditProfile";
import EditAvatar from "../components/EditAvatar/EditAvatar";
import EditRegistration from "../components/EditRegistration";
import MyInvoices from "../components/MyInvoices";
import { useDispatch } from "react-redux";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import { useTranslation } from "react-i18next";
import MentoringSessionShow from "../components/MentoringSessionShow/MentoringSessionShow";
import NewMentoringSession from "../components/NewMentoringSession/NewMentoringSession";
import Glossary from "../components/Glossary/Glossary";
import ArticleSubscriptions from "../components/ArticleSubscriptions/ArticleSubscriptions";
import ManageSignature from "../components/ManageSignature/ManageSignature";
import ManageActiveSessionPresence from "../components/ManageActiveSessionPresence/ManageActiveSessionPresence";
import "./Feed.scss";
import UserMentoringSessionsIndex from "../components/UserMentoringSessionsIndex/UserMentoringSessionsIndex";

const Feed = ({ reload, currentLanguageId }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isCompany = useSelector((state) => state.auth.isCompany);
  const { put, postAvatar } = useFetch();
  const dispatch = useDispatch();
  const { t } = useTranslation("profile");

  const updateProfile = async (newDetails) => {
    await put(
      "/profile",
      newDetails,
      dispatch(displaySuccess(t("profile:profile_updated")))
    );
    reload(true);
  };

  const updateAvatar = async (newAvatar) => {
    await postAvatar(
      `/${isCompany ? "company_profile" : "users"}/${currentUser.id}/avatars`,
      newAvatar
    );
    reload(true);
  };

  const updateCompany = async (newDetails) => {
    await put(
      `/company_profile/${currentUser.id}`,
      newDetails,
      editCompanySucess
    );
    reload(true);
  };

  const editCompanySucess = () => {
    dispatch(displaySuccess(t("profile:infos_edit_done")));
  };

  const updateRegistration = (newCredentials) => {
    console.log("TODO --> Modifier Devise Registration", newCredentials);
  };

  return (
    <div className="Feed pt-2">
      <Switch>
        <Route path="/profile/dashboard">
          <ProfileDashboard />
        </Route>
        <Route path="/profile/mycourses">
          <MyCourses />
        </Route>
        <Route path="/profile/edit">
          <EditProfile
            onSubmit={updateProfile}
            onCompanySubmit={updateCompany}
          />
        </Route>
        <Route path="/profile/avatar">
          <EditAvatar onSubmit={updateAvatar} />
        </Route>
        <Route path="/profile/:userId/mentoring/:sessionId">
          <MentoringSessionShow />
        </Route>
        <Route path="/profile/:userId/mentoringsession/new">
          <NewMentoringSession />
        </Route>
        <Route path="/profile/glossary">
          <Glossary currentLanguageId={currentLanguageId} />
        </Route>
        <Route path="/profile/myarticlesubscriptions">
          <ArticleSubscriptions />
        </Route>
        <Route path="/profile/signature">
          <ManageSignature />
        </Route>
        <Route path="/profile/presences">
          <ManageActiveSessionPresence />
        </Route>
        <Route path="/profile/mymentoringsessions" exact>
          <UserMentoringSessionsIndex />
        </Route>
      </Switch>
    </div>
  );
};

export default Feed;
