import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Form, Button, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import { useHistory } from "react-router";
import useFetch from "hooks/useFetch";
import Loading from "components/Loading";

const AddArticle = () => {
  const { t } = useTranslation("admin");
  const { post, error, isLoading } = useFetch();
  const [url, setUrl] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const creationSuccess = () => {
    dispatch(displaySuccess(t("article_imported")));
    history.push("/admin/articles");
  };

  const handleClick = (event) => {
    event.preventDefault();
    post("/admin/articles", { notion_article_id: url }, creationSuccess);
  };

  return (
    <div>
      <h3 className="text-center">{t("add_new_article")}</h3>
      <div className="d-flex my-4 align-items-center">
        <Form.Control
          className="w-75 mx-auto"
          placeholder={t("notion_page_id")}
          onChange={(e) => setUrl(e.target.value)}
        />
        <Button
          type="button"
          className="mx-auto btn btn-warning text-white m-3 "
          onClick={handleClick}
        >
          {t("validate_creation")}
        </Button>
        {isLoading && <Loading />}
      </div>

      <div className="d-flex align-items-center justify-content-center">
        {error && <p>{error}</p>}
      </div>
    </div>
  );
};

export default AddArticle;
