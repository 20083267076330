import useFetch from "hooks/useFetch";
import { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GlossaryLine from "./components/GlossaryLine";
import { Form, Row, Container, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { InView } from "react-intersection-observer";
import AlphabetNav from "components/AlphabetNav/AlphabetNav.jsx";

import "./Glossary.scss";

const Glossary = ({ currentLanguageId }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [search, setSearch] = useState("");
  const [terms, setTerms] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const { t } = useTranslation("profile");
  const { get } = useFetch();
  let route = useLocation();
  const glossaryType =
    route.pathname.split("/")[route.pathname.split("/").length - 1];
  const ref = useRef();
  const { inView, entry } = useInView({
    threshold: 0.5,
  });

  const getAllTerms = async () => {
    const data = await get(
      `/users/${currentUser?.id}/glossaries?language_id=${currentLanguageId}&definition_type=${glossaryType}`
    );
    setTerms(data);
  };

  const handleLetterClick = (letter) => {
    const term = terms.find((term) => term.title.charAt(2) === letter);
    setSelectedLetter(letter);
    if (term) {
      const termIndex = terms.indexOf(term);
      const termRef = ref.current.children[termIndex];
      if (termRef) {
        termRef.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      getAllTerms();
    }
  }, [currentLanguageId, currentUser]);

  useEffect(() => {
    if (currentUser) {
      getAllTerms();
    }
  }, [glossaryType, currentUser]);

  useEffect(() => {
    const getSpecificTerms = async () => {
      const specificTerms = await get(
        `/users/${currentUser.id}/glossaries?language_id=${currentLanguageId}&search=${search}&definition_type=${glossaryType}`
      );
      setTerms(specificTerms);
    };
    if (search.length > 2) {
      getSpecificTerms();
    } else {
      getAllTerms();
    }
  }, [search]);

  return (
    <Container className="glossary-container">
      <Row className="align-items-center justify-content-center px-3">
        <h4 className="glossary-title mb-3">
          {glossaryType === "btp"
            ? t("construction_glossary")
            : t("bim_glossary")}{" "}
          <span className="icon">{glossaryType === "btp" ? "🏗️" : "💻"}</span>
        </h4>
      </Row>
      <div>
        <Form.Control
          type="text"
          placeholder={t("search")}
          className="search-input mb-4"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <Row>
        <Col sm={0} lg={2} className="alphabetnav">
          <AlphabetNav
            terms={terms}
            onLetterClick={handleLetterClick}
            selectedLetter={selectedLetter}
          />
        </Col>
        <Col sm={12} lg={10} ref={ref}>
          {terms &&
            terms.length > 0 &&
            terms.map((glossary) => (
              <InView
                as="div"
                key={glossary.id}
                onChange={(inView, entry) => {
                  if (inView) {
                    entry.target.classList.add("visible");
                    if (entry.target.classList.contains("not-visible")) {
                      entry.target.classList.remove("not-visible");
                    }
                    const firstLetter = glossary.title.charAt(2);
                    setSelectedLetter(firstLetter);
                  } else {
                    if (entry.target.classList.contains("visible")) {
                      entry.target.classList.remove("visible");
                    }
                    entry.target.classList.add("not-visible");
                  }
                }}
              >
                <GlossaryLine glossary={glossary} />
              </InView>
            ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Glossary;
