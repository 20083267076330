import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import RequestForm from "./RequestForm";
import useFetch from "hooks/useFetch";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
import { useSelector } from "react-redux";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";

const UserRequestLine = ({ request, reload }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("company");
  const { patch } = useFetch();
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState(request.first_name);
  const [lastName, setLastName] = useState(request.last_name);
  const [email, setEmail] = useState(request.email);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    editUserRequest(firstName, lastName, email);
  };

  const editUserRequest = async (firstName, lastName, email) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      company_id: currentUser.id,
    };
    
    await patch(
      `/company/company_user_requests/${request.id}`,
      data,
      displaySuccess(t("company_user_request_edited")),
      displayError(t("company_user_request_edition_error"))
    );
    handleClose();
    reload();
  };

  return (
    <>
      <tr>
        <td>{request.email} </td>
        <td>{request.first_name} </td>
        <td>{request.last_name} </td>
        <td>
          {request.state === "pending"
            ? t("pending") + " ⏳"
            : t("solved") + " 🔥"}{" "}
        </td>
        <td>
          {request.state === "pending" ? (
            <AiOutlineEdit
              size={30}
              style={{ color: "orange" }}
              onClick={handleShow}
            />
          ) : (
            t("solved")
          )}{" "}
        </td>
        <td className="text-center">
          <Link to={`/company/users/requests/${request.id}`} params={{ id: request.id }}>
            <AiFillEye size={20} style={{ color: "black" }} />
          </Link>
        </td>
      </tr>
      <Modal
        dialogClassName="modal-80w mx-auto"
        aria-labelledby="custom-modal-styling"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("edit_company_user_request")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RequestForm
            setFirstName={setFirstName}
            setLastName={setLastName}
            setEmail={setEmail}
            handleSubmit={handleSubmit}
            request={request}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserRequestLine;
