import {
  Card,
  Image,
  Row,
  Tooltip,
  OverlayTrigger,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import defaultcover from "assets/covers/defaultcover.svg";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./AdvancementCard.scss";

const AdvancementCard = () => {
  const { t } = useTranslation("profile");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userInitiatedLogout = useSelector(
    (state) => state.auth.userInitiatedLogout
  );
  const { get, isLoading, error } = useFetch();
  const [userSubscriptions, setUserSubscriptions] = useState(null);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState(null);
  const history = useHistory();

  const getAllSubscriptions = async () => {
    const userSubscriptionsData = await get(
      `/users/${currentUser.id}/subscriptions`
    );

    setUserSubscriptions(userSubscriptionsData);
  };

  const filteredSubscriptionsForCoursesOnly = (subscriptions, setState) => {
    if (!Array.isArray(subscriptions) || subscriptions.length === 0) {
      setState([]);
      return;
    }

    let courses = [];
    if (subscriptions) {
      subscriptions.map((subscription) => {
        subscription?.learning_path?.courses.map((course) =>
          courses.push(course)
        );
      });

      const filteredCourses = new Set([...courses]);
      setState([...filteredCourses]);
    }
  };

  useEffect(() => {
    if (userInitiatedLogout) {
      return;
    }

    if (
      currentUser &&
      currentUser.subscriptions &&
      currentUser.subscriptions.length > 0
    )
      setUserSubscriptions(currentUser.subscriptions);
    getAllSubscriptions();
  }, [userInitiatedLogout, currentUser]);

  useEffect(() => {
    if (userSubscriptions && userSubscriptions.length > 0)
      filteredSubscriptionsForCoursesOnly(
        userSubscriptions,
        setFilteredSubscriptions
      );
  }, [userSubscriptions]);

  return (
    <Card className="courses-card p-4 mt-3">
      <h4 className="mb-4 text-center courses-card-title">
        {t("menucourses")} 📙
      </h4>
      <Row className="ml-1 mx-auto">
        {error && <h5>{error}</h5>}
        {(!error &&
          isLoading &&
          !filteredSubscriptions &&
          t("admin:loading")) ||
          (filteredSubscriptions &&
            filteredSubscriptions.length > 0 &&
            filteredSubscriptions.map((subscription) => (
              <Link
                key={subscription.id}
                to={`/chapters?courseId=${subscription.id}`}
              >
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 250 }}
                  overlay={<Tooltip id="tooltip">{subscription.title}</Tooltip>}
                >
                  <Image
                    src={
                      subscription.image_url
                        ? subscription.image_url
                        : defaultcover
                    }
                    className="path-avatar m-1"
                  />
                </OverlayTrigger>
              </Link>
            ))) ||
          (!error && <p className="ml-3">{t("common:no_subscriptions")}</p>)}
      </Row>
      <div className="d-flex flex-column align-items-center button-block">
        <Button
          className="btn btn-courses my-4"
          onClick={() => history.push("/learning_paths")}
        >
          {t("home:ctalearningpaths")}
        </Button>
      </div>
    </Card>
  );
};

export default AdvancementCard;
