import useFetch from "hooks/useFetch";
import { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CompanyManagementLine from "./components/CompanyManagementLine";

const ChooseACompanyToManage = () => {
  const { t } = useTranslation("admin");
  const { get, destroy, isLoading } = useFetch();
  const [companies, setCompanies] = useState(null);
  const [toReload, setToReload] = useState(false);

  const getCompanies = async () => {
    const response = await get("/admin/companies");
    setCompanies(response);
  };

  const reload = () => {
    setToReload(!toReload);
  };

  const handleDelete = (companyId) => {
    destroy(`/admin/companies/${companyId}`, reload, true);
  };

  useEffect(() => {
    getCompanies();
  }, []);

  useEffect(() => {
    getCompanies();
  }, [toReload]);

  return (
    <div className="container">
      {companies && !isLoading ? (
        <Table striped bordered hover responsive className="my-4">
          <thead>
            <tr>
              <th>{t("id")}</th>
              <th>{t("company")}</th>
              <th>{t("description")}</th>
              <th>{t("email")}</th>
              <th>{t("is_bootcamp?")}</th>
              <th>{t("update")}</th>
              <th>{t("delete")}</th>
            </tr>
          </thead>
          <tbody>
            {companies &&
              companies.map((company) => (
                <CompanyManagementLine key={company.id} company={company} handleDelete={handleDelete} reload={reload} />
              ))}
          </tbody>
        </Table>
      ) : null}
      {isLoading && <div className="text-center my-5">{t("loading")}</div>}
    </div>
  );
};

export default ChooseACompanyToManage;
