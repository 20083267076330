import { useTranslation } from "react-i18next";
import {
  Row,
  Form,
  Accordion,
  Card,
  Button,
  ListGroup,
  ProgressBar,
  Col,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "helpers/capitalizeFirstLetter";
import noavatar from "assets/noavatar.jpg";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-gb";

import "./UserFeed.scss";

const UserFeed = ({ user }) => {
  const { t } = useTranslation("company");
  const [noDuplicateCourses, setNoDuplicateCourses] = useState([]);
  const [userProgression, setUserProgression] = useState(null);

  const getTotalProgession = (arrayOfProgressStates) => {
    const arrayOfProgressions = arrayOfProgressStates.map(
      (state) => state.progression
    );
    const totalValueOfProgression = arrayOfProgressions.reduce(
      (total, currentValue) => total + currentValue,
      0
    );
    const finalPercetentageOfAdvancement = Math.round(
      totalValueOfProgression / arrayOfProgressions.length
    );

    setUserProgression(finalPercetentageOfAdvancement);
  };

  useEffect(() => {
    let toKeepProgressStates = [];
    if (noDuplicateCourses && noDuplicateCourses?.length > 0) {
      noDuplicateCourses.map((course) => {
        let courseProgressState = user.progress_states.find(
          (state) =>
            Number(state.user_id) === Number(user.id) &&
            Number(state.course_id) === Number(course.id)
        );
        courseProgressState && toKeepProgressStates.push(courseProgressState);
      });
    }

    getTotalProgession(toKeepProgressStates);
  }, [noDuplicateCourses]);

  useEffect(() => {
    let uniqCoursesSubscriptions = [];

    if (user && user.subscriptions) {
      user.subscriptions.map((subscription) => {
        subscription.learning_path.courses &&
          subscription.learning_path.courses.map((course) =>
            uniqCoursesSubscriptions.push({
              ...course,
              chapters: course.chapters.map((chapter) => ({
                ...chapter,
                lessons: chapter.lessons,
              })),
            })
          );
      });
    }

    const getUniqueListBy = (arr, key) => {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    };

    setNoDuplicateCourses(getUniqueListBy(uniqCoursesSubscriptions, "id"));
  }, [user]);

  return (
    <div>
      {user && user.first_name.length > 0 && (
        <>
          <h3 className="text-center mt-3 mb-4">
            {t("company:collaborator_dashboard")}
          </h3>
          <Card className="user-stats-card">
            <Card.Body>
              <Row className="d-flex justify-content-center align-items-center my-3">
                <Col xs={12} md={1}>
                  <img
                    src={user.picture_url ? user.picture_url : noavatar}
                    alt="avatar"
                    className="rounded-circle avatar-user-feed mr-3"
                    width={100}
                    height={100}
                  />
                </Col>
                <Col
                  xs={12}
                  md={10}
                  className="ml-3 h-100 d-flex align-items-center"
                >
                  <div className="d-flex flex-column">
                    <p className="font-weight-bold mb-0">
                      {user.first_name} {user.last_name}
                    </p>
                    {user.last_connexion && (
                      <p className="mb-0">
                        {t("last_connexion_at")} :{" "}
                        {moment(user.last_connexion).format("DD MMMM YYYY")}{" "}
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Row className="my-5">
            <Col sm={12} md={6}>
              <Card className="statistics-cards h-100 p-4">
                <Card.Title className="text-center font-weight-bold">
                  {t("company:user_progression")} 🎯
                </Card.Title>
                <Card.Body className="d-flex flex-column justify-content-center">
                  {userProgression !== 0 && (
                    <ProgressBar
                      now={userProgression}
                      className="company-user-progressbar"
                      variant="ORANGE_BG"
                      label={`${userProgression}%`}
                    />
                  )}

                  {userProgression === 0 && (
                    <p className="text-center">
                      {t("company:no_progression_for_the_moment")}
                    </p>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={6}>
              <Card className="statistics-cards right-stats-card h-100">
                <Card.Body>
                  <Row className="d-flex align-items-center">
                    <Col sm={12} md={4}>
                      {user.mentoring_sessions_count >= 0 && (
                        <p className="taked-sessions-number">
                          {user.mentoring_sessions_count}
                        </p>
                      )}
                    </Col>
                    <Col sm={12} md={8}>
                      <p className="text-left">
                        {t("company:taked_mentoring_sessions")}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Card className="user-stats-card my-5">
            <Card.Body>
              <Card.Title className="text-center font-weight-bold">
                {t("company:course_follow_up")} 🎓
              </Card.Title>

              {noDuplicateCourses &&
                noDuplicateCourses.map((course, index) => {
                  const courseProgressState = user.progress_states.find(
                    (state) =>
                      Number(state.user_id) === Number(user.id) &&
                      Number(state.course_id) === Number(course.id)
                  );
                  return (
                    <Accordion
                      key={course.id}
                      defaultActiveKey={
                        noDuplicateCourses.length === 1 ? "0" : null
                      }
                      className="my-3 company-accordion"
                    >
                      <Card className="right-course-card">
                        <Card.Header className="card-header-company">
                          <Accordion.Toggle
                            as={Button}
                            variant="none"
                            eventKey={index.toString()}
                            className="company-toggler"
                          >
                            <Row className="px-5 py-2 mt-2">
                              <p className="id-number">{index + 1}</p>{" "}
                              <p className="company-title">
                                {capitalizeFirstLetter(course.title)}
                              </p>
                            </Row>
                            <div>
                              <ProgressBar
                                now={
                                  courseProgressState
                                    ? courseProgressState.progression
                                    : 0
                                }
                                className="company-student-progressbar"
                                label={`${
                                  courseProgressState
                                    ? courseProgressState.progression
                                    : 0
                                }%`}
                              />
                            </div>
                          </Accordion.Toggle>
                        </Card.Header>
                        {courseProgressState && (
                          <Accordion.Collapse eventKey={index.toString()}>
                            <Card.Body style={{ padding: 0 }}>
                              {course.chapters &&
                                course.chapters.map((chapter, chapterIndex) => (
                                  <div key={chapter.id}>
                                    <h3 className="chapter-title">
                                      {t("Chapter")} {chapterIndex + 1}:{" "}
                                      {chapter.title}
                                    </h3>
                                    {chapter.lessons &&
                                      chapter.lessons.map(
                                        (lesson, lessonIndex) => {
                                          const progressLesson =
                                            courseProgressState.progress_lessons.find(
                                              (progressLesson) =>
                                                progressLesson.lesson_id ===
                                                lesson.id
                                            );

                                          return (
                                            <ListGroup.Item
                                              key={lesson.id}
                                              className="lesson-list-element"
                                            >
                                              <h4>
                                                Leçon {lessonIndex + 1}:{" "}
                                                {lesson.title}
                                              </h4>
                                              <p>
                                                Résultat du quiz :{" "}
                                                {progressLesson &&
                                                progressLesson.quizz_result ? (
                                                  <span className="bolder-result">
                                                    {
                                                      progressLesson.quizz_result
                                                    }
                                                  </span>
                                                ) : (
                                                  <span className="bolder-result">
                                                    {t("not_started")}
                                                  </span>
                                                )}
                                              </p>
                                            </ListGroup.Item>
                                          );
                                        }
                                      )}
                                  </div>
                                ))}
                            </Card.Body>
                          </Accordion.Collapse>
                        )}
                      </Card>
                    </Accordion>
                  );
                })}
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  );
};

export default UserFeed;
