import MDEditor from "@uiw/react-md-editor";
import { useState } from "react";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const UpdateSessionReport = ({ session, reload, handleClose }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { patch } = useFetch();
  const { t } = useTranslation("profile");
  const [report, setReport] = useState(t("report_placeholder"));

  const submitReport = (event) => {
    event.preventDefault();
    patch(
      `/users/${currentUser.id}/mentoring_sessions/${session.id}`,
      { report: report },
      displaySuccess(t("report_updated")),
      displayError(t("report_not_updated"))
    );
    handleClose();
    reload();
  };
  return (
    <div>
      <MDEditor
        value={report}
        onChange={setReport}
        className="mb-3"
        height={400}
      />
      <div className="d-flex flex-column align-items-center">
        <button className="btn btn-warning text-white" onClick={submitReport}>
          {t("submit_report")}
        </button>
      </div>
    </div>
  );
};

export default UpdateSessionReport;
