import { Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import NotPermittedPicture from "assets/homme_perdu.png";
import "./NotPermitted.scss";

const NotPermitted = ({location, redirection}) => {
  const { t } = useTranslation(location);
  const history = useHistory();
  return (
    <Row className="not-permitted-section">
      <Col sm={12} lg={6} className="left-section">
        <p className="oups">Oups !</p>
        <p className="not-accessible">{t("not_accessible")}</p>
        <button className="safe-area-btn" onClick={() => history.push(redirection)}>{t("go_to_safe_area")} </button>
      </Col>
      <Col sm={12} lg={6} className="right-section">
        <Image src={NotPermittedPicture} alt="Access not permitted" className="not-permitted-image" />
      </Col>
    </Row>
  );
};

export default NotPermitted;
