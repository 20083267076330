import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { useLocation } from "react-router";
import { Table, Modal, Form, Button } from "react-bootstrap";
import MessageCard from "components/MessageCard";
import { AiOutlineEdit } from "react-icons/ai";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
import { useSelector } from "react-redux";

const DisplayFullCompanyUserRequestToAdmin = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("admin");
  const { get, post } = useFetch();
  const location = useLocation();
  const [requestId, setRequestId] = useState(
    location.pathname.split("/")[location.pathname.split("/").length - 1]
  );
  const [request, setRequest] = useState(null);
  const [show, setShow] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [toReload, setToReload] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getRequest = async () => {
    const response = await get(`/admin/company_user_requests/${requestId}`);
    setRequest(response);
  };

  const reload = () => {
    setToReload(!toReload);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      user_id: currentUser.id,
      message: newMessage,
      company_user_request_id: requestId,
    };
    post(
      "/admin/company_user_request_messages",
      data,
      handleClose(),
      displaySuccess(t("admin:company_user_request_message_sent")),
      displayError(t("admin:company_user_request_message_not_sent"))
    );
    reload();
  };

  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    getRequest();
  }, [toReload]);

  return (
    <div>
      {request && Object.values(request).length > 0 && (
        <div>
          <h3 className="text-center">
            {t("manage_company_user_request_for")} {request.first_name}{" "}
            {request.last_name}
          </h3>

          <Table striped bordered hover className="my-5">
            <thead>
              <tr>
                <th>{t("company")}</th>
                <th>{t("email")}</th>
                <th>{t("first_name")}</th>
                <th>{t("last_name")}</th>
                <th>{t("state")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{request.company.name} </td>
                <td>{request.email} </td>
                <td>{request.first_name} </td>
                <td>{request.last_name} </td>
                <td>
                  {request.state === "pending"
                    ? t("company:pending") + " ⏳"
                    : t("company:solved") + " 🔥"}{" "}
                </td>
              </tr>
            </tbody>
          </Table>
          {request.company_user_request_messages.length > 0 && (
            <>
              <h5 className="text-center">{t("discover_discussion")} </h5>
              {request.company_user_request_messages.map((message) => (
                <MessageCard
                  key={message.id}
                  message={message}
                  company={request.company}
                  reload={reload}
                />
              ))}
            </>
          )}
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: "orange",
              borderRadius: "50%",
              height: "55px",
              width: "55px",
              position: "fixed",
              bottom: "15px",
              right: "15px",
              cursor: "pointer",
              boxShadow: "0 0 10px  rgba(0,0,0,0.6)",
            }}
            onClick={handleShow}
          >
            <AiOutlineEdit size={30} style={{ color: "white" }} />
          </div>
          <Modal
            dialogClassName="modal-80w mx-auto"
            aria-labelledby="custom-modal-styling"
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("add_new_message")}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ width: "85%" }} className="mx-auto">
              <Form onSubmit={handleSubmit}>
                <div className="d-flex row justify-content-center">
                  <Form.Control
                    as="textarea"
                    type="text"
                    className="mb-3"
                    style={{ height: 200 }}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                  <Button
                    type="submit"
                    className="ButtonPrimary w-50"
                    onClick={handleSubmit}
                  >
                    {t("send")}
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default DisplayFullCompanyUserRequestToAdmin;
