import { useSelector } from "react-redux";
import { FaLinkedin } from "react-icons/fa";
import "./ProfileDisplay.scss";

const ProfileDisplay = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isCompany = useSelector((state) => state.auth.isCompany);

  return (
    <div className="ProfileDisplay">
      {currentUser && (
        <>
          {isCompany && (
            <p className="mt-2 mb-2">
              {currentUser.name}
          </p>
          )}

          {!isCompany && (
            <p className="user-identity mt-2 mb-2" style={{fontWeight: "bold"}}>
              {currentUser.first_name} {currentUser.last_name}
              {currentUser.linkedin_address && (
              <a href={currentUser.linkedin_address}>
                <FaLinkedin />
              </a>
          )}
            </p>
          )}

          {currentUser.description && (
            <p className="text-muted font-italic">{currentUser.description}</p>
          )}

          {currentUser.role === "teacher" && currentUser.categories && currentUser.categories.map(category => (
            <p key={category.id} className="text-muted font-italic">{category.title}</p>
          ))}

          {currentUser.job && <p>{currentUser.job}</p>}
        </>
      )}
    </div>
  );};

export default ProfileDisplay;
