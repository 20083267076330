import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./BookmarkBanner.scss";
const BookmarkBanner = ({ onClose, onRedirect }) => {
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation("common");

  useEffect(() => {
    const increment = 0.5; 
    const intervalTime = 100; 

    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          clearInterval(interval);
          onClose();
          return 100;
        }
        return Math.min(oldProgress + increment, 100);
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [onClose]);

  return (
    <div
      className="bookmark-banner alert"
      role="alert"
      onClick={onRedirect}
    >
      <p className="bookmark-banner-text">{t("return_to_the_last_lesson")} </p>
      <ProgressBar className="progress-bookmark" now={progress} />
    </div>
  );
};

export default BookmarkBanner;
