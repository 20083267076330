import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import SkillsForm from "./components/SkillsForm";
import ChooseMentorForm from "./components/ChooseMentorForm";
import { useEffect, useState } from "react";
import AddExperienceForm from "./components/AddExperienceForm/AddExperienceForm";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

const UpdateMentorProfile = () => {
  const { t } = useTranslation("admin");
  const [selectedMentor, setSelectedMentor] = useState("");
  const [existingsMentors, setExistingsMentors] = useState([]);
  const [notValidMentor, setNotValidMentor] = useState(false);
  const [toReload, setToReload] = useState(false);
  const [updateExp, setUpdateExp] = useState(false);

  const reload = () => {
    setToReload(!toReload);
  };

  useEffect(() => {
    checkIfSelectedMentorExist();
  }, [selectedMentor]);

  const checkIfSelectedMentorExist = () => {
    let existingMentorProfileIdArray = [];

    existingsMentors.map((existingProfil) =>
      existingMentorProfileIdArray.push(existingProfil.id)
    );

    if (existingMentorProfileIdArray.includes(Number(selectedMentor))) {
      setNotValidMentor(false);
    } else {
      setNotValidMentor(true);
    }
  };

  return (
    <div>
      <h4 className="text-center">{t("update_mentor_profile")}</h4>
      <div className="mt-4">
        <Card>
          <Card.Header className="LearningPathCard__title">
            {t("update_mentor_profile")}
          </Card.Header>
          <Card.Body className="py-0">
            <div className="mt-4">
              <BootstrapSwitchButton
                checked={false}
                onlabel="Exp"
                offlabel="Skill"
                size={"sm"}
                onstyle="dark"
                offstyle="secondary"
                onChange={() => setUpdateExp(!updateExp)}
              />
            </div>
            <ChooseMentorForm
              setSelectedMentor={setSelectedMentor}
              existingsMentors={existingsMentors}
              setExistingsMentors={setExistingsMentors}
            />
            {updateExp === false && (
              <SkillsForm
                selectedMentor={selectedMentor}
                notValidMentor={notValidMentor}
                reload={reload}
              />
            )}
            {updateExp === true && (
              <AddExperienceForm
                selectedMentor={selectedMentor}
                notValidMentor={notValidMentor}
                reload={reload}
              />
            )}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default UpdateMentorProfile;
