
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
import { useHistory } from "react-router-dom";
import useFetch from "hooks/useFetch";
import RequestForm from "./RequestForm";

const AddEmployees = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("company");
  const { post } = useFetch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    createUserRequest(firstName, lastName, email);
  };

  const createUserRequest = async (firstName, lastName, email) => {
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      company_id: currentUser.id,
    };
    
    await post(
      "/company/company_user_requests",
      data,
      history.push("/company/users"),
      displaySuccess(t("company_user_request_created")),
      displayError(t("company_user_request_creation_error"))
    );
  };

  return (
    <div>
      <div className="text-center my-3">
        <h3>{t("add_users_to_company")}</h3>
      </div>
      <RequestForm setFirstName={setFirstName} setLastName={setLastName} setEmail={setEmail} handleSubmit={handleSubmit} />
    </div>
  );
};

export default AddEmployees;
