import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const UpdateSessionLink = ({ session, reload, handleClose }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("profile");
  const { patch } = useFetch();
  const [newLink, setNewLink] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    await patch(
      `/users/${currentUser.id}/mentoring_sessions/${session.id}`,
      { session_url: newLink },
      displaySuccess(t("link_updated")),
      displayError(t("link_not_updated"))
    );
    handleClose();
    reload();
  };

  return (
    session &&
    currentUser && (
      <Form onSubmit={handleSubmit}>
        <Form.Control
          type="text"
          placeholder="https://..."
          onChange={(e) => setNewLink(e.target.value)}
        />
        <div className="d-flex flex-column align-items-center">
          <Button variant="warning" className="text-white mt-3" type="submit">
            {t("edit_link")}
          </Button>
        </div>
      </Form>
    )
  );
};

export default UpdateSessionLink;
