import { Card, Col, Image, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import noavatar from "assets/noavatar.jpg";
import moment from "moment";
import "moment/locale/fr";

import "./IndexMentoringCard.scss";

const IndexMentoringCard = ({ session }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("profile");

  const sessionDate = (
    <p>
      {moment(session.session_date).locale("fr").format("DD MMMM YYYY")} à{" "}
      {moment(session.session_date).locale("fr").format("HH") +
        " h " +
        moment(session.session_date).locale("fr").format("mm")}
    </p>
  );

  const DisplayInformationsForNotMentorUsers = () => (
    <Row className="d-flex align-items-center">
      <Col sm={12} md={6} lg={3} className="text-center">
        {session.mentor.avatar ? (
          <Image
            className="avatar-session"
            src={session.mentor.avatar}
            alt="mentor avatar"
            roundedCircle
          />
        ) : (
          <Image
            className="avatar-session"
            src={
              session.mentor.picture_url ? session.mentor.picture_url : noavatar
            }
            alt="Mentor picture"
            roundedCircle
          />
        )}
      </Col>
      <Col sm={12} md={6} lg={3}>
        <p className="session-card-subtitle">
          {session.mentor.first_name} {session.mentor.last_name}
        </p>
        <p>{session.mentor?.job}</p>
      </Col>
      <Col sm={12} md={6} lg={3}>
        <p className="session-card-subtitle">Thème abordé</p>
        <p>{session.category?.title}</p>
      </Col>
      <Col sm={12} md={6} lg={3}>
        <p className="session-card-subtitle">Date:</p>
        <p>
          {session.session_date
            ? sessionDate
            : t("pending confirmation") + " ⏳"}
        </p>
      </Col>
    </Row>
  );

  const DisplayInformationsForMentors = () => (
    <Row className="d-flex align-items-center">
      <Col sm={12} md={6} lg={3} className="text-center">
        {session.student.avatar ? (
          <Image
            className="avatar-session"
            src={session.student.avatar}
            alt="Student avatar"
            roundedCircle
          />
        ) : (
          <Image
            className="avatar-session"
            src={
              session.student.picture_url
                ? session.student.picture_url
                : noavatar
            }
            alt="Student picture"
            roundedCircle
          />
        )}
      </Col>
      <Col sm={12} md={6} lg={3}>
        <p className="session-card-subtitle">
          {session.student.first_name} {session.student.last_name}
        </p>
        <p>{session.student?.job}</p>
      </Col>
      <Col sm={12} md={6} lg={3}>
        <p className="session-card-subtitle">Thème abordé</p>
        <p>{session.category?.title}</p>
      </Col>
      <Col sm={12} md={6} lg={3}>
        <p className="session-card-subtitle">Date:</p>
        <p>
          {session.session_date
            ? sessionDate
            : t("pending confirmation") + " ⏳"}
        </p>
      </Col>
    </Row>
  );

  return (
    <Link to={`/profile/${currentUser.id}/mentoring/${session.id}`} className="not-default-link">
      <Card className="mentoring-index-card">
        <Card.Body>
          {currentUser?.role === "mentor" ? (
            <DisplayInformationsForMentors />
          ) : (
            <DisplayInformationsForNotMentorUsers />
          )}
        </Card.Body>
      </Card>
    </Link>
  );
};

export default IndexMentoringCard;
