import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFetch from "hooks/useFetch";
import IndexMentoringCard from "./components/IndexMentoringCard/IndexMentoringCard";
import NoSessionPicture from "assets/cactus.png";

import "./UserMentoringSessionsIndex.scss";

const UserMentoringSessionsIndex = () => {
  const { t } = useTranslation("profile");
  const { get, isLoading } = useFetch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [sessions, setSessions] = useState(null);

  const getMentoringSessions = async () => {
    const sessions = await get(
      `/users/${currentUser.id}/mentoring_sessions?role=${
        currentUser && currentUser.role === "mentor" ? "mentor" : "student"
      }`
    );
    setSessions(sessions);
  };

  useEffect(() => {
    if (currentUser)  {
      getMentoringSessions();
    }
  }, [currentUser]);

  return (
    <div className="mentoring-sessions">
      <h4 className="title">{t("my_mentoring_sessions")}</h4>
      {sessions &&
        sessions.length > 0 &&
        sessions.map((session) => (
          <IndexMentoringCard key={session.id} session={session} />
        ))}
      {!sessions && !isLoading && (
        <div>
          <p className="no-sessions">{t("no_sessions_to_diplay")}...</p>
          <img className="no-sessions-image" src={NoSessionPicture} alt="Aucune session à afficher" />
        </div>
      )}
    </div>
  );
};

export default UserMentoringSessionsIndex;
