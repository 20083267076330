import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import { Container } from "react-bootstrap";
import useFetch from "hooks/useFetch";

import "./GeneralTermsOfUse.scss";
import MarkdownConverter from "components/MarkdownConverter/MarkdownConverter";

const GeneralTermsOfUse = ({ setUserUpdated, isCompany}) => {
  const { t } = useTranslation("common");
  const { put } = useFetch();
  const dispatch = useDispatch();

  const updateProfileWithTermsAccepted = async () => {
    if (isCompany) {
      await put (
        "/company/update",
        {company: { has_agreed_terms: true }},
        dispatch(displaySuccess(t("terms_accepted")))
      );
    } else {
      await put(
        "/profile",
        { has_agreed_terms: true },
        dispatch(displaySuccess(t("terms_accepted")))
      );
    }
    setUserUpdated(true);
  };

  const frenchTraductionOfGeneralTermsOfUse = `
  # 1- PROPRIETE INTELLECTUELLE

  L’ensemble du contenu du site www.arsenio.co (illustrations, textes, libellés, marques, images, vidéos) est la propriété de Arsenio, de ses cocontractants ou partenaires. Toute reproduction partielle ou totale du contenu du site Arsenio par quelque procédé que ce soit et sur n’importe quel support est soumise à une autorisation préalable et expresse de Arsenio.
  
  Toutes les informations, les contenus, les dossiers et les logiciels fournis sur le Site Arsenio sont protégés par les lois françaises et internationales concernant la propriété intellectuelle et le copyright.
  
  Arsenio n’autorise pas la reproduction, la représentation, la copie, l’affichage ou la distribution de tout contenu pour lequel l’utilisateur du site Arsenio ne possède pas de droits de propriété intellectuelle. Toute utilisation sans autorisation de ces contenus en fraude aux droits détenus par Arsenio et/ou par des tiers sur ceux-ci est susceptible de constituer le délit de contrefaçon, sévèrement sanctionné par le Code de la Propriété Intellectuelle.
  
  Arsenio ne saurait en aucun cas être tenue pour responsable de la violation par un utilisateur du site Arsenio de droits détenus par des tiers et perpétrée à raison des activités de l’utilisateur sur le Site Arsenio.
  
  # 2- RESPECTER LA VIE PRIVEE ET LE DROIT A L’IMAGE
  
  Afin de respecter le droit à l’image de chacun, Arsenio n’autorise pas l’enregistrement vidéo des échanges entre l’apprenant et le professeur pendant le cours en visioconférence.
  
  # 3- COOKIES
  
  Lors de vos visites sur le Site Arsenio, des cookies enregistrent certaines informations qui sont stockées dans la mémoire de votre disque dur. Ces informations nous servent à générer des statistiques d’audience du site Arsenio, et à vous proposer des produits selon les articles que vous avez déjà sélectionnés lors de vos visites précédentes. Ces cookies ne contiennent pas d’information confidentielle vous concernant.
  
  Vous pouvez désactiver les cookies dans les paramètres de votre navigateur. Nous vous conseillons cependant de les autoriser, afin de profiter de l’intégralité des fonctionnalités du site Arsenio.
  
  # 4- DONNEES A CARACTERE PERSONNEL
  
  En s’inscrivant sur le Site Arsenio (notamment pour l’ouverture d’un compte client ou en s’abonnant à des services fournis sur le Site Arsenio), l’utilisateur du Site s’engage à nous fournir des informations sincères et véritables le concernant. La communication de fausses informations est contraire aux présentes conditions d’utilisation.Arsenio est seul détenteur des informations vous concernant.
  
  Vous pouvez à n’importe quel moment faire une demande auprès de Arsenio afin : de savoir quelles informations vous concernant sont détenues par Arsenio, de vous opposer à leur traitement, de les faire modifier ou supprimer et ce, en contactant le service client par email à l’adresse [customer@arsenio.co](mailto:customer@arsenio.co).
  
  Si vous l’avez accepté lors de votre identification sur le Site, Arsenio et ses partenaires commerciaux peuvent vous envoyer par courrier électronique des informations dans le cadre d’opérations promotionnelles précises et ponctuelles. Ces partenaires sont spécialement choisis par Arsenio en fonction de la qualité de leurs produits et services.Vous pouvez demander à ne plus recevoir ces courriers électroniques à tout moment sur le lien prévu à cet effet et inséré en pied de page de chacun des courriers électroniques qui vous seront adressés.
  
  # **Politique de confidentialité (RGPD)**
  
  **Sécurité et protection des données personnelles**
  
  ### Avertissements
  
  Le présent outil est mis à votre disposition gratuitement. L’outil se base sur des informations fondées sur l’analyse professionnelle du cabinet en matière de conformité RGPD. Toutefois, la conformité étant un processus dynamique et toute situation étant particulière, les informations transmises doivent être adaptées et ne peuvent en aucun cas être considérées comme exhaustives ou exactes.
  
  Sauf à ce que vous demandiez une revue et validation par le Cabinet, le document généré est considéré comme une simple information. En conséquence, vous êtes seul responsable des interprétations faites des informations fournies, des conseils que vous en déduisez et des adaptations réalisées pour votre activité commerciale propre. L’utilisation et l’exploitation de l’outil se fait donc sous votre seule responsabilité et à vos risques et périls.
  
  ### Définitions :
  
  **L’Éditeur**: La personne, physique ou morale, qui édite les services de communication au public en ligne.**Le Site : **L’ensemble des sites, pages Internet et services en ligne proposés par l’Éditeur.**L’Utilisateur** : La personne utilisant le Site et les services.
  
  ### Nature des données collectées
  
  Dans le cadre de l’utilisation des Sites, l’Éditeur est susceptible de collecter les catégories de données suivantes concernant ses Utilisateurs :
  
  - Données d’état-civil, d’identité, d’identification…
  - Données relatives à la vie professionnelle (CV, scolarité, formation professionnelle, distinctions…)
  - Données de connexion (adresses IP, journaux d’événements…)
  - Données de localisation (déplacements, données GPS, GSM…)
  
  ### Communication des données personnelles à des tiers
  
  Pas de communication à des tiers
  
  Vos données ne font l’objet d’aucune communication à des tiers. Vous êtes toutefois informés qu’elles pourront être divulguées en application d’une loi, d’un règlement ou en vertu d’une décision d’une autorité réglementaire ou judiciaire compétente.
  
  Information préalable pour la communication des données personnelles à des tiers en cas de fusion / absorption
  
  Collecte de l’opt-in (consentement) préalable à la transmission des données suite à une fusion / acquisition
  
  Dans le cas où nous prendrions part à une opération de fusion, d’acquisition ou à toute autre forme de cession d’actifs, nous nous engageons à obtenir votre consentement préalable à la transmission de vos données personnelles et à maintenir le niveau de confidentialité de vos données personnelles auquel vous avez consenti.
  
  ### Finalité de la réutilisation des données personnelles collectées
  
  Effectuer des opérations relatives à la prospection
  
  - la gestion d’opérations techniques de prospection (ce qui inclut notamment les opérations techniques comme la normalisation, l’enrichissement et la déduplication)
  - la sélection de personnes pour réaliser des actions de fidélisation, de prospection, de sondage, de test produit et de promotion. Sauf consentement des personnes concernées recueilli dans les conditions prévues à l’article 6, ces opérations ne doivent pas conduire à l’établissement de profils susceptibles de faire apparaître des données sensibles (origines raciales ou ethniques, opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle ou santé des personnes)
  - la réalisation d’opérations de sollicitations
  
  **L’élaboration de statistiques commerciales**
  
  La cession, la location ou l’échange de ses fichiers de clients et de ses fichiers de prospects
  
  L’actualisation de ses fichiers de prospection par l’organisme en charge de la gestion de la liste d’opposition au démarchage téléphonique, en application des dispositions du code de la consommation
  
  La gestion des demandes de droit d’accès, de rectification et d’opposition
  
  La gestion des avis des personnes sur des produits, services ou contenus
  
  ### Agrégation des données
  
  **Agrégation avec des données non personnelles**
  
  Nous pouvons publier, divulguer et utiliser les informations agrégées (informations relatives à tous nos Utilisateurs ou à des groupes ou catégories spécifiques d’Utilisateurs que nous combinons de manière à ce qu’un Utilisateur individuel ne puisse plus être identifié ou mentionné) et les informations non personnelles à des fins d’analyse du secteur et du marché, de profilage démographique, à des fins promotionnelles et publicitaires et à d’autres fins commerciales.
  
  **Agrégation avec des données personnelles disponibles sur les comptes sociaux de l’Utilisateur**
  
  Si vous connectez votre compte à un compte d’un autre service afin de faire des envois croisés, ledit service pourra nous communiquer vos informations de profil, de connexion, ainsi que toute autre information dont vous avez autorisé la divulgation. Nous pouvons agréger les informations relatives à tous nos autres Utilisateurs, groupes, comptes, aux données personnelles disponibles sur l’Utilisateur.
  
  ### Collecte des données d’identité
  
  **Inscription et identification préalable pour la fourniture du service**
  
  L’utilisation du Site nécessite une inscription et une identification préalable. Vos données nominatives (nom, prénom, adresse postale, e-mail, numéro de téléphone,…) sont utilisées pour exécuter nos obligations légales résultant de la livraison des produits et / ou des services, en vertu du Contrat de licence utilisateur final, de la limite de garantie, le cas échéant, ou de toute autre condition applicable. Vous ne fournirez pas de fausses informations nominatives et ne créerez pas de compte pour une autre personne sans son autorisation. Vos coordonnées devront toujours être exactes et à jour.
  
  ### Collecte des données d’identification
  
  **Utilisation de l’identifiant de l’utilisateur pour proposition de mise en relation et offres commerciales**
  
  Nous utilisons vos identifiants électroniques pour rechercher des relations présentes par connexion, par adresse mail ou par services. Nous pouvons utiliser vos informations de contact pour permettre à d’autres personnes de trouver votre compte, notamment via des services tiers et des applications clientes. Vous pouvez télécharger votre carnet d’adresses afin que nous soyons en mesure de vous aider à trouver des connaissances sur notre réseau ou pour permettre à d’autres Utilisateurs de notre réseau de vous trouver. Nous pouvons vous proposer des suggestions, à vous et à d’autres Utilisateurs du réseau, à partir des contacts importés de votre carnet d’adresses. Nous sommes susceptibles de travailler en partenariat avec des sociétés qui proposent des offres incitatives. Pour prendre en charge ce type de promotion et d’offre incitative, nous sommes susceptibles de partager votre identifiant électronique.
  
  ### Collecte des données du terminal
  
  **Collecte des données de profilage et des données techniques à des fins de fourniture du service**
  
  Certaines des données techniques de votre appareil sont collectées automatiquement par le Site. Ces informations incluent notamment votre adresse IP, fournisseur d’accès à Internet, configuration matérielle, configuration logicielle, type et langue du navigateur… La collecte de ces données est nécessaire à la fourniture des services.
  
  **Collecte des données techniques à des fins publicitaires, commerciales et statistiques**
  
  Les données techniques de votre appareil sont automatiquement collectées et enregistrées par le Site, à des fins publicitaires, commerciales et statistiques. Ces informations nous aident à personnaliser et à améliorer continuellement votre expérience sur notre Site. Nous ne collectons ni ne conservons aucune donnée nominative (nom, prénom, adresse…) éventuellement attachée à une donnée technique. Les données collectées sont susceptibles d’être revendues à des tiers.
  
  ### Cookies
  
  **Durée de conservation des cookies**
  
  Conformément aux recommandations de la CNIL, la durée maximale de conservation des cookies est de 13 mois au maximum après leur premier dépôt dans le terminal de l’Utilisateur, tout comme la durée de la validité du consentement de l’Utilisateur à l’utilisation de ces cookies. La durée de vie des cookies n’est pas prolongée à chaque visite. Le consentement de l’Utilisateur devra donc être renouvelé à l’issue de ce délai.
  
  **Finalité cookies**
  
  Les cookies peuvent être utilisés pour des fins statistiques notamment pour optimiser les services rendus à l’Utilisateur, à partir du traitement des informations concernant la fréquence d’accès, la personnalisation des pages ainsi que les opérations réalisées et les informations consultées.
  
  Vous êtes informé que l’Éditeur est susceptible de déposer des cookies sur votre terminal. Le cookie enregistre des informations relatives à la navigation sur le service (les pages que vous avez consultées, la date et l’heure de la consultation…) que nous pourrons lire lors de vos visites ultérieures.
  
  **Droit de l’Utilisateur de refuser les cookies**
  
  Vous reconnaissez avoir été informé que l’Éditeur peut avoir recours à des cookies. Si vous ne souhaitez pas que des cookies soient utilisés sur votre terminal, la plupart des navigateurs vous permettent de désactiver les cookies en passant par les options de réglage.
  
  ### Conservation des données techniques
  
  Durée de conservation des données techniques
  
  Les données techniques sont conservées pour la durée strictement nécessaire à la réalisation des finalités visées ci-avant.
  
  ### Délai de conservation des données personnelles et d’anonymisation
  
  **Conservation des données pendant la durée de la relation contractuelle**
  
  Conformément à l’article 6-5° de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, les données à caractère personnel faisant l’objet d’un traitement ne sont pas conservées au-delà du temps nécessaire à l’exécution des obligations définies lors de la conclusion du contrat ou de la durée prédéfinie de la relation contractuelle.
  
  **Conservation des données anonymisées au delà de la relation contractuelle / après la suppression du compte**
  
  Nous conservons les données personnelles pour la durée strictement nécessaire à la réalisation des finalités décrites dans les présentes CGU. Au-delà de cette durée, elles seront anonymisées et conservées à des fins exclusivement statistiques et ne donneront lieu à aucune exploitation, de quelque nature que ce soit.
  
  **Suppression des données après suppression du compte**
  
  Des moyens de purge de données sont mis en place afin d’en prévoir la suppression effective dès lors que la durée de conservation ou d’archivage nécessaire à l’accomplissement des finalités déterminées ou imposées est atteinte. Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, vous disposez par ailleurs d’un droit de suppression sur vos données que vous pouvez exercer à tout moment en prenant contact avec l’Éditeur.
  
  **Suppression des données après 3 ans d’inactivité**
  
  Pour des raisons de sécurité, si vous ne vous êtes pas authentifié sur le Site pendant une période de trois ans, vous recevrez un e-mail vous invitant à vous connecter dans les plus brefs délais, sans quoi vos données seront supprimées de nos bases de données.
  
  ### Suppression du compte
  
  **Suppression du compte à la demande**
  
  L’Utilisateur a la possibilité de supprimer son Compte à tout moment, par simple demande à l’Éditeur OU par le menu de suppression de Compte présent dans les paramètres du Compte le cas échéant.
  
  **Suppression du compte en cas de violation des CGU**
  
  En cas de violation d’une ou de plusieurs dispositions des CGU ou de tout autre document incorporé aux présentes par référence, l’Éditeur se réserve le droit de mettre fin ou restreindre sans aucun avertissement préalable et à sa seule discrétion, votre usage et accès aux services, à votre compte et à tous les Sites.
  
  ### Indications en cas de faille de sécurité décelée par l’Éditeur
  
  **Information de l’Utilisateur en cas de faille de sécurité**
  
  Nous nous engageons à mettre en oeuvre toutes les mesures techniques et organisationnelles appropriées afin de garantir un niveau de sécurité adapté au regard des risques d’accès accidentels, non autorisés ou illégaux, de divulgation, d’altération, de perte ou encore de destruction des données personnelles vous concernant.
  
  Dans l’éventualité où nous prendrions connaissance d’un accès illégal aux données personnelles vous concernant stockées sur nos serveurs ou ceux de nos prestataires, ou d’un accès non autorisé ayant pour conséquence la réalisation des risques identifiés ci-dessus, nous nous engageons à:
  
  - Vous notifier l’incident dans les plus brefs délais ;
  - Examiner les causes de l’incident et vous en informer ;
  - Prendre les mesures nécessaires dans la limite du raisonnable afin d’amoindrir les effets négatifs et préjudices pouvant résulter dudit incident
  
  ### Limitation de la responsabilité
  
  En aucun cas les engagements définis au point ci-dessus relatifs à la notification en cas de faille de sécurité ne peuvent être assimilés à une quelconque reconnaissance de faute ou de responsabilité quant à la survenance de l’incident en question.
  
  ### Transfert des données personnelles à l’étranger
  
  **Pas de transfert en dehors de l’Union européenne**
  
  L’Éditeur s’engage à ne pas transférer les données personnelles de ses Utilisateurs en dehors de l’Union européenne.
  
  ### Modification des CGU et de la politique de confidentialité
  
  **En cas de modification des présentes CGU, engagement de ne pas baisser le niveau de confidentialité de manière substantielle sans l’information préalable des personnes concernées**
  
  Nous nous engageons à vous informer en cas de modification substantielle des présentes CGU, et à ne pas baisser le niveau de confidentialité de vos données de manière substantielle sans vous en informer et obtenir votre consentement.
  
  ### Droit applicable et modalités de recours
  
  **Clause d’arbitrage**
  
  Vous acceptez expressément que tout litige susceptible de naître du fait des présentes CGU, notamment de son interprétation ou de son exécution, relèvera d’une procédure d’arbitrage soumise au règlement de la plateforme d’arbitrage choisie d’un commun accord, auquel vous adhérerez sans réserve.
  
  ### Portabilité des données
  
  L’Éditeur s’engage à vous offrir la possibilité de vous faire restituer l’ensemble des données vous concernant sur simple demande. L’Utilisateur se voit ainsi garantir une meilleure maîtrise de ses données, et garde la possibilité de les réutiliser. Ces données devront être fournies dans un format ouvert et aisément réutilisable.
  `;

  return (
    <div>
      <h1 className="text-center mt-3 mb-5">{t("general_terms_of_use")}</h1>
      <Container>
        <MarkdownConverter text={frenchTraductionOfGeneralTermsOfUse} />
        <div className=" text-center my-3">
          <button
            className="accept-terms-btn my-3"
            onClick={updateProfileWithTermsAccepted}
          >
            {t("accept_terms")}
          </button>
        </div>
      </Container>
    </div>
  );
};

export default GeneralTermsOfUse;
