import useDeviceResize from "hooks/useDeviceResize";
import { useEffect } from "react";
import { Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

const MentorForm = ({
  categories,
  choosedCategoryIndex,
  setSelectedMentor,
  selectedMentor,
  handleShow
}) => {
  const { t } = useTranslation("profile");
  const {
    isMobile,
    checkOnDeviceResize,
    removeResizeListener,
  } = useDeviceResize();

  useEffect(() => {
    checkOnDeviceResize();
  }, []);

  removeResizeListener();

  return (
    <div>
      <Form.Group controlId="SelectedMentor">
        <Row>
          <Form.Control
            as="select"
            value={selectedMentor}
            onChange={(event) => setSelectedMentor(event.target.value)}
            className={!isMobile && selectedMentor ? "w-75 mt-3" : "w-100 mt-3"}
          >
            <option value="">{t("select_a_mentor")}</option>
            {categories[choosedCategoryIndex].mentors.map((mentor) => (
              <option key={mentor.id} value={mentor.id}>
                {mentor.first_name} {mentor.last_name}
              </option>
            ))}
          </Form.Control>
          {selectedMentor && (
            <Button
              onClick={handleShow}
              className={
                !isMobile
                  ? "btn mentoring-session-btns text-white ml-3 mt-3"
                  : "btn mentoring-session-btns text-white mt-3"
              }
              style={{ width: !isMobile ? "22%" : "100%" }}
            >
              {t("see_mentor_profile")}
            </Button>
          )}
        </Row>
      </Form.Group>
    </div>
  );
};

export default MentorForm;
