import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFetch from "hooks/useFetch";
import PresenceTable from "components/PresenceTable/PresenceTable";
import { useHistory } from "react-router-dom";
import "./ManageActiveSessionPresence.scss";

const ManageActiveSessionPresence = () => {
  const { t } = useTranslation("profile");
  const { get, post, destroy } = useFetch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [activeBootcamp, setActiveBootcamp] = useState(null);
  const [numberOfWeeks, setNumberOfWeeks] = useState(null);
  const [validDatesOnWeek, setValidDatesOnWeek] = useState([]);
  const [checkedStateForMorning, setCheckedStateForMorning] = useState(null);
  const [checkedStateForAfternoon, setCheckedStateForAfternoon] =
    useState(null);
  const [notFilteredExistantPresences, setNotFilteredExistantPresences] =
    useState(null);
  const [dateToWorkWith, setDateToWorkWith] = useState(null);
  const history = useHistory();

  const getAlreadyCompletedBootcampPresences = async () => {
    const response = await get(
      `/users/${currentUser.id}/bootcamp_presences?bootcamp_session_id=${activeBootcamp.id}`
    );
    setNotFilteredExistantPresences(response);
  };

  const handleNewPresence = async () => {
    const data = {
      bootcamp_session_id: activeBootcamp.id,
      date: dateToWorkWith.date,
      morning: dateToWorkWith.morning,
      user_id: currentUser.id,
    };
    const response = await post(
      `/users/${currentUser.id}/bootcamp_presences`,
      data
    );
    setDateToWorkWith(null);
    setNotFilteredExistantPresences([
      ...notFilteredExistantPresences,
      response,
    ]);
  };

  const handleDeletePresence = async () => {
    if (dateToWorkWith) {
      const dateToDeleteId = notFilteredExistantPresences.find(
        (presence) =>
          new Date(presence.date).toString() ===
            new Date(dateToWorkWith.date).toString() &&
          presence.morning === dateToWorkWith.morning
      ).id;
      if (dateToDeleteId) {
        const response = await destroy(
          `/users/${currentUser.id}/bootcamp_presences/${dateToDeleteId}`,
          null,
          false
        );

        setDateToWorkWith(null);
        setNotFilteredExistantPresences(
          notFilteredExistantPresences.filter(
            (presence) => presence.id !== dateToDeleteId
          )
        );
      }
    }
  };

  useEffect(() => {
    currentUser && setActiveBootcamp(currentUser.active_bootcamp);
  }, [currentUser]);

  return (
    <div>
      <h3 className="text-center">{t("manage_active_session_presences")} </h3>

      <PresenceTable
        notFilteredExistantPresences={notFilteredExistantPresences}
        numberOfWeeks={numberOfWeeks}
        setNumberOfWeeks={setNumberOfWeeks}
        checkedStateForMorning={checkedStateForMorning}
        setCheckedStateForMorning={setCheckedStateForMorning}
        checkedStateForAfternoon={checkedStateForAfternoon}
        setCheckedStateForAfternoon={setCheckedStateForAfternoon}
        handleNewPresence={handleNewPresence}
        handleDeletePresence={handleDeletePresence}
        selectedUser={currentUser}
        dateToWorkWith={dateToWorkWith}
        setDateToWorkWith={setDateToWorkWith}
        validDatesOnWeek={validDatesOnWeek}
        setValidDatesOnWeek={setValidDatesOnWeek}
        getAlreadyCompletedBootcampPresences={
          getAlreadyCompletedBootcampPresences
        }
        activeBootcamp={activeBootcamp}
      />
      <div className="text-center my-4">
        <button className="confirm-presence-btn" onClick={() => history.push("/profile/dashboard")}>{t("confirm")}</button>
      </div>
    </div>
  );
};

export default ManageActiveSessionPresence;
