import { useState } from "react";
import { useTranslation } from "react-i18next";
import CourseStructure from "assets/repo_course_structure.png";
import { Form, Button, Row } from "react-bootstrap";
import useFetch from "hooks/useFetch";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading";

const ImportCourse = () => {
  const { t } = useTranslation("admin");
  const { post, error, isLoading } = useFetch();
  const [url, setUrl] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const creationSuccess = () => {
    dispatch(displaySuccess(t("course_imported")));
    history.push("/admin/courses");
  };

  const handleClick = () => {
    post("/admin/courses", { notion_course_id: url }, creationSuccess);
  };

  return (
    <div className="ImportCourse">
      <div>
        <Form.Label>{t("notion_page_id")}</Form.Label>
        <Form.Control
          placeholder={t("notion_page_id")}
          onChange={() => setUrl(event.target.value)}
        />
        <Row>
          <Button
            type="button"
            className="btn btn-primary btn-lg m-3 "
            onClick={handleClick}
          >
            {t("validate_creation")}
          </Button>
          {isLoading && <Loading />}
          {error && <p>{error}</p>}
        </Row>
        <h4>{t("how_to_import")}</h4>
        <p>{t("notion_import_explanation")}</p>
        <p>{t("after_course_submission")}</p>
      </div>
    </div>
  );
};

export default ImportCourse;
