import { Form, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import StandardCompanyForm from "components/StantardCompanyForm";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import StantardLearningPathForm from "components/StantardLearningPathForm";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import { useDispatch } from "react-redux";

const AddPathSubscriptionForCompanyOrBootcampUsers = () => {
  const { t } = useTranslation("admin");
  const { get, post } = useFetch();
  const dispatch = useDispatch();
  const [allCompanies, setAllCompanies] = useState([]);
  const [allLearningPaths, setAllLearningPaths] = useState([]);
  const [companyChoiceError, setCompanyChoiceError] = useState(false);
  const [learningPathChoiceError, setLearningPathChoiceError] = useState(false);
  const [selectedLearningPath, setSelectedLearningPath] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const getAllCompanies = async () => {
    const response = await get("/admin/companies");
    setAllCompanies(response);
  };

  const getAllLearningPaths = async () => {
    const response = await get("/admin/learning_paths");
    setAllLearningPaths(response);
  };

  const checkForCompanyChoiceError = (event) => {
    const allCompaniesIds = allCompanies.map((company) => Number(company.id));
    if (allCompaniesIds.includes(Number(event.target.value)))
      setCompanyChoiceError(false);
    else setCompanyChoiceError(true);
  };

  const checkForLearningPathChoiceError = (event) => {
    const allLearningPathsIds = allLearningPaths.map(learningPath => Number(learningPath.id));
    if (allLearningPathsIds.includes(Number(event.target.value)))
      setLearningPathChoiceError(false);
    else setLearningPathChoiceError(true);
  };

  const handleChangeCompany = (e) => {
    checkForCompanyChoiceError(e);
    setSelectedCompany(e.target.value);
  };

  const handleChangeLearningPath = (e) => {
    checkForLearningPathChoiceError(e);
    setSelectedLearningPath(e.target.value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    post(
      `/admin/subscriptions?company_id=${selectedCompany}&learning_path_id=${selectedLearningPath}`,
      null,
      dispatch(displaySuccess(t("admin:article_subscription_for_user_company_done")))
    );
  };

  useEffect(() => {
    getAllCompanies();
    getAllLearningPaths();
  }, []);

  return (
    <div className="text-center">
      <h3 className="text-center my-3">{t("add_path_subscription")}</h3>
      <Form>

        <div>
          <StandardCompanyForm
            companies={allCompanies}
            noLabel={true}
            handleChange={handleChangeCompany}
          />

          {selectedCompany && companyChoiceError && (
            <span className="text-danger">{t("choose_a_valid_company")}</span>
          )}
        </div>

        <div>
          <StantardLearningPathForm
            learningPaths={allLearningPaths}
            noLabel={true}
            handleChange={handleChangeLearningPath}
          />
          {learningPathChoiceError && (
            <span className="text-danger">{t("choose_a_valid_learning_path")}</span>
          )}
        </div>

        {selectedCompany && !companyChoiceError && selectedLearningPath && !learningPathChoiceError && (
          <Button className="btn btn-warning text-white" onClick={handleSubmit}>
            {t("add_all_employees_to_this_learning_path")}{" "}
          </Button>
        )}
      </Form>
    </div>
  );
};

export default AddPathSubscriptionForCompanyOrBootcampUsers;
