import { useTranslation } from "react-i18next";
import BootcampSessionForm from "./components/BootcampSessionForm";

const CreateBootcamp = () => {
  const { t } = useTranslation("admin");

  return (
    <div>
      <h3 className="text-center">{t("create_bootcamp")}</h3>
      <div>
        <BootcampSessionForm />
      </div>
    </div>
  );
};

export default CreateBootcamp;
