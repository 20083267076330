import { Modal, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/ai";
import useFetch from "hooks/useFetch";

const UserSubscriptionsModal = ({ show, handleClose, user, onSubscriptionDelete }) => {
  const { t } = useTranslation("admin");
  const { destroy, get } = useFetch();

  const handleDeleteSubscription = async (id) => {
    await destroy(`/admin/subscriptions/${id}`, onSubscriptionDelete);
    
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("subscriptions")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(user &&
            user.subscriptions.length > 0 &&
            user.subscriptions.map((subscription) => (
              <Row key={subscription.id}>
                <Col sm={10}>
                  <p>{subscription.learning_path.title} {subscription.id}</p>
                </Col>
                <Col sm={2}>
                  <AiFillCloseCircle size={30} style={{ color: "red" }} onClick={() => handleDeleteSubscription(subscription.id)} />
                </Col>
              </Row>
            ))) || <p>Cet utilisateur n a pas de cours </p>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserSubscriptionsModal;
