import { useState, useEffect } from "react";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import StandardArticleForm from "components/StandardArticleForm";
import { Button, Form } from "react-bootstrap";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading";

const UpdateArticle = () => {
  const { t } = useTranslation("admin");
  const { get, patch, isLoading } = useFetch();
  const [articles, setArticles] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [url, setUrl] = useState(null);
  const history = useHistory();

  const getAllArticles = async () => {
    const response = await get("/admin/articles");
    setArticles(response);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await patch(
      `/admin/articles/${selectedArticleId}`,
      { notion_article_id: url },
      displaySuccess(t("article_updated")),
      displayError(t("article_not_updated"))
    );
    history.push("/admin/articles");
  };

  useEffect(() => {
    getAllArticles();
  }, []);

  return (
    <div>
      <h3 className="text-center">{t("update_article")} </h3>
      {articles && articles.length > 0 ? (
        <div className="my-3">
          <StandardArticleForm
            articles={articles}
            noLabel={true}
            handleChange={(e) => setSelectedArticleId(e.target.value)}
          />
        </div>
      ) : (
        <p className="text-center my-3">{t("no_article_to_update")}</p>
      )}
      {selectedArticleId && selectedArticleId !== null && (
        <Form.Control
          className="mx-auto"
          placeholder={t("notion_page_id")}
          onChange={(e) => setUrl(e.target.value)}
        />
      )}

      {selectedArticleId && selectedArticleId !== null && url && url !== null && (
        <div className="text-center my-3">
          <Button
            className="btn btn-warning btn-lg text-white"
            onClick={handleSubmit}
          >
            {t("update")}
          </Button>
        </div>
      )}
      {isLoading && <Loading />}
    </div>
  );
};

export default UpdateArticle;
