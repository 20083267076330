import { useEffect, useState } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchToLogout } from "stores/authentication/authMiddleware";
import { Navbar, NavDropdown, Nav, Image, Container } from "react-bootstrap";
import "./Navigation.scss";
import noavatar from "assets/noavatar.jpg";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import logo from "assets/Logo-Arsenio.png";
import ProfileIcon from "assets/profil.png";
import { IoArrowDownCircleOutline } from "react-icons/io5";
import { userInitiatedLogout } from "stores/authentication/authActions";

const Navigation = ({
  changeLanguage,
  currentLanguageId,
  languageDropdownOptions,
}) => {
  const token = useSelector((state) => state.auth.token);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isCompany = useSelector((state) => state.auth.isCompany);
  const { data, get } = useFetch();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentLanguageImage, setCurrentLanguageImage] = useState("");

  useEffect(() => {
    if (localStorage.getItem("i18n-language")) {
      const language = languageDropdownOptions.find(
        (language) =>
          language.value ===
          localStorage.getItem("i18n-language").split("-")[0].toLowerCase()
      );

      setCurrentLanguageImage(language?.label ?? "fr");
    }
  }, [changeLanguage]);

  const logout = () => {
    dispatch(userInitiatedLogout());
    history.push("/login");
    dispatch(fetchToLogout(token));
  };

  useEffect(() => {
    // get(`/learning_paths?single=false&language=${currentLanguageId}`);
  }, [currentLanguageImage]);

  return (
    <Navbar className="Navigation" expand="lg">
      <Navbar.Brand>
        <Link className="Navigation__logo" to="/">
          <img src={logo} />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mx-auto d-flex align-items-center">
          {currentUser && currentUser.has_agreed_terms && !isCompany && (
            <Nav.Link>
              <NavLink to="/mentors/index" className="nav-link">
                <span className="mr-1">{t("navigation:mentors")}</span>
              </NavLink>
            </Nav.Link>
          )}

          {currentUser && currentUser.has_agreed_terms && (
            <>
              <NavDropdown
                title={
                  <span className="mr-1">
                    {t("profile:glossaries")}{" "}
                    <IoArrowDownCircleOutline className="arrow-down" />
                  </span>
                }
                id="path-nav-dropdown"
              >
                <NavDropdown.Item>
                  <Link className="dropdown-text" to="/profile/glossary/btp">
                    {t("navigation:btp_glossary")}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="dropdown-text" to="/profile/glossary/bim">
                    {t("navigation:bim_glossary")}
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link className="nav-link mr-3">
                <Link to="/articles">{t("navigation:memo")}</Link>
              </Nav.Link>
            </>
          )}

          {currentUser &&
            currentUser.has_agreed_terms &&
            currentUser.role === "admin" && (
              <>
                <Nav.Link className="btn btn-admin py-1">
                  <Link to="/admin/statistics">
                    {t("navigation:linkAdmin")}
                  </Link>
                </Nav.Link>
              </>
            )}

          {currentUser && currentUser.notion_url && (
            <>
              <div className="btn btn-notion py-1">
                <a
                  href={currentUser.notion_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("navigation:go_to_notion")}
                </a>
              </div>
            </>
          )}

          {currentUser && isCompany && currentUser.has_agreed_terms && (
            <>
              <Nav.Link className="btn btn-company">
                <Link to="/company/users">
                  {t("navigation:company_dashboard")}
                </Link>
              </Nav.Link>
            </>
          )}
        </Nav>

        <Nav className="ml-auto d-flex align-items-center">
          {currentUser && !isCompany && (
            <>
              <NavDropdown
                title={
                  <span className="ml-1 btn dashboard-btn">
                    <Image
                      src={ProfileIcon}
                      alt="Real avatar"
                      className="user-avatar-navbar"
                    />
                  </span>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item>
                  <Link className="dropdown-text" to="/profile/dashboard">
                    {t("profile:dashboard")}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="dropdown-text" to="/profile/mycourses">
                    {t("navigation:my_courses")}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link
                    className="dropdown-text"
                    to="/profile/myarticlesubscriptions"
                  >
                    {t("profile:my_memos")}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="dropdown-text" to="/profile/edit">
                    {t("navigation:my_profile")}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Link className="dropdown-text" onClick={logout} to="/">
                    {t("navigation:linkLogout")}
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            </>
          )}
          {currentUser && isCompany && (
            <>
              <NavDropdown
                title={
                  <span className="ml-1 btn dashboard-btn">
                    <Image
                      src={ProfileIcon}
                      alt="Real avatar"
                      className="user-avatar-navbar"
                    />
                  </span>
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item>
                  <Link className="dropdown-text" to="/profile/edit">
                    {t("navigation:my_profile")}
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <Link className="dropdown-text" onClick={logout} to="/login">
                    {t("navigation:linkLogout")}
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
            </>
          )}
          <NavDropdown
            title={
              <img
                src={currentLanguageImage}
                alt="current language"
                className="language-icon mb-1"
              />
            }
            className="ml-1 btn dashboard-btn language-btn"
            id="selectAppLanguage"
          >
            {languageDropdownOptions.map(
              (option) =>
                option.label !== currentLanguageImage && (
                  <NavDropdown.Item
                    key={option.value}
                    onClick={() => changeLanguage(`${option.value}`)}
                    className="language-dropdown text-center"
                  >
                    <Image
                      src={`${option.label}`}
                      alt={`${option.label} flag`}
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                      }}
                    />
                  </NavDropdown.Item>
                )
            )}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
