import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PdfComponent from "components/PDFComponent/PdfComponent";
import { useLocation } from "react-router";
import { Form, Button, Col, Row } from "react-bootstrap";
import Loading from "components/Loading";

const GeneratePdfForSelectedUserInSelectedBootcamp = () => {
  const { t } = useTranslation("admin");
  const { get } = useFetch();
  const locationPathname = useLocation().pathname;
  const [bootcampCompanyId, setBootcampCompanyId] = useState(
    locationPathname?.split("/")[3]
  );
  const [userId, setUserId] = useState(locationPathname?.split("/")[5]);
  const [bootcampCompany, setBootcampCompany] = useState(null);
  const [user, setUser] = useState(null);
  const [bootcampSession, setBootcampSession] = useState(null);
  const [formationName, setFormationName] = useState("");
  const [numberOfWeeks, setNumberOfWeeks] = useState(null);
  const [validDatesOnWeek, setValidDatesOnWeek] = useState(null);
  const [notFilteredExistantPresences, setNotFilteredExistantPresences] =
    useState(null);
  const [checkedStateForMorning, setCheckedStateForMorning] = useState(null);
  const [checkedStateForAfternoon, setCheckedStateForAfternoon] =
    useState(null);
  const [readyToGenerate, setReadyToGenerate] = useState(false);

  const getBootcampData = async () => {
    const response = await get(`/admin/companies/${bootcampCompanyId}`);
    setBootcampCompany(response);
  };

  const getAlreadyCompletedBootcampPresencesForSelectedUser = async () => {
    const response = await get(
      `/admin/bootcamp_presences?bootcamp_session_id=${bootcampSession.id}&user_id=${userId}`
    );
    setNotFilteredExistantPresences(response);
  };

  const filterSelectedUser = (users) => {
    return users.find((user) => Number(user.id) === Number(userId));
  };

  useEffect(() => {
    getBootcampData();
  }, [bootcampCompanyId]);

  useEffect(() => {
    if (bootcampCompany) {
      setUser(filterSelectedUser(bootcampCompany?.users));
      setBootcampSession(bootcampCompany.bootcamp_session);
    }
  }, [bootcampCompany]);

  return (
    <div>
      {(user && user.encoded_signature_picture && (
        <div>
          <Form className="my-3">
            <Row>
              <Col sm={12} md={8} className="mb-3">
                <Form.Control
                  type="text"
                  placeholder={t("formation_name")}
                  value={formationName}
                  onChange={(e) => setFormationName(e.target.value)}
                />
              </Col>
              <Col sm={12} md={3} className="mb-3">
                <div className="text-center">
                  <Button
                    className="btn btn-warning text-white"
                    onClick={() => setReadyToGenerate(true)}
                  >
                    {t("generate_pdf")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          {user && bootcampSession && readyToGenerate && (
            <PDFDownloadLink
              document={
                <PdfComponent
                  firstName={user.first_name}
                  lastName={user.last_name}
                  bootcampSession={bootcampSession}
                  bootcampStartDate={bootcampSession.start_date}
                  bootcampEndDate={bootcampSession.end_date}
                  formationName={formationName}
                  user={user}
                  checkedStateForMorning={checkedStateForMorning}
                  setCheckedStateForMorning={setCheckedStateForMorning}
                  checkedStateForAfternoon={checkedStateForAfternoon}
                  setCheckedStateForAfternoon={setCheckedStateForAfternoon}
                  notFilteredExistantPresences={notFilteredExistantPresences}
                  numberOfWeeks={numberOfWeeks}
                  setNumberOfWeeks={setNumberOfWeeks}
                  validDatesOnWeek={validDatesOnWeek}
                  setValidDatesOnWeek={setValidDatesOnWeek}
                  getAlreadyCompletedBootcampPresencesForSelectedUser={
                    getAlreadyCompletedBootcampPresencesForSelectedUser
                  }
                />
              }
              fileName={`${user.first_name}_${user.last_name}_${formationName}_${bootcampSession.start_date}_${bootcampSession.end_date}.pdf`}
            >
              {({ loading }) =>
                loading ? (
                  <div
                    className="text-center mx-auto"
                    style={{ width: "100%" }}
                  >
                    <Loading />
                  </div>
                ) : (
                  <div className="text-center">
                    <Button className="btn btn-warning text-white">
                      {t("download")}
                    </Button>
                  </div>
                )
              }
            </PDFDownloadLink>
          )}
        </div>
      )) || <p className="text-center">{t("any_signature_generated_yet")}</p>}
    </div>
  );
};

export default GeneratePdfForSelectedUserInSelectedBootcamp;
