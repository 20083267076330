import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import gfm from "remark-gfm";
import "./MarkdownConverter.scss";
import rehypeRaw from "rehype-raw";

const MarkdownConverter = ({ text }) => {
  return (
    <ReactMarkdown remarkPlugins={[gfm, remarkGfm]} rehypePlugins={[rehypeRaw]} style={{maxWidth: "100%", overflowX: "hidden"}}>
      {text}
    </ReactMarkdown>
  );
};

export default MarkdownConverter;
