import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import CompanyRoute from "components/CompanyRoute";
import GetUsers from "./components/SeeEmployees/GetUser/GetUsers";
import DisplayUser from "./components/SeeEmployees/DisplayUser/DisplayUser";
import CompanySidebar from "./components/CompanySidebar";
import AddEmployees from "./components/ManageEmployees/AddEmployees/AddEmployees";
import SeeCompanyUserRequests from "./components/ManageEmployees/AddEmployees/SeeCompanyUserRequests";
import DisplayFullCompanyUserRequestToCompany from "./components/ManageEmployees/DisplayFullCompanyUserRequestToCompany/DisplayFullCompanyUserRequestToCompany";
import GeneralTermsOfUse from "components/GeneralTermsOfUse/GeneralTermsOfUse";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import {
  fetchCurrentCompany,
  fetchCurrentUserMinimalsDetails,
} from "stores/authentication/authMiddleware";
import SeeStatistics from "./components/SeeStatistics/SeeStatistics";

const Company = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isCompany = useSelector((state) => state.auth.isCompany);
  const [userUpdated, setUserUpdated] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = Cookies.get("token");
    if (userUpdated && !isCompany) {
      dispatch(fetchCurrentUserMinimalsDetails(token));
    } else if (userUpdated && isCompany) {
      dispatch(fetchCurrentCompany(token, currentUser));
    }
    setUserUpdated(false);
  }, [userUpdated]);

  return (
    <div className="Company">
      {currentUser && currentUser?.has_agreed_terms ? (
        <Row className="m-0 p-0">
          <Col md={3} lg={3} className="m-0 p-0">
            <CompanySidebar />
          </Col>
          <Col md={9} lg={9} className="m-0 p-0">
            <Container>
              <Switch>
                <CompanyRoute
                  currentUser={currentUser}
                  component={SeeStatistics}
                  path="/company/statistics"
                  exact
                />
                <CompanyRoute
                  currentUser={currentUser}
                  component={GetUsers}
                  path="/company/users"
                  exact
                />
                <CompanyRoute
                  currentUser={currentUser}
                  component={DisplayUser}
                  path="/company/user/:id"
                />
                <CompanyRoute
                  currentUser={currentUser}
                  component={AddEmployees}
                  path="/company/users/add"
                  exact
                />
                <CompanyRoute
                  currentUser={currentUser}
                  component={SeeCompanyUserRequests}
                  path="/company/users/requests"
                  exact
                />
                <CompanyRoute
                  currentUser={currentUser}
                  component={DisplayFullCompanyUserRequestToCompany}
                  path="/company/users/requests/:id"
                />
              </Switch>
            </Container>
          </Col>
        </Row>
      ) : (
        <GeneralTermsOfUse
          setUserUpdated={setUserUpdated}
          isCompany={true}
        />
      )}
    </div>
  );
};

export default Company;
