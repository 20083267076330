const ProgressLessonLine = ({
  selectedCourseProgressState,
  progressLesson,
}) => {
  return (
    <tr>
      <td>{selectedCourseProgressState.course_id}</td>
      <td>
        {
          selectedCourseProgressState.lessons.find(
            (lesson) => Number(lesson.id) === Number(progressLesson.lesson_id)
          ).title
        }
      </td>
      <td>{progressLesson.quizz_result}</td>
    </tr>
  );
};

export default ProgressLessonLine;
