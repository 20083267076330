import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Table, Col } from "react-bootstrap";
import CompanyRequestLine from "./CompanyRequestLine";
import CompanyForm from "../UpdateCompanyUsers/components/CompanyForm";
import { AiFillEye } from "react-icons/ai";

const ManageCompanyUserRequest = () => {
  const { t } = useTranslation("admin");
  const { get } = useFetch();
  const [allCompaniesRequests, setAllCompaniesRequests] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [toReload, setToReaload] = useState(false);
  const [allCompaniesIds, setAllCompaniesIds] = useState([]);
  const noLabel = true;

  const getAllCompaniesUsersRequests = async () => {
    const data = await get("/admin/company_user_requests");
    setAllCompaniesRequests(data);
  };

  const getFilteredCompanyUserRequests = async () => {
    const data = await get(
      `/admin/company_user_requests?company_id=${selectedCompanyId}`
    );
    setAllCompaniesRequests(data);
  };

  const reload = () => {
    setToReaload(!toReload);
  };

  const checkIfSelectedCompanyExist = (companyId) => {
    return allCompaniesIds.includes(Number(companyId));
  };

  useEffect(() => {
    getAllCompaniesUsersRequests();
  }, []);

  useEffect(() => {
    if (checkIfSelectedCompanyExist(selectedCompanyId)) getFilteredCompanyUserRequests();
    else getAllCompaniesUsersRequests();
  }, [selectedCompanyId]);

  useEffect(() => {
    getAllCompaniesUsersRequests();
  }, [toReload]);

  return (
    <div>
      <h3 className="text-center">{t("manage_company_user_request")}</h3>
      <Row className="align-items-center">
        <Col sm={12} md={3} lg={3}>
          {t("filter_by_company")} :
        </Col>
        <Col sm={12} md={9} lg={9}>
          <CompanyForm
            handleChange={(e) => setSelectedCompanyId(e.target.value)}
            setAllCompaniesIds={setAllCompaniesIds}
            noLabel={noLabel}
          />
        </Col>
      </Row>
      {allCompaniesRequests && allCompaniesRequests.length > 0 && (
        <Table striped bordered hover className="my-3">
          <thead>
            <tr>
              <th>{t("company")}</th>
              <th>{t("email")}</th>
              <th>{t("first_name")}</th>
              <th>{t("last_name")}</th>
              <th>{t("state")}</th>
              <th>{t("manage")}</th>
              <th>{t("show")}</th>
            </tr>
          </thead>
          <tbody>
            {allCompaniesRequests.map((request) => (
              <CompanyRequestLine
                request={request}
                key={request.id}
                reload={reload}
                setAllCompaniesIds={setAllCompaniesIds}
                noLabel={false}
              />
            ))}
          </tbody>
        </Table>
      )}
      {allCompaniesRequests && allCompaniesRequests.length === 0 && checkIfSelectedCompanyExist(selectedCompanyId) && (
        <p>{t("this_company_doesnt_have_requests")} </p>
      )}
    </div>
  );
};

export default ManageCompanyUserRequest;
