import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Form, Button, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";

const SkillsForm = ({ selectedMentor, notValidMentor, reload }) => {
  const { t } = useTranslation("admin");
  const { post, get, destroy } = useFetch();
  const [skill, setSkill] = useState("");
  const [selectedMentorSkills, setSelectedMentorSkills] = useState([]);

  const handleSubmit = (event) => {
    const data = {
      mentor_profile_id: selectedMentor,
      skill: skill,
    };
    event.preventDefault();
    post(
      "/admin/mentor_skills",
      data,
      null,
      displaySuccess(t("mentor_profile_skill_created"))
    );
    setSkill("");
    reload();
  };

  const handleDelete = (id) => {
    destroy(`/admin/mentor_skills/${id}`, reload);
  };

  useEffect(() => {
    const getSelectedMentorSkills = async () => {
      const data = await get(
        `/admin/mentor_skills?profile_id=${selectedMentor}`
      );
      setSelectedMentorSkills(data);
    };

    getSelectedMentorSkills();
  }, [selectedMentor, reload]);

  return (
    <div className="my-4">
      {(selectedMentor && !notValidMentor && (
        <Form>
          <Form.Label>{t("add_a_skill")} :</Form.Label>
          <Form.Control
            type="text"
            placeholder="BIM, MOA..."
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
          />
          {selectedMentorSkills &&
            selectedMentorSkills.length > 0 &&
            selectedMentorSkills.map((skill) => (
              <span className="badge badge-warning my-2 mx-1" key={skill.id}>
                {skill.skill}
                <button type="button" className="close">
                  <span
                    aria-hidden="true"
                    onClick={() => handleDelete(skill.id)}
                  >
                    &times;
                  </span>
                </button>
              </span>
            ))}
          {skill && skill.length > 2 && !notValidMentor && (
            <div className="d-flex row justify-content-center my-4">
              <Button
                type="submit"
                className="ButtonPrimary w-50"
                onClick={handleSubmit}
              >
                {t("send")}
              </Button>
            </div>
          )}
        </Form>
      )) || <small className="text-danger">{t("not_valid_mentor")}</small>}
    </div>
  );
};

export default SkillsForm;
