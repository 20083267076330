import { useState } from "react";
import { useComponentWillMount } from "hooks/useComponentWillMount";

const useDeviceResize = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkOnDeviceResize = () => {
    window.addEventListener("resize", updateDeviceSize);
  };

  const updateDeviceSize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const removeResizeListener = () => {
    useComponentWillMount(() =>
      window.removeEventListener("resize", updateDeviceSize)
    );
  };

  return {
    isMobile,
    checkOnDeviceResize,
    removeResizeListener,
  };
};

export default useDeviceResize;
