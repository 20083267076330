import useFetch from "hooks/useFetch";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import Loading from "components/Loading";
import LittleCourseCard from "components/littleCourseCard/LittleCourseCard";

const MyCourses = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { get, isLoading } = useFetch();
  const [subscriptions, setSubscritpions] = useState(currentUser?.subscriptions);
  const [userCourses, setUserCourses] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();

  const getAllCurrentUserSubscriptions = async () => {
    const response = await get(`/users/${currentUser.id}/subscriptions`);
    setSubscritpions(response);
  };

  const filteredSubscriptionsForCoursesOnly = (subscriptions) => {
    let courses = [];
    subscriptions.map((subscription) => {
      subscription.learning_path.courses.map((course) => courses.push(course));
    });

    const completeCourses = getEachCurrentUserCourseProgression(courses);

    const filteredCourses = new Set([...completeCourses]);
    setUserCourses([...filteredCourses]);
  };

  const getEachCurrentUserCourseProgression = (courses) => {
    return courses.map((course) => {
      return {
        ...course,
        progressState: getCurrentUserCourseProgression(course),
      };
    });
  };

  const getCurrentUserCourseProgression = (course) => {
    return course.progress_states.find(
      (state) => Number(state.user_id) === Number(currentUser.id)
    );
  };

  useEffect(() => {
    if (currentUser) {
      getAllCurrentUserSubscriptions();
    }
  }, [currentUser]);

  useEffect(() => {
    if (subscriptions) {
      filteredSubscriptionsForCoursesOnly(subscriptions);
    }
  }, [subscriptions]);

  return (
    <div className="MyCourses">
      <div className="page-title-section text-center">
        <h4 className="page-title">{t("navigation:my_courses")}</h4>
      </div>
      <Row className="d-flex justify-content-center">
        {userCourses &&
          userCourses.length > 0 &&
          userCourses.map((course) => (
            <LittleCourseCard key={course.id} course={course} />
          ))}
        {!isLoading && userCourses && userCourses.length === 0 && (
          <p className="text-center">{t("no_subscriptions")}</p>
        )}
      </Row>
    </div>
  );
};

export default MyCourses;
