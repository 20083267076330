import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Badge, Container, Row, Button } from "react-bootstrap";
import CardAccordion from "components/CardAccordion/CardAccordion";
import ModalMentorSessionEdit from "./components/ModalMentorSessionEdit/ModalMentorSessionEdit";
import { useLocation, useHistory } from "react-router-dom";
import MarkdownConverter from "components/MarkdownConverter/MarkdownConverter";
import moment from "moment";
import "moment/locale/fr";

const MentoringSessionShow = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [show, setShow] = useState(false);
  const [session, setSession] = useState({});
  const [toReload, setToReload] = useState(false);
  const { t } = useTranslation("profile");
  const { get, destroy } = useFetch();
  const route = useLocation();
  const history = useHistory();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getSessionId = () => {
    return route.pathname.split("/").pop();
  };

  const getCurrentUserId = () => {
    return route.pathname.split("/")[2];
  };

  const getCurrentSession = async () => {
    const currentSession = await get(
      `/users/${currentUserId}/mentoring_sessions/${sessionId}?role=${
        currentUser && currentUser.role === "mentor" ? "mentor" : "student"
      }`
    );
    setSession(currentSession[0]);
  };

  const handleDelete = async () => {
    await destroy(`/users/${currentUserId}/mentoring_sessions/${session.id}`);
    history.goBack();
  };

  useEffect(() => {
    const currentUserIdInUrl = getCurrentUserId();
    const sessionIdInUrl = getSessionId();
    setCurrentUserId(currentUserIdInUrl);
    setSessionId(sessionIdInUrl);
  }, []);

  useEffect(() => {
    getCurrentSession();
  }, [sessionId, toReload, currentUser]);

  return (
    <section>
      {session && session.id && (
        <div>
          <Row className="d-flex flex-column align-items-center mt-3 mb-5">
            <>
              {(currentUser && currentUser.role === "mentor" && (
                <h3>
                  {t("your_session_with")} {session.student.first_name}
                </h3>
              )) || (
                <h3>
                  {t("your_session_with")} {session.mentor.first_name}
                </h3>
              )}
            </>
            <Badge
              pill
              className={
                session.finished
                  ? "badge-success text-white"
                  : "badge-warning text-white"
              }
            >
              {session.finished ? t("session_done") : t("coming_soon")}
            </Badge>
          </Row>
          <Container>
            <Row className="ml-1 d-flex justify-content-between">
              <div>
                {session && session.session_date && (
                  <p>
                    {t("expected_date")} :{" "}
                    <span className="font-weight-bold">
                      {moment(session.session_date)
                        .locale("fr")
                        .format("DD MMMM YYYY")}
                    </span>{" "}
                    à{" "}
                    <span className="font-weight-bold">
                      {moment(session.session_date).locale("fr").format("HH") +
                        " h " +
                        moment(session.session_date).locale("fr").format("mm")}
                    </span>
                  </p>
                )}
              </div>
              <div className="mr-3">
                {session && session.session_url && (
                  <Button
                    className="btn btn-warning text-white"
                    onClick={() => window.open(session.session_url, "_blank")}
                  >
                    {t("access_to_session")} {<span className="ml-1">🖥️</span>}
                  </Button>
                )}
              </div>
            </Row>
            <div className="my-3">
              <CardAccordion
                header={t("your_session_description")}
                content={<p>{session.description}</p>}
              />
            </div>
            <div className="my-3">
              {(session && session.report && session.report.length > 0 && (
                <CardAccordion
                  header={t("your_session_report")}
                  content={<MarkdownConverter text={session.report} />}
                />
              )) || <p>{t("no_report_yet")}</p>}
            </div>
            <Row div className="d-flex justify-content-center">
              {session && currentUser && currentUser.role === "mentor" && (
                <Button
                  className="btn btn-warning text-white mx-1"
                  onClick={handleShow}
                >
                  {t("edit_session")} {<span className="ml-1">➕</span>}
                </Button>
              )}
              {session && currentUser && session.finished === false && (
                <Button
                  className="btn btn-danger text-white mx-1"
                  onClick={handleDelete}
                >
                  {t("delete_session")} {<span className="ml-1">🗑️</span>}
                </Button>
              )}
            </Row>
          </Container>
        </div>
      )}
      {handleShow && (
        <ModalMentorSessionEdit
          show={show}
          handleClose={handleClose}
          session={session}
          toReload={toReload}
          setToReload={setToReload}
        />
      )}
    </section>
  );
};

export default MentoringSessionShow;
