import { Modal } from "react-bootstrap";

const DeletionValidationModal = ({
  translator,
  handleClose,
  show,
  setValidation,
  validationPhrase,
}) => {

  const handleClick = (event) => {
    event.preventDefault();
    setValidation(true);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "20px" }}>
          {translator("are_your_sure")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p>{translator(validationPhrase)}</p>
          <button
            className="btn btn-warning text-white"
            onClick={handleClick}
          >
            {translator("yes")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeletionValidationModal;
