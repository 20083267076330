import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";

const GeneralesInformationsForm = () => {
  const { t } = useTranslation("admin");
  const { get, isLoading, data, post } = useFetch();
  const history = useHistory();
  const [mentors, setMentors] = useState([]);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [job, setJob] = useState("");
  const [presentation, setPresentation] = useState("");
  const [existingsMentors, setExistingsMentors] = useState([]);
  const [alreadyProfileExist, setAlreadyProfileExist] = useState(false);

  useEffect(() => {
    const getMentors = async () => {
      const data = await get("/admin/users?is_teacher=true");
      setMentors(data);
    };

    const getExistingMentorProfiles = async () => {
      const data = await get("/admin/mentor_profiles");
      setExistingsMentors(data);
    };

    getMentors();
    getExistingMentorProfiles();
  }, []);

  const redirectAfterProfileCreation = () => {
    history.push("/admin/mentorprofile/update");
  };

  const handleSubmit = (event) => {
    const data = {
      mentor_id: selectedMentor,
      job: job,
      presentation: presentation,
    };
    event.preventDefault();
    post(
      "/admin/mentor_profiles",
      data,
      redirectAfterProfileCreation,
      displaySuccess(t("mentor_profile_created"))
    );
  };

  const checkIfSelectedMentorDoesntHaveProfileYet = () => {
    let existingMentorProfileIdArray = [];

    existingsMentors.map((existingProfil) =>
      existingMentorProfileIdArray.push(existingProfil.mentor_id)
    );

    if (existingMentorProfileIdArray.includes(Number(selectedMentor))) {
      setAlreadyProfileExist(true);
    } else {
      setAlreadyProfileExist(false);
    }
  };

  useEffect(() => {
    checkIfSelectedMentorDoesntHaveProfileYet();
  }, [selectedMentor]);

  return (
    <div>
      <Form>
        <div className="mt-2 my-4">
          {(!isLoading && mentors && mentors.length > 0 && (
            <div>
              <Form.Label>
                {t("choose_to_which_user_this_profile_will_belong")} :
              </Form.Label>
              <Form.Control
                sm="10"
                as="select"
                onChange={(event) => setSelectedMentor(event.target.value)}
              >
                <option>
                  {t("choose_to_which_user_this_profile_will_belong")}
                </option>
                {mentors &&
                  mentors.length > 0 &&
                  mentors.map((mentor) => (
                    <option
                      key={mentor.id}
                      value={mentor.id}
                      name={mentor.first_name}
                    >
                      {mentor.id} - {mentor.first_name} {mentor.last_name}
                    </option>
                  ))}
              </Form.Control>
              {alreadyProfileExist === true && (
                <small className="text-danger">
                  {t("profile_already_exist")}
                </small>
              )}
            </div>
          )) || <p>{t("loading")}</p>}
        </div>
        <div className="my-4">
          <Form.Label>{t("define_job_name")} :</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("job_name_placeholder")}
            onChange={(e) => setJob(e.target.value)}
          />
        </div>
        <div className="my-4">
          <Form.Label>{t("create_mentor_presentation")} :</Form.Label>
          <Form.Control
            as="textarea"
            type="text"
            placeholder={t("create_mentor_presentation")}
            onChange={(e) => setPresentation(e.target.value)}
          />
        </div>
        {selectedMentor &&
          selectedMentor.length > 0 &&
          job &&
          job.length > 0 &&
          presentation &&
          presentation.length > 0 &&
          alreadyProfileExist !== true && (
            <div className="d-flex row justify-content-center mb-4">
              <Button
                type="submit"
                className="ButtonPrimary w-50"
                onClick={handleSubmit}
              >
                {t("send")}
              </Button>
            </div>
          )}
      </Form>
    </div>
  );
};

export default GeneralesInformationsForm;
