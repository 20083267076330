import { useEffect } from "react";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const UserForm = ({ handleChange, noLabel }) => {
  const { data, error, isLoading, get } = useFetch();
  const { t } = useTranslation("admin");

  useEffect(() => {
    get("/admin/users?no_details=true");
  }, []);

  return (
    <div className="my-4">
      {error && <h4>{error}</h4>}
      {(isLoading && t("loading")) ||
        (data && (
          <div>
            {noLabel === false && <h6>{t("admin:choose_user")}</h6>}
            <Form.Control as="select" className="my-3" onChange={handleChange}>
              <option>{t("admin:choose_user")}</option>
              {data.map((user) => (
                <option key={data.id} value={user.id}>
                  {user.id} - {user.first_name} {user.last_name} - {user.email}
                </option>
              ))}
            </Form.Control>
          </div>
        ))}
    </div>
  );
};

export default UserForm;
