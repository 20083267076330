import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import useDeviceResize from "hooks/useDeviceResize";
import { capitalize } from "tools/capitalize";
import useFetch from "hooks/useFetch";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const EditUserRoleForm = ({ user, reload, handleClose }) => {
  const { t } = useTranslation("admin");
  const { patch } = useFetch();
  const possibleRoles = ["student", "mentor", "admin"];
  const [selectedRole, setSelectedRole] = useState("");
  const [roleError, setRoleError] = useState(false);
  const {
    isMobile,
    checkOnDeviceResize,
    removeResizeListener,
  } = useDeviceResize();

  const checkIfSelectedRoleAreInPossibleRolesArray = (event) => {
    event.preventDefault();
    if (possibleRoles.includes(selectedRole)) handleSubmit();
    else setRoleError(true);
    return;
  };

  const handleSubmit = async () => {
    await patch(
      `/admin/users/${user.id}`,
      { role: selectedRole },
      displaySuccess(t("role_updated")),
      displayError(t("role_not_updated"))
    );
    handleClose();
    reload();
  };

  const handleChange = (event) => {
    setRoleError(false);
    setSelectedRole(event.target.value);
  };

  useEffect(() => {
    checkOnDeviceResize();
  }, []);

  removeResizeListener();

  return (
    <Form onSubmit={checkIfSelectedRoleAreInPossibleRolesArray}>
      <Form.Group
        controlId="SelectRole"
        className={!isMobile ? "input-group mb-3" : "mb-3 w-100"}
      >
        <Form.Control
          as="select"
          onChange={(event) => handleChange(event)}
          style={{
            borderRadius: "5px",
            border: roleError ? "1px solid red" : "",
          }}
        >
          <option>{t("choose_role")}</option>
          {possibleRoles.map((role) => (
            <option key={role} value={role}>
              {capitalize(role)}
            </option>
          ))}
        </Form.Control>
        <div className="text-center">
          <Button
            variant="warning"
            className={!isMobile ? "text-white ml-3" : "text-white mt-3"}
            type="submit"
          >
            {t("edit_user_role")}
          </Button>
        </div>
      </Form.Group>
      {roleError && (
        <Form.Text className="text-danger">
          {t("error_in_role_choice")}
        </Form.Text>
      )}
    </Form>
  );
};

export default EditUserRoleForm;
