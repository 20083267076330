import { findTokenAfterLinkedinAuth } from "helpers/findTokenAfterLinkedinAuth";
import { useEffect } from "react";
import {
  fetchCurrentUser,
  fetchCurrentUserMinimalsDetails,
} from "stores/authentication/authMiddleware";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const HomePage = () => {
  const findToken = findTokenAfterLinkedinAuth();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const userInitiatedLogout = useSelector(
    (state) => state.auth.userInitiatedLogout
  );
  useEffect(() => {
    findToken;
    const token = Cookies.get("token");
    fetchCurrentUserMinimalsDetails(token);
  }, []);

  const redirectIfUserLogged = () => {
    const isCompany = Cookies.get("company");

    if (userInitiatedLogout) {
      history.push("/login");
      return;
    }

    if (history.location.pathname !== "/" && !currentUser) {
      history.push("/login");
      return;
    }

    if (history.location.pathname === "/" && !currentUser) {
      history.push("/login");
      return;
    }

    if (history.location.pathname === "/" && currentUser && !isCompany) {
      history.push("/profile/dashboard");
      return;
    }

    if (history.location.pathname !== "/" && currentUser) {
      return;
    }

    if (history.location.pathname === "/" && isCompany) {
      history.push("/company/statistics");
    }
  };

  useEffect(() => {
    redirectIfUserLogged();
  }, [currentUser]);

  return <div className="home-page"></div>;
};

export default HomePage;
