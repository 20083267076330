import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { Table } from "react-bootstrap";
import TeacherLine from "./TeacherLine";

const ManageTeachers = () => {
  const { data, error, isLoading, get } = useFetch();
  const { data: categories, get: getCategories } = useFetch();
  const { t } = useTranslation("admin");
  const [toReload, setToReload] = useState(false);

  const reload = () => {
    setToReload(!toReload);
  };

  const handleEdit = () => {
    get("/admin/users?is_teacher=true");
  };

  console.log(data);

  useEffect(() => {
    get("/admin/users?is_teacher=true");
    getCategories("/admin/categories");
  }, []);

  useEffect(() => {
    get("/admin/users?is_teacher=true");
  }, [toReload]);

  return (
    <div className="ManageTeachers">
      {error && <h4>{error}</h4>}
      {(isLoading && t("loading")) ||
        (data && (
          <>
            <div className="text-center">
              <h3>{t("manage_teachers")}</h3>
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t("first_name")}</th>
                  <th>{t("last_name")}</th>
                  <th>{t("email")}</th>
                  <th>{t("hubspot_username")}</th>
                  <th>{t("title_category")}</th>
                  <th>{t("update")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((user) => (
                  <TeacherLine
                    key={user.id}
                    mentor={user}
                    handleEdit={handleEdit}
                    categories={categories}
                    reload={reload}
                  />
                ))}
              </tbody>
            </Table>
          </>
        ))}
    </div>
  );
};

export default ManageTeachers;
