import "../Login/Login.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchToLogin } from "stores/authentication/authMiddleware";
import { useTranslation } from "react-i18next";
import logincover from "assets/covers/right-side-login.png";
import leftSidePicture from "assets/covers/left-side-login.png";
import {
  Row,
  Col,
  Image,
  Form,
  FormGroup,
  FormControl,
  Button,
  Container,
} from "react-bootstrap";
import { LINKEDIN_URL } from "helpers/linkedinAuth";
import { GrLinkedin } from "react-icons/gr";
import "./AdminLogin.scss";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation("login");
  const dispatch = useDispatch();
  const history = useHistory();

  const login = async (e) => {
    const data = {
      user: {
        email: email,
        password: password,
      },
    };
    e.preventDefault();

    if (await dispatch(fetchToLogin(data))) {
      history.push("/admin");
    }
  };

  return (
    <Container fluid className="AdminLogin">
      <Row style={{ minHeight: "100%" }}>
        <Col
          xs={12}
          md={6}
          className="Login_col d-flex flex-column justify-content-center col-lg-6 col-md-12"
        >
          <Image
            src={leftSidePicture}
            alt="left side login page picture"
            className="left-side-picture"
            height={700}
          />
          <div className="login-text-block">
            <h2 className="happy text-center p-3">
              {t("login:good_to_see_you")}
            </h2>
            <Form
              className="mt-3 mb-4 d-flex flex-column justify-content-around w-50"
              onSubmit={login}
            >
              <FormGroup>
                <FormControl
                  className="p-4 mb-3"
                  type="email"
                  placeholder={t("login:placeholderemail")}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <FormControl
                  className="p-4 mb-3"
                  type="password"
                  placeholder={t("login:placeholderpassword")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </FormGroup>
              <Button
                type="submit"
                className="company-btn w-75 mx-auto"
                size="lg"
              >
                {t("login:labelbutton")}
              </Button>
            </Form>
            <button
              className="linkedin-btn btn btn-primary btn-lg mx-auto font-weight-bold"
              size="lg"
            >
              <GrLinkedin className="mb-1" />
              <a href={LINKEDIN_URL} className="linkedin-link ml-2">
                <span>{t("login:linkedin_connexion")}</span>
              </a>
            </button>
          </div>
        </Col>
        <Col md={6} className="Login_col d-none d-lg-block text-center">
          <Image src={logincover} alt="Illustration login page" height={800} />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminLogin;
