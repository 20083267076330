import { useParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Image } from "react-bootstrap";
import UserFeed from "./components/UserFeed/UserFeed.jsx";

const SeeSpecificUserStatistics = () => {
  const params = useParams();
  const { data, get, isLoading, error } = useFetch();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (error) { 
      console.log(error, "error");
    }
  }, [error]);

  useEffect(() => {
    get(`/admin/users/${params.id}?for_statistics=true`);
  }, [params]);

  return (
    <div className="mx-auto">
      <div className="my-3 text-center">{error && error === "Not Found" && t("user_not_found")}</div>
      {(isLoading && !error && t("admin:loading")) ||
        (data && (
          <Row className="mt-3">
            <Col xs={12} sm={12} md={12} lg={12}>
              <UserFeed user={data} />
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default SeeSpecificUserStatistics;