import { Form } from "react-bootstrap";

const TagsFilter = ({ tags, selectedTags, setSelectedTags }) => {
  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setSelectedTags([...selectedTags, e.target.value]);
    } else {
      setSelectedTags(
        selectedTags.filter((tag) => tag !== e.target.value)
      );
    }
  };

  return (
    <>
      {tags && tags.length > 0 && (
        <Form className="col-lg-12 mx-auto text-center mb-3">
          {tags.map((type) => (
            <div key={type.id} className="mb-3 d-inline">
              <Form.Check
                custom
                label={type.name}
                onChange={handleCheckbox}
                type="checkbox"
                value={type.id}
                id={`inline-checkbox-${type.id}`}
              />
            </div>
          ))}
        </Form>
      )}
    </>
  );
};

export default TagsFilter;
