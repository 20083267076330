import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import CheckboxLearningPaths from "./CheckboxLearningPaths";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { useDispatch } from "react-redux";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";

const TeacherLine = ({ mentor, handleEdit, categories, reload }) => {
  const { t } = useTranslation("admin");
  const { patch } = useFetch();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [newCalendlyUsername, setNewCalendlyUsername] = useState("");

  const updateTeacherLearningPaths = (categories) => {
    if (editing) {
      setEditing(false);
      handleEdit();
      return;
    }
    setEditing(true);
  };

  const handleSubmitCalendlyUsername = async (event) => {
    event.preventDefault();
    patch(
      `/admin/users/${mentor.id}`,
      { calendly_username: newCalendlyUsername },
      dispatch(displaySuccess(t("hubspot_username_updated")))
    );
    reload();
  };

  return (
    <tr key={mentor.id}>
      <td>{mentor.id}</td>
      <td>{mentor.first_name}</td>
      <td>{mentor.last_name}</td>
      <td>{mentor.email}</td>
      {!editing ? (
        <td>{mentor.calendly_username}</td>
      ) : (
        <td>
          <Form onSubmit={handleSubmitCalendlyUsername}>
            <Form.Control
              className="w-100"
              type="text"
              placeholder={t("update_hubspot_username")}
              onChange={(e) => setNewCalendlyUsername(e.target.value)}
            />
            <div className="text-center mt-3">
              <button
                type="submit"
                className="btn btn-warning text-white"
                onClick={handleSubmitCalendlyUsername}
              >
                {t("update")}
              </button>
            </div>
          </Form>
        </td>
      )}
      {!editing ? (
        <td>{mentor.categories.map((c) => c.title).join(",")}</td>
      ) : (
        categories && (
          <td>
            <Form>
              {categories.map((category) => (
                <CheckboxLearningPaths
                  key={category.id}
                  title={category.title}
                  category={category}
                  mentor={mentor}
                  handleEdit={handleEdit}
                />
              ))}
            </Form>
          </td>
        )
      )}
      <td>
        <span className="ml-3">
          <AiOutlineEdit
            size={30}
            onClick={updateTeacherLearningPaths}
            style={{ color: "orange" }}
          />
        </span>
      </td>
    </tr>
  );
};

export default TeacherLine;
