import { Form, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import useFetch from "hooks/useFetch";
import { useHistory } from "react-router";
import StandardCompanyForm from "components/StantardCompanyForm";
import DatePicker from "react-datepicker";
import moment from "moment";
import "moment/locale/fr";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

import "react-datepicker/dist/react-datepicker.css";

const BootcampSessionForm = () => {
  const { t } = useTranslation("admin");
  const { get, post } = useFetch();
  const history = useHistory();
  const [companies, setCompanies] = useState([]);
  const [companyChoiceError, setCompanyChoiceError] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [startSessionDate, setStartSessionDate] = useState(new Date());
  const [endSessionDate, setEndSessionDate] = useState(new Date());
  const [notionUrl, setNotionUrl] = useState(null);

  const getBootcampCompanies = async () => {
    const response = await get("/admin/companies?is_bootcamp=true");
    console.log(response);
    setCompanies(response);
  };

  const checkForCompanyChoiceError = (event) => {
    const allCompaniesIds = companies.map((company) => Number(company.id));
    if (allCompaniesIds.includes(Number(event.target.value)))
      setCompanyChoiceError(false);
    else setCompanyChoiceError(true);
  };

  const handleCompanyChange = (e) => {
    checkForCompanyChoiceError(e);
    setSelectedCompany(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      company_id: selectedCompany,
      start_date: moment(startSessionDate).format("DD/MM/YYYY"),
      end_date: moment(endSessionDate).format("DD/MM/YYYY"),
      notion_url: notionUrl,
    };

    post(
      "/admin/bootcamp_sessions",
      data,
      history.push("/admin"),
      displaySuccess(t("bootcamp_session_created")),
      displayError(t("bootcamp_session_not_created"))
    );
  };

  useEffect(() => {
    getBootcampCompanies();
  }, []);

  return (
    <Form>
      <div>
        <StandardCompanyForm
          companies={companies}
          noLabel={false}
          handleChange={handleCompanyChange}
        />
        {selectedCompany && companyChoiceError && (
          <span className="text-danger small">
            {t("choose_a_valid_company")}
          </span>
        )}
      </div>
      <div className="my-4 text-center">
        <Row>
          <Col sm={12} md={6}>
            <p>{t("choose_session_start_date")}</p>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale="fr"
              selected={startSessionDate}
              onChange={(date) => setStartSessionDate(date)}
            />
          </Col>
          <Col sm={12} md={6}>
            <p>{t("choose_session_end_date")}</p>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              locale="fr"
              selected={endSessionDate}
              onChange={(date) => setEndSessionDate(date)}
            />
          </Col>
        </Row>
      </div>
      <Form.Label>{t("notion_url")}</Form.Label>
      <Form.Control
        type="text"
        placeholder={t("notion_url")}
        className="mb-3"
        onChange={(e) => setNotionUrl(e.target.value)}
      />

      {selectedCompany &&
        !companyChoiceError &&
        endSessionDate &&
        startSessionDate &&
        notionUrl && (
          <div className="text-center">
            <button
              className="btn btn-lg btn-warning text-white"
              onClick={handleSubmit}
            >
              {t("create_bootcamp_session")}
            </button>
          </div>
        )}
    </Form>
  );
};

export default BootcampSessionForm;
