import React from "react";
import ReactPlayer from "react-player";
import "../Lesson.scss";

const LessonVideo = ({ url }) => {
  return (
    <div className=" d-flex row w-100 justify-content-center mb-5 mt-3">
      <ReactPlayer
        url={url}
        width="75%"
        height="360px"
        controls
      />
    </div>
  );
};

export default LessonVideo;
