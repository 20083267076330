import { useTranslation } from "react-i18next";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import moment from "moment";
import { Form, Row } from "react-bootstrap";

const UsersConnexionsGraph = ({
  data,
  totalConnexions,
  dropdownConnexionsPeriod,
  selectedDropdownConnexionsPeriod,
  handleSelectConnexionsPeriod,
}) => {
  const { t, i18n } = useTranslation("common");

  moment.locale(i18n.language.split("-")[0] === "fr" ? "fr" : "en");

  return (
    <div className="mx-auto col-12">
      <Row>
        <p
          className="font-weight-bold"
          style={{ marginBottom: 0, marginTop: 10 }}
        >
          {t("connections")}{" "}
          <span className="small">
            ({totalConnexions} {t("on_total")})
          </span>
        </p>

        <Form.Control
          as="select"
          value={selectedDropdownConnexionsPeriod.value}
          onChange={handleSelectConnexionsPeriod}
          className="col-3 ml-auto"
        >
          {dropdownConnexionsPeriod.map((choice) => (
            <option key={choice.value} value={choice.value}>
              {choice.label}
            </option>
          ))}
        </Form.Control>
      </Row>
      <AreaChart
        data={data}
        width={1100}
        height={300}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          tickFormatter={(date) => moment(date).format("DD MMMM")}
        />
        <YAxis dataKey="connections" />
        <Tooltip
          labelFormatter={(date) => moment(date).format("DD MMMM")}
          formatter={(value) => [`${t("connection(s)")}: ${value}`]}
        />
        <Area
          type="monotone"
          dataKey="connections"
          stroke="#ff8a00"
          fill="#ff8a00"
        />
      </AreaChart>
    </div>
  );
};

export default UsersConnexionsGraph;
