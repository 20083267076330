import "./MentoringSessionCard.scss";
import { Card, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";

const MentoringSessionCard = ({ session }) => {
  const { t } = useTranslation("profile");
  const sessionDate = (
    <p>
      {moment(session.session_date).locale("fr").format("DD MMMM YYYY")} à{" "}
      {moment(session.session_date).locale("fr").format("HH") +
        " h " +
        moment(session.session_date).locale("fr").format("mm")}
    </p>
  );

  return (
    <Card className="MentoringSessionCard p-3 mx-auto">
      <Row>
        <p className="sessionLegendary font-weight-bold ml-2">
          {t("subject")} :
        </p>
        <p className="sessionInfos ml-1">{session.category.title}</p>
      </Row>
      <Row>
        <p className="sessionLegendary font-weight-bold ml-2">
          {t("mentor")} :
        </p>
        <p className="sessionInfos ml-1">
          {session.mentor.first_name} {session.mentor.last_name}
        </p>
      </Row>
      <Row>
        <p className="sessionLegendary font-weight-bold ml-2 mb-0">
          {t("date")} :
        </p>
        <p className="sessionInfos ml-2 mb-0">
          {session.session_date
            ? sessionDate
            : t("pending confirmation") + " ⏳"}
        </p>
      </Row>
    </Card>
  );
};

export default MentoringSessionCard;
