import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import AdminRoute from "components/AdminRoute";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import ManageApproval from "./components/ManageApproval/ManageApproval";
import ManageUsers from "./components/ManageUsers/ManageUsers";
import ManageLearningPath from "./components/ManageLearningPath/ManageLearningPath";
import ManageCategories from "./components/ManageCategories/ManageCategories";
import ManageCourses from "./components/ManageCourses/ManageCourses";
import ImportCourse from "./components/ManageCourses/ImportCourse";
import EditCourse from "./components/ManageCourses/EditCourse/EditCourse";
import ManageTeachers from "./components/ManageTeachers/ManageTeachers";
import AddPathSubscriptionForUser from "./components/AddPathSubscriptionForUser/AddPathSubscriptionForUser";
import CreateCompany from "./components/ManageCompany/CreateCompany/CreateCompany";
import UpdateCompanyUsers from "./components/ManageCompany/UpdateCompanyUsers/UpdateCompanyUsers";
import CreateNewDefinition from "./components/ManageGlossary/CreateNewDefinition";
import EditOrDeleteDefinition from "./components/ManageGlossary/EditOrDeleteDefinition";
import ShowAllMentoringSessions from "./components/ShowAllMentoringSessions/ShowAllMentoringSessions";
import ShowOneMentoringSession from "./components/ShowOneMentoringSession/ShowOneMentoringSession";
import AddMentorProfile from "./components/ManageMentorProfile/AddMentorProfile/AddMentorProfile";
import UpdateMentorProfile from "./components/ManageMentorProfile/UpdateMentorProfile/UpdateMentorProfile";
import DashboardUsers from "./components/DashbordUsers/DashboardUsers";
import SelectedUserDashboard from "./components/DashbordUsers/SelectedUserDashboard";
import ManageCompanyUserRequest from "./components/ManageCompany/ManageCompanyUserRequest/ManageCompanyUserRequest";
import DisplayFullCompanyUserRequestToAdmin from "./components/ManageCompany/ManageCompanyUserRequest/DisplayFullCompanyUserRequestToAdmin";
import AddArticle from "./components/ManageArticles/AddArticle";
import AllArticles from "./components/ManageArticles/AllArticles/AllArticles";
import TagsIndex from "./components/ManageTags/TagsIndex";
import AddArticleSubscription from "./components/AddArticleSubscription/AddArticleSubscription";
import UpdateArticle from "./components/ManageArticles/UpdateArticle";
import CreateBootcamp from "./components/ManageBootcamps/CreateBootcamp/CreateBootcamp";
import ChooseBootcampToManagePresences from "./components/ManageUserPresenceAtBootcamp/ChooseBootcampToManagePresences";
import SelectedBootcampUsers from "./components/ManageUserPresenceAtBootcamp/SelectedBootcampUsers";
import EditSelectedUserPresences from "./components/ManageUserPresenceAtBootcamp/EditSelectedUserPresences";
import GeneratePdfForSelectedUserInSelectedBootcamp from "./components/ManageUserPresenceAtBootcamp/GeneratePdfForSelectedUserInSelectedBootcamp";
import ChooseABootcampSessionToManage from "./components/ManageBootcamps/UpdateBootcamp/ChooseABootcampSessionToManage";
import ChooseACompanyToManage from "./components/ManageCompany/UpdateCompany/ChooseACompanyToManage";
import AddPathSubscriptionForCompanyOrBootcampUsers from "./components/AddPathSubscriptionForCompanyOrBootcampUsers/AddPathSubscriptionForCompanyOrBootcampUsers";
import SeeStatisticsForAdmin from "./components/SeeStatisticsForAdmin/SeeStatisticsForAdmin";
import SeeUsersCourses from "./components/SeeUsersCourses/SeeUsersCourses";
import SeeSpecificUserStatistics from "./components/SeeSpecificUserStatistics/SeeSpecificUserStatistics";

const Admin = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);

  return (
    <div className="Admin">
      <Row className="m-0 p-0">
        <Col md={3} lg={3} className="m-0 p-0">
          <Sidebar />
        </Col>
        <Col md={9} lg={9} className="m-0 p-0">
          <Container>
            <Switch>
              <AdminRoute
                currentUser={currentUser}
                component={SeeStatisticsForAdmin}
                path="/admin/statistics"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={SeeUsersCourses}
                path="/admin/specificuser"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={ManageApproval}
                path="/admin"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={ManageApproval}
                path="/admin/approvals"
              />
              <AdminRoute
                currentUser={currentUser}
                component={ManageUsers}
                path="/admin/users"
              />
              <AdminRoute
                currentUser={currentUser}
                component={ManageCategories}
                path="/admin/categories"
              />
              <AdminRoute
                currentUser={currentUser}
                component={ManageLearningPath}
                path="/admin/learning_paths"
              />
              <AdminRoute
                currentUser={currentUser}
                component={ManageCourses}
                path="/admin/courses"
              />
              <AdminRoute
                currentUser={currentUser}
                component={ImportCourse}
                path="/admin/import"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={EditCourse}
                path="/admin/import/edit"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={ManageTeachers}
                path="/admin/teachers"
              />
              <AdminRoute
                currentUser={currentUser}
                component={AddPathSubscriptionForUser}
                path="/admin/newpathsubscriptions"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={AddPathSubscriptionForCompanyOrBootcampUsers}
                path="/admin/newpathsubscriptionsforcompanyorbootcampusers"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={AddArticleSubscription}
                path="/admin/newarticlesubscriptions"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={CreateCompany}
                path="/admin/company/create"
              />
              <AdminRoute
                currentUser={currentUser}
                component={UpdateCompanyUsers}
                path="/admin/company/users/new"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={ManageCompanyUserRequest}
                path="/admin/company/usersrequests"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={DisplayFullCompanyUserRequestToAdmin}
                path="/admin/company/usersrequests/:id"
              />
              <AdminRoute
                currentUser={currentUser}
                component={CreateNewDefinition}
                path="/admin/glossary/new"
              />
              <AdminRoute
                currentUser={currentUser}
                component={EditOrDeleteDefinition}
                path="/admin/glossary/manage"
              />
              <AdminRoute
                currentUser={currentUser}
                component={ShowAllMentoringSessions}
                path="/admin/mentoringsessions"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={ShowOneMentoringSession}
                path="/admin/mentoringsessions/:id"
              />
              <AdminRoute
                currentUser={currentUser}
                component={AddMentorProfile}
                path="/admin/mentorprofile/new"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={UpdateMentorProfile}
                path="/admin/mentorprofile/update"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={DashboardUsers}
                path="/admin/dashboardusers"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={SelectedUserDashboard}
                path="/admin/displayuser/:id"
              />
              <AdminRoute
                currentUser={currentUser}
                component={AddArticle}
                path="/admin/articles/new"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={AllArticles}
                path="/admin/articles"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={UpdateArticle}
                path="/admin/articles/update"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={TagsIndex}
                path="/admin/tags"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={CreateBootcamp}
                path="/admin/bootcamps/new"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={ChooseBootcampToManagePresences}
                path="/admin/bootcamppresences"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={SelectedBootcampUsers}
                path="/admin/bootcamp/:id/users"
              />
              <AdminRoute
                currentUser={currentUser}
                component={EditSelectedUserPresences}
                path="/admin/bootcamp/:id/user/:id/presences"
              />
              <AdminRoute
                currentUser={currentUser}
                component={GeneratePdfForSelectedUserInSelectedBootcamp}
                path="/admin/bootcamp/:id/user/:id/pdf"
              />
              <AdminRoute
                currentUser={currentUser}
                component={ChooseABootcampSessionToManage}
                path="/admin/bootcamps/management"
                exact
              />
              <AdminRoute
                currentUser={currentUser}
                component={ChooseACompanyToManage}
                path="/admin/companies/management"
                exact
              />

              <AdminRoute
                currentUser={currentUser}
                component={SeeSpecificUserStatistics}
                path="/admin/seeuser/:id"
              />
            </Switch>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default Admin;
