import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";
import noavatar from "assets/noavatar.jpg";
import { useTranslation } from "react-i18next";

const UserLine = ({ user }) => {
  const { t } = useTranslation("company");

  return (
    <tr key={user.id}>
      <td style={{ padding: 5 }} className="align-middle">
        {user.picture_url ? (
          <Image
            src={user.picture_url}
            alt="Real avatar"
            className="user-avatar-navbar mx-4"
            style={{
              height: "70px",
              width: "70px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
            roundedCircle
          />
        ) : (
          <Image
            src={noavatar}
            alt="Real avatar"
            className="user-avatar-navbar mx-4"
            style={{
              height: "70px",
              width: "70px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
            roundedCircle
          />
        )}
      </td>
      <td className="align-middle">{user.first_name}</td>
      <td className="align-middle">{user.last_name}</td>
      <td className="text-center align-middle">
        <Link to={`/company/user/${user.id}`} params={{ id: user.id }}>
          <Button
            style={{
              backgroundColor: "#ff8a00",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          >
            {t("see_user")}
          </Button>
        </Link>
      </td>
    </tr>
  );
};

export default UserLine;
