import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { Button, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const TagsIndex = () => {
  const { t } = useTranslation("admin");
  const { get, post, destroy } = useFetch();
  const [allTags, setAllTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [toReload, setToReload] = useState(false);
  const dispatch = useDispatch();

  const reload = () => setToReload(!toReload);

  const getAllTags = async () => {
    const response = await get("/admin/tags");
    setAllTags(response);
  };

  const handleNewTagClick = async (e) => {
    e.preventDefault();
    await post(
      "/admin/tags",
      { name: newTag },
      reload,
      dispatch(displaySuccess(t("tag_successfully_created")))
    );
    setNewTag("");
  };

  const handleDeleteTag = async (tagId) => {
    await destroy(`/admin/tags/${tagId}`, reload);
  };

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    getAllTags();
  }, [toReload]);

  console.log(allTags);

  return (
    <div>
      <h3 className="text-center">{t("manage_tags")}</h3>
      <div className="d-flex w-100 align-items-center">
        <Form
          className="row w-100 my-4 justify-content-center"
          onSubmit={(e) => handleNewTagClick(e)}
        >
          <Form.Control
            className="w-50"
            placeholder={t("new_tag_name")}
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
          />
          <Button
            type="button"
            className="btn btn-warning text-white w-25 ml-3"
            onClick={(e) => handleNewTagClick(e)}
          >
            {t("send")}
          </Button>
        </Form>
      </div>
      <div className="text-center w-100">
        <h5 className="text-center">{t("existing_tags")}</h5>
        {allTags.map((tag) => (
          <span
            key={tag.id}
            className="badge my-2 mx-1"
            style={{
              fontSize: "0.8rem",
              borderRadius: 0,
              backgroundColor: "rgba(255, 255, 255, 0.5)",
            }}
          >
            {tag.name}{" "}
            <span
              onClick={(e) => handleDeleteTag(tag.id)}
              className="text-danger ml-1"
              style={{ cursor: "pointer", fontSize: "0.6rem", position: "relative", top:"-1px" }}
            >
              ❌
            </span>
          </span>
        ))}
      </div>
    </div>
  );
};

export default TagsIndex;
