export const calculateWeekBeetweenTwoDates = (date1, date2) => {
  return Math.ceil((date2 - date1) / (1000 * 60 * 60 * 24 * 7));
};

export const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const filterAlreadyCompletedBootcampPresencesByMomentAndSortThem = (
  presences
) => {
  const morningPresences = presences
    .filter((presence) => presence.morning === true)
    .sort((a, b) => new Date(a.date) - new Date(b.date));
  const afternoonPresences = presences
    .filter((presence) => presence.morning !== true)
    .sort((a, b) => new Date(a.date) - new Date(b.date));
  return { morningPresences, afternoonPresences };
};

export const compareExistingPresencesWithValidDates = (
  existingPresencesDates,
  validDates,
  arraytoFill
) => {
  existingPresencesDates.forEach((presence) => {
    validDates.map((week, index) => {
      week.forEach((day, i) => {
        if (day.toString() === new Date(presence.date).toString()) {
          arraytoFill[index][i] = true;
        }
      });
    });
  });
  return arraytoFill;
};

export const createArrayWithFalseValuesDependingOnValidDates = (
  validDatesOnWeek
) => {
  const newFilledArray = validDatesOnWeek.map((array) => {
    return array.map((_) => false);
  });

  return newFilledArray;
};

export const divideAllValidDatesIntoWeeks = (dates, stateToUpdate) => {
  let weeks = [];
  let week = [];
  for (let i = 0; i < dates.length; i++) {
    week.push(dates[i]);
    if (week.length === 5) {
      weeks.push(week);
      week = [];
    }
  }
  stateToUpdate(weeks);
};

export const excludeWeekEndDates = (date1, date2, stateToUpdate) => {
  let dates = [];
  let currentDate = date1;
  while (currentDate <= date2) {
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      dates.push(currentDate);
    }
    currentDate = new Date(currentDate.getTime() + 1000 * 60 * 60 * 24);
  }
  divideAllValidDatesIntoWeeks(dates, stateToUpdate);
};