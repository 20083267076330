import { useState } from "react";
import {  Form } from "react-bootstrap";
import useFetch from "hooks/useFetch";

const CheckboxLearningPaths = ( { category,  title, mentor } ) => {

  const [isChecked, setIsChecked] = useState(mentor.categories.some(c => c.id === category.id));
  const {  post, destroy } = useFetch();

  const handleChange = () => {
    if(!isChecked){
      post(
        "/admin/mentor_categories",
        {
          mentor_id: mentor.id,
          category_id: category.id,
        },
      );
    }
    if(isChecked){
      const id = mentor.mentor_categories.find(c => c.category_id === category.id ).id;
      destroy(`/admin/mentor_categories/${id}`);
    }
    setIsChecked(!isChecked);
  };

return (
  <div className='CheckboxLearningPaths'>
    <Form.Check 
      type="checkbox"
      id={category.id}
      label={title}
      checked={isChecked}
      onChange={handleChange}
    />
  </div>
);
};
  
export default CheckboxLearningPaths;
