import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";

const NewLearningPath = ({ handleEdit }) => {
  const [creating, setCreating] = useState(false);
  const { get, data, post } = useFetch();
  const [selectedCourses, setselectedCourses] = useState([]);
  const [title, setTitle] = useState();
  const [priceInCents, setPriceInCents] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [largeImageUrl, setLargeImageUrl] = useState("");
  const [checked, setChecked] = useState(false);
  const [publicPath, setPublicPath] = useState(true);
  const [frenchCourses, setFrenchCourses] = useState([]);
  const [englishCourses, setEnglishCourses] = useState([]);
  const [russianCourses, setRussianCourses] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("fr");
  const { t } = useTranslation("admin");
  const languagesSelectionOptions = [
    { label: t("admin:french"), value: "fr" },
    { label: t("admin:english"), value: "en" },
    { label: t("admin:russian"), value: "ru" },
  ];

  const handleClick = () => {
    if (!creating) {
      setCreating(true);
    } else {
      post(
        "/admin/learning_paths",
        {
          selected_courses_ids: selectedCourses.join(),
          title: title,
          price_in_cents: priceInCents,
          image_url: imageUrl,
          public: publicPath,
          large_image_url: largeImageUrl
        },
        handleEdit
      );
      setCreating(false);
    }
  };

  const handleCheckboxesCourses = (event) => {
    if (event.target.checked) {
      setselectedCourses([...selectedCourses, event.target.value]);
    } else {
      setselectedCourses(
        selectedCourses.filter((course) => course !== event.target.value)
      );
    }
  };

  const setLanguagesCoursesState = (data) => {
    let fr, en, ru;
    [fr, en, ru] = data;
    setFrenchCourses(Object.values(fr));
    setEnglishCourses(Object.values(en));
    setRussianCourses(Object.values(ru));
  };

  useEffect(() => {
    get("/courses");
    data ? setLanguagesCoursesState(data) : null;
    return;
  }, [creating, setSelectedLanguage]);

  return (
    <div className="NewLearningPath">
      {creating && (
        <>
          <Form>
            <Row>
              <Col>
                <Form.Label>{t("title")}</Form.Label>
                <Form.Control
                  placeholder={t("title")}
                  onChange={() => setTitle(event.target.value)}
                />
              </Col>
              <Col>
                <Form.Label>{t("price_in_cents")}</Form.Label>
                <Form.Control
                  type="number"
                  pattern="[0-9]"
                  placeholder={t("price_in_cents")}
                  onChange={() => setPriceInCents(event.target.value)}
                />
              </Col>
            </Row>
            <Form.Label className="mt-4 mr-3">
              {t("admin:create_with_one_or_many")} :
            </Form.Label>
            <Form.Check
              inline
              type="checkbox"
              onChange={() => setChecked(!checked)}
            />
            <Col className="p-0 mt-2">
              <Form.Label>{t("admin:add_image")}</Form.Label>
              <Form.Control
                placeholder={t("admin:add_image")}
                onChange={(event) => setImageUrl(event.target.value)}
                disabled={!checked}
              />
            </Col>
            
            <Col className="p-0 mt-2">
              <Form.Label>{t("admin:add_large_image")}</Form.Label>
              <Form.Control
                placeholder={t("admin:add_large_image")}
                onChange={(event) => setLargeImageUrl(event.target.value)}
              />
            </Col>
            <Form.Label className="mt-3">{t("add_courses")} :</Form.Label>
            <Form.Group controlId="SelectedLanguage">
              <Form.Control
                as="select"
                value={selectedLanguage}
                onChange={(event) => setSelectedLanguage(event.target.value)}
              >
                {languagesSelectionOptions.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <div key={"inline-checkbox"} className="mb-3">
              {(selectedLanguage === "fr" &&
                frenchCourses &&
                frenchCourses.map((language) =>
                  language.map((course) => (
                    <Form.Check
                      key={course.id}
                      inline
                      label={course.title}
                      type="checkbox"
                      value={course.id}
                      onChange={handleCheckboxesCourses}
                    />
                  ))
                )) ||
                (selectedLanguage === "en" &&
                  englishCourses &&
                  englishCourses.map((language) =>
                    language.map((course) => (
                      <Form.Check
                        key={course.id}
                        inline
                        label={course.title}
                        type="checkbox"
                        value={course.id}
                        onChange={handleCheckboxesCourses}
                      />
                    ))
                  )) ||
                (selectedLanguage === "ru" &&
                  russianCourses &&
                  russianCourses.map((language) =>
                    language.map((course) => (
                      <Form.Check
                        key={course.id}
                        inline
                        label={course.title}
                        type="checkbox"
                        value={course.id}
                        onChange={handleCheckboxesCourses}
                      />
                    ))
                  ))}
            </div>
            <Form.Label className="font-weight-bold mr-3">
              {t("admin:public_or_private")} :
            </Form.Label>
            <Form.Check
              inline
              type="checkbox"
              onChange={() => setPublicPath(!publicPath)}
            />
          </Form>
        </>
      )}
      <div className="mb-4">
        {
          <button
            type="button"
            className="validation-btn btn btn-primary btn-lg"
            onClick={handleClick}
          >
            {(!creating && t("create_learning_path")) || t("validate_creation")}
          </button>
        }
        {creating && (
          <button
            type="button"
            className="cancel-btn btn btn-sm btn-danger"
            onClick={() => setCreating(false)}
          >
            {t("cancel_creation")}
          </button>
        )}
      </div>
    </div>
  );
};

export default NewLearningPath;
