import { Col, Row, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const RequestForm = ({setFirstName, setLastName, setEmail, handleSubmit, request }) => {

  const { t } = useTranslation("company");
  
  return (
    <Form className="my-5" onSubmit={handleSubmit}>
      <Row>
        <Col sm={12} lg={6}>
          <div>
            <Form.Group>
              <Form.Label>{t("employee_first_name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("employee_first_name")}
                defaultValue={request ? request.first_name : ""}
                onChange={(e) => setFirstName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </div>
        </Col>
        <Col sm={12} lg={6}>
          <div>
            <Form.Group>
              <Form.Label>{t("employee_last_name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("employee_last_name")}
                defaultValue={request ? request.last_name : ""}
                onChange={(e) => setLastName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={12} lg={12}>
          <Form.Group>
            <Form.Label>{t("employee_email")}</Form.Label>
            <Form.Control
              type="email"
              placeholder={t("employee_email")}
              defaultValue={request ? request.email : ""}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
      </Row>
      <div className="d-flex row justify-content-center">
        <Button
          type="submit"
          className="ButtonPrimary w-50 mt-3"
          onClick={handleSubmit}
        >
          {t("create_a_user_request")}
        </Button>
      </div>
    </Form>
  );
};

export default RequestForm;
