import { useEffect } from "react";
import { Col, Row, Accordion, ListGroup } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import "./ChapterProgressBar.scss";
import { useSelector, useDispatch } from "react-redux";
import { fetchCurrentUser } from "stores/authentication/authMiddleware";
import useFetch from "hooks/useFetch";
import { Link } from "react-router-dom";
import { capitalize } from "tools/capitalize";
import { AiOutlineArrowRight, AiFillCloseCircle } from "react-icons/ai";

const ChapterProgressBar = ({
  currentLesson,
  handleClose,
  handleProgressSideBar,
  isOpen,
  isMobile,
}) => {
  const token = useSelector((state) => state.auth.token);
  const { data, get } = useFetch();
  const dispatch = useDispatch();

  useEffect(() => {
    get(`/courses/${currentLesson.chapter.course_id}`);
  }, [currentLesson]);

  return (
    <Accordion
      style={{ backgroundColor: "#ffffff" }}
      className={`${
        isOpen ? "accordion" : "d-flex column align-items-center mt-5"
      }`}
    >
      <Accordion.Toggle
        eventKey="0"
        className="mx-auto btn-sidebar"
        onClick={() => handleProgressSideBar()}
      >
        {isOpen ? <AiFillCloseCircle /> : <AiOutlineArrowRight />}
      </Accordion.Toggle>
      {!isOpen && data && !isMobile && (
        <div className="mx-auto" style={{ minHeight: "100%" }}>
          {data.chapters.map((chapter) => (
            <>
              <Row>
                <Col auto style={{ maxWidth: "70px" }} className="ml-2">
                  <AiFillCheckCircle
                    className={
                      chapter.id === currentLesson.chapter.id
                        ? "mx-auto active-item"
                        : "mx-auto inactive-item"
                    }
                    size={40}
                  />{" "}
                  <div className="title-line mx-3"></div>
                </Col>
              </Row>
              <ListGroup key={chapter.title} className="mb-3">
                {chapter.lessons.map((lesson) => (
                  <ListGroup.Item
                    key={lesson.id}
                    className="lessonItem pl-2 py-0"
                  >
                    <Row>
                      <Col auto style={{ maxWidth: "70px" }}>
                        <AiFillCheckCircle
                          className={
                            lesson.id === currentLesson.id
                              ? "mx-1 active-item"
                              : "mx-1 inactive-item"
                          }
                          size={29}
                        />{" "}
                        {lesson.position !== chapter.lessons.length && (
                          <div className="line mx-3"></div>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </>
          ))}
        </div>
      )}
      <Accordion.Collapse eventKey="0" id="side-progression">
        <Row className="ChapterProgressBar d-flex justify-content-center mb-5 mt-2 h-100">
          {data && (
            <section className={isOpen ? "d-block" : "d-none"}>
              <h4 className="mx-2 mb-3 font-weight-bold">{data.title}</h4>
              {data.chapters.map((chapter) => (
                <>
                  <Row>
                    <Col auto style={{ maxWidth: "70px" }} className="ml-2">
                      <AiFillCheckCircle
                        className={
                          chapter.id === currentLesson.chapter.id
                            ? "mx-auto active-item"
                            : "mx-auto inactive-item"
                        }
                        size={40}
                      />{" "}
                      <div className="title-line mx-3"></div>
                    </Col>
                    <Col auto>
                      <h5 className="ml-1 mb-3 font-weight-bold mt-1 chapter-title">
                        {capitalize(chapter.title)}
                      </h5>
                    </Col>
                  </Row>
                  <ListGroup key={chapter.title} className="mb-3">
                    {chapter.lessons.map((lesson) => (
                      <ListGroup.Item
                        key={lesson.id}
                        className="lessonItem pl-2 py-0"
                      >
                        <Row>
                          <Col auto style={{ maxWidth: "70px" }}>
                            <AiFillCheckCircle
                              className={
                                lesson.id === currentLesson.id
                                  ? "mx-1 active-item"
                                  : "mx-1 inactive-item"
                              }
                              size={29}
                            />{" "}
                            {lesson.position !== chapter.lessons.length && (
                              <div className="line mx-3"></div>
                            )}
                          </Col>
                          <Col auto>
                            <Link
                              onClick={handleClose}
                              className="align-self-center"
                              to={`/courses/${currentLesson.chapter.course_id}/chapters/${chapter.id}/lessons/${lesson.id}`}
                            >
                              {capitalize(lesson.title)}
                            </Link>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </>
              ))}
            </section>
          )}
        </Row>
      </Accordion.Collapse>
    </Accordion>
  );
};

export default ChapterProgressBar;
