import useFetch from "hooks/useFetch";
import { AiFillCloseCircle } from "react-icons/ai";

const MentorExperienceLine = ({ experience, reload }) => {
  const { destroy } = useFetch();

  const handleDelete = (id) => {
    destroy(`/admin/mentor_experiences/${id}`, reload);
  };

  return (
    <tr>
      <td>{experience.society_name}</td>
      <td>{experience.job}</td>
      <td className="text-center">
        <AiFillCloseCircle
          size={30}
          style={{ color: "red" }}
          onClick={() => handleDelete(experience.id)}
        />
      </td>
    </tr>
  );
};

export default MentorExperienceLine;
