import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import Logo from "../../assets/arseniologo.png";
import moment from "moment";
import "moment/locale/fr";
import Html from "react-pdf-html";
import ReactDOMServer from "react-dom/server";
import { useEffect } from "react";
import {
  calculateWeekBeetweenTwoDates,
  excludeWeekEndDates,
  insert,
  createArrayWithFalseValuesDependingOnValidDates,
  filterAlreadyCompletedBootcampPresencesByMomentAndSortThem,
  compareExistingPresencesWithValidDates,
} from "helpers/presenceTableHelpers";
import { useTranslation } from "react-i18next";
import SignatureKarim from "assets/signature_karim.jpeg";
import LogoPoleEmploi from "assets/logo_pole_emploi.jpg";
import TamponArsenio from "assets/tampon_arsenio.jpeg";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    alignItems: "center",
  },
  logo: {
    position: "absolute",
    left: 20,
    top: 25,
    width: 50,
    height: 50,
  },
  logoPoleEmploi: {
    position: "absolute",
    right: 20,
    top: 15,
    width: 80,
    height: 80,
  },
  titleSection: {
    alignItems: "center",
    border: "1px solid black",
    margin: 20,
    padding: 10,
    width: "70%",
  },
  userInformationsView: {
    flexDirection: "row",
    border: "1px solid black",
    padding: 10,
    width: "95%",
    justifyContent: "space-between",
  },
  userInformationsText: {
    marginLeft: 10,
    fontSize: 14,
  },
  formationNameView: {
    flexDirection: "row",
    margin: 10,
    width: "95%",
    justifyContent: "space-between",
  },
  formationNameText: {
    fontSize: 14,
  },
  tableCase: {
    borderWidth: 1,
    borderStyle: "solid",
  },
  bodyTableCase: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "black",
    justifyContent: "center",
    alignItems: "center",
  },
  signaturePicture: {
    width: 62,
    height: 35,
  },
  footerView: {
    flexDirection: "column",
    alignSelf: "flex-end",
    marginTop: 20,
    marginRight: 40,
  },
  footerText: {
    fontSize: 14,
    marginTop: 2,
  },
  serviceText: {
    fontSize: 10,
  },
});

const PdfComponent = ({
  firstName,
  lastName,
  bootcampSession,
  bootcampStartDate,
  bootcampEndDate,
  formationName,
  user,
  checkedStateForMorning,
  setCheckedStateForMorning,
  checkedStateForAfternoon,
  setCheckedStateForAfternoon,
  notFilteredExistantPresences,
  numberOfWeeks,
  setNumberOfWeeks,
  validDatesOnWeek,
  setValidDatesOnWeek,
  getAlreadyCompletedBootcampPresencesForSelectedUser,
  signature,
}) => {
  const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];
  const { t } = useTranslation("admin");

  const TableHeader = () => {
    const columns = [];
    for (let i = 0; i <= 4; i++) {
      columns.push(days[i]);
    }

    const finalColumns = insert(columns, 0, " ");

    return (
      <thead style={{ textAlign: "center" }}>
        <tr>
          {finalColumns.map((day, index) => {
            if (index === 0) {
              return <th key={index}>{day}</th>;
            }
            return (
              <th
                style={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  display: "flex",
                  flexDirection: "column",
                }}
                key={index}
              >
                <div>{day}</div>
                <div style={{ flexDirection: "row" }}>
                  <div
                    style={{
                      width: "50%",
                      borderWidth: 0,
                      borderStyle: "dashed",
                      borderRightWidth: 1,
                      fontSize: 10,
                      marginTop: 5,
                    }}
                  >
                    Matin
                  </div>
                  <div style={{ width: "50%", fontSize: 11.5, marginTop: 5 }}>
                    Après-midi
                  </div>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const TableBody = () => {
    const weeks = [];

    const columns = [];
    for (let i = 1; i <= days.length; i++) {
      columns.push(`Semaine ${i}`);
    }

    for (let i = 0; i < numberOfWeeks; i++) {
      weeks.push({
        title: `Semaine ${i + 1}`,
        subTitle: `${moment(validDatesOnWeek[i][0]).format(
          "DD/MM/YYYY"
        )} - ${moment(
          validDatesOnWeek[i][validDatesOnWeek[i].length - 1]
        ).format("DD/MM/YYYY")}`,
      });
    }

    const finalColumns = insert(columns, 0, " ");
    return (
      <tbody>
        {weeks.map((week, i) => (
          <tr key={i} style={{ minHeight: 35 }}>
            <td style={styles.bodyTableCase}>
              <div style={{ fontSize: 14 }}>{week.title}</div>
              <div style={{ fontSize: 10 }}>{week.subTitle}</div>
            </td>
            {finalColumns.map((column, index) => {
              if (index === 0) return null;
              return (
                <th
                  scope="row"
                  key={index}
                  style={{ justifyContent: "center" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        borderWidth: 0,
                        borderStyle: "dashed",
                        borderRightWidth: 1,
                        alignItems: "center",
                      }}
                    >
                      {checkedStateForMorning[i][index - 1] ? (
                        <img
                          src={user.encoded_signature_picture}
                          style={styles.signaturePicture}
                        />
                      ) : (
                        <div style={{ fontSize: 14 }}>Absent</div>
                      )}
                    </div>
                    <div
                      style={{
                        width: "50%",
                        alignItems: "center",
                      }}
                    >
                      {checkedStateForAfternoon[i][index - 1] ? (
                        <img
                          src={user.encoded_signature_picture}
                          style={styles.signaturePicture}
                        />
                      ) : (
                        <div style={{ fontSize: 14 }}>Absent</div>
                      )}
                    </div>
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </tbody>
    );
  };

  const Table = () => {
    return (
      <div style={{ marginLeft: 20, marginRight: 20 }}>
        {user &&
        user.encoded_signature_picture &&
        checkedStateForMorning &&
        checkedStateForAfternoon ? (
          <table
            style={{
              marginTop: 10,
              marginRight: 5,
              marginLeft: 5,
              border: "1px solid black",
            }}
          >
            <TableHeader />
            <TableBody />
          </table>
        ) : (
          <p style={{ fontSize: 14 }}>{t("any_signature_generated_yet")} </p>
        )}
      </div>
    );
  };

  const table = ReactDOMServer.renderToStaticMarkup(<Table />);

  useEffect(() => {
    if (bootcampStartDate && bootcampEndDate) {
      setNumberOfWeeks(
        calculateWeekBeetweenTwoDates(
          new Date(bootcampStartDate),
          new Date(bootcampEndDate)
        )
      );
      excludeWeekEndDates(
        new Date(bootcampStartDate),
        new Date(bootcampEndDate),
        setValidDatesOnWeek
      );
      getAlreadyCompletedBootcampPresencesForSelectedUser();
    }
  }, []);

  useEffect(() => {
    if (notFilteredExistantPresences) {
      const { morningPresences, afternoonPresences } =
        filterAlreadyCompletedBootcampPresencesByMomentAndSortThem(
          notFilteredExistantPresences
        );

      const morningPresencesFilled =
        createArrayWithFalseValuesDependingOnValidDates(validDatesOnWeek);

      const afternoonPresencesFilled =
        createArrayWithFalseValuesDependingOnValidDates(validDatesOnWeek);
      const updateCheckedStateForMorning =
        compareExistingPresencesWithValidDates(
          morningPresences,
          validDatesOnWeek,
          morningPresencesFilled
        );
      const updateCheckedStateForAfternoon =
        compareExistingPresencesWithValidDates(
          afternoonPresences,
          validDatesOnWeek,
          afternoonPresencesFilled
        );

      setCheckedStateForMorning(updateCheckedStateForMorning);
      setCheckedStateForAfternoon(updateCheckedStateForAfternoon);
    }
  }, [notFilteredExistantPresences]);

  useEffect(() => {
    if (validDatesOnWeek) {
      const newCheckedState =
        createArrayWithFalseValuesDependingOnValidDates(validDatesOnWeek);

      setCheckedStateForMorning(newCheckedState);
      setCheckedStateForAfternoon(newCheckedState);
    }
  }, [validDatesOnWeek]);

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <Image src={Logo} style={styles.logo} />
        <View>
          <Text style={styles.serviceText}>Service Régional de Contrôle de la Formation Professionnelle</Text>
        </View>
        <View style={styles.titleSection}>
          <Text>Emargement Individuel</Text>
        </View>
        <Image src={LogoPoleEmploi} style={styles.logoPoleEmploi} />
        <View style={styles.userInformationsView}>
          <Text
            style={styles.userInformationsText}
            render={() => `Nom: ${lastName} `}
            fixed
          />
          <Text
            style={styles.userInformationsText}
            render={() => `Prénom: ${firstName}`}
            fixed
          />
          <Text
            style={styles.userInformationsText}
            render={() =>
              `Date de formation du : ${moment(bootcampStartDate)
                .locale("fr")
                .format("DD MMMM YYYY")} au ${moment(bootcampEndDate)
                .locale("fr")
                .format("DD MMMM YYYY")}`
            }
            fixed
          />
        </View>
        <View style={styles.formationNameView}>
          <Text
            style={styles.formationNameText}
            render={() => `Formation suivie: ${formationName}`}
          />
        </View>
        <View style={{ width: "100%" }}>
          <Html>{table}</Html>
        </View>
        <View style={styles.footerView}>
          <Text style={styles.footerText}>
            Certifié exact par l&apos;organisme
          </Text>
          <Text style={styles.footerText}>Fait à Paris</Text>
          <Text style={styles.footerText}>
            Le {moment(new Date()).locale("fr").format("DD MMMM YYYY")}
          </Text>
          <Text style={styles.footerText}>Par Monsieur Fathi Karim</Text>
          <Text style={styles.footerText}>Signature:</Text>
          <View>
            <Image src={SignatureKarim} style={{ width: 200, height: 100 }} />
          </View>
          <View>
            <Image src={TamponArsenio} style={{ width: 200, height: 100 }} />
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default PdfComponent;
