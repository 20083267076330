import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import ArticleLine from "./components/ArticleLine";

const AllArticles = () => {
  const { t } = useTranslation("admin");
  const { get } = useFetch();
  const [articles, setArticles] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [toReload, setToReload] = useState(false);

  const reload = () => setToReload(!toReload);

  const getAllArticles = async () => {
    const response = await get("/admin/articles");
    setArticles(response);
  };

  const getAllTags = async () => {
    const response = await get("/admin/tags");
    setAllTags(response);
  };

  useEffect(() => {
    getAllArticles();
    getAllTags();
  }, []);

  useEffect(() => {
    getAllArticles();
    getAllTags();
  }, [toReload]);

  return (
    <div>
      <h3 className="text-center mb-4">{t("manage_articles")}</h3>
      {articles && articles.length > 0 ?(
        <section>
          <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>{t("title")}</th>
              <th>{t("manage_tag_for_article")}</th>
              <th>{t("delete")}</th>
            </tr>
          </thead>
          <tbody>
            {articles.map((article) => (
              <ArticleLine key={article.id} article={article} reload={reload} tags={allTags} />
            ))}
          </tbody>
        </Table>
      </section>
      ) : <p className="text-center">{t("no_article_yet")} </p>}
    </div>
  );
};

export default AllArticles;
