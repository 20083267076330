import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import { useTranslation } from "react-i18next";

const MentoringSessionLine = ({ session }) => {
  const { t } = useTranslation("admin");

  return (
    <tr key={session.id}>
      <td>
        {session.student.first_name} {session.student.last_name}
      </td>
      <td>
        {session.mentor.first_name} {session.mentor.last_name}
      </td>
      <td>
        {session.session_date && session.session_date !== null && (
          moment(session.session_date).locale("fr").format("DD MMMM YYYY") + " à " +
          moment(session.session_date).locale("fr").format("HH") +
            " h " +
            moment(session.session_date).locale("fr").format("mm")
        ) || <p className="m-0">{t("not_session_date_yet")}</p>}
      </td>
      <td className="text-center">
        <Link to={`/admin/mentoringsessions/${session.id}`}>
          <AiFillEye size={20} />
        </Link>
      </td>
    </tr>
  );
};

export default MentoringSessionLine;
