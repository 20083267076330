import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import LearningPathForm from "./LearningPathForm";
import UserForm from "./UserForm";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const AddPathSubscriptionForUser = () => {
  const { error, post } = useFetch();
  const { t } = useTranslation("admin");
  const [user, setUser] = useState("");
  const [learningPath, setLearningPath] = useState("");
  const history = useHistory();

  const createSubscription = (user, learningPath) => {
    const data = { user_id: user, learning_path_id: learningPath };
    post(
      "/admin/subscriptions",
      data,
      history.push("/admin"),
      displaySuccess(t("admin:subscription_done")),
      displayError(t("admin:subscription_failed"))
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    createSubscription(user, learningPath);
  };

  return (
    <div>
      {error && <h4>{error}</h4>}
      <h3 className="d-flex row justify-content-center my-3">
        {t("admin:add_path_subscription")}
      </h3>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} lg={6}>
            <LearningPathForm
              handleChange={(e) => setLearningPath(e.target.value)}
            />
          </Col>
          <Col sm={12} lg={6}>
            <UserForm handleChange={(e) => setUser(e.target.value)} noLabel={false} />
          </Col>
        </Row>
        <div className="d-flex row justify-content-center">
          <Button
            type="submit"
            className="ButtonPrimary w-50"
            onClick={handleSubmit}
          >
            {t("admin:user_subscription")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddPathSubscriptionForUser;
