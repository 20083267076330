import { AiFillCloseCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DeletionValidationModal from "components/DeletionValidationModal";
import useFetch from "hooks/useFetch";
import AddTagToArticle from "../../AddTagToArticle/AddTagToArticle";

const ArticleLine = ({ article, reload, tags }) => {
  const { t } = useTranslation("admin");
  const { destroy } = useFetch();
  const [show, setShow] = useState(false);
  const [validation, setValidation] = useState(false);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteArticle = async (articleId) => {
    destroy(`/admin/articles/${articleId}`, reload);
  };

  const handleClickOnDeleteArticleButton = (articleId) => {
    setSelectedArticleId(articleId);
    handleShow();
  };

  useEffect(() => {
    validation === true && deleteArticle(selectedArticleId);
  }, [validation]);

  return (
    <>
      <tr>
        <td>{article.id}</td>
        <td>{article.title}</td>
        <td>
          <AddTagToArticle articleId={article.id} tags={tags} reload={reload} />
        </td>
        <td className="text-center">
          <AiFillCloseCircle
            size={30}
            style={{ color: "red" }}
            onClick={() => handleClickOnDeleteArticleButton(article.id)}
          />{" "}
        </td>
      </tr>
      <DeletionValidationModal
        translator={t}
        handleClose={handleClose}
        show={show}
        setValidation={setValidation}
        validationPhrase="article_deletion_confirmation"
      />
    </>
  );
};

export default ArticleLine;
