import { AiFillCloseCircle, AiOutlineEdit } from "react-icons/ai";
import useFetch from "hooks/useFetch";
import { useState } from "react";

const DefinitionLine = ({ definition, reload, handleShow }) => {
  const { destroy } = useFetch();

  const handleDelete = (id) => {
    destroy(`/admin/glossaries/${id}`, reload);
  };

  return (

      <tr key={definition.id}>
        <td>{definition.title}</td>
        <td>{definition.definition}</td>
        <td>{definition.language.name}</td>
        <td>{definition.definition_type}</td>
        <td className="text-center">
          <AiOutlineEdit
            size={30}
            onClick={() => handleShow(definition)}
            style={{ color: "orange" }}
          />
        </td>
        <td className="text-center">
          <AiFillCloseCircle
            size={30}
            style={{ color: "red" }}
            onClick={() => handleDelete(definition.id)}
          />
        </td>
      </tr>
  );
};

export default DefinitionLine;
