export const verifyIfUserAccountLimitDateAreExpired = (currentUser, t, dispatch, displayWarning, history) => {
  if (currentUser?.account_limit_validity_date) {
    const currentDate = new Date();
    const accountLimitDate = new Date(
      currentUser.account_limit_validity_date
    );
    if (currentDate > accountLimitDate) {
      history.push("/profile/dashboard");
      dispatch(displayWarning(t("account_limit_date_are_expired")));
    }
  }
  else {
    history.push("/profile/dashboard");
    dispatch(displayWarning(t("account_limit_date_are_expired")));
  }
};
