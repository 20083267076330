import useFetch from "hooks/useFetch";
import UserLine from "./components/UserLine.jsx";
import { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Container, Table, Pagination, Form, Row } from "react-bootstrap";
import debounce from "lodash/debounce";
import "./SeeUsersCourses.scss";

const SeeUsersCourses = () => {
  const { t } = useTranslation();
  const { error, isLoading, get } = useFetch();
  const [users, setUsers] = useState([]);
  const [actualPage, setActualPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [userSearch, setUserSearch] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [selectedBootcamp, setSelectedBootcamp] = useState(null);
  const [existingsBootcamps, setExistingBootcamps] = useState([]);
  const [selectedChoosenMod, setSelectedChoosenMod] = useState({
    label: t("admin:general"),
    value: "general",
  });

  const dropdownChoosenMod = [
    { label: t("admin:general"), value: "general" },
    { label: t("admin:by_bootcamp"), value: "bootcamp" },
  ];

  const searchInputRef = useRef(null);

  const getUsers = async () => {
    let response;
    if (selectedChoosenMod.value === "bootcamp") {
      response = await get(
        `/admin/users?page=${actualPage}&per_page=10&for_statistics=true&bootcamp_id=${selectedBootcamp}`
      );
    } else {
      response = await get(
        `/admin/users?page=${actualPage}&per_page=10&for_statistics=true`
      );
    }

    if (!response.total_pages) {
      setUsers([]);
      return;
    }

    setTotalPages(response.total_pages);
    setUsers(response.users);
  };

  const getBootcamps = async () => {
    const response = await get("/admin/companies?is_bootcamp=true");
    setSelectedBootcamp(response[0].id);
    setExistingBootcamps(response);
  };

  const getASpecificUser = async (name) => {
    const response = await get(
      `/admin/users?name=${name}&for_statistics=true${
        selectedChoosenMod.value === "bootcamp"
          ? `&bootcamp_id=${selectedBootcamp}`
          : ""
      }`
    );
    if (response.user === null) {
      setNotFound(true);
      return;
    }
    setUsers([response.user]);
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleModChoice = (event) => {
    const value = event.target.value;
    const choice = dropdownChoosenMod.find((d) => d.value === value);
    setSelectedChoosenMod(choice);
  };

  const handleCompanyChoice = (event) => {
    const value = event.target.value;
    setSelectedBootcamp(value);
  };

  const handlePageChange = (page) => {
    setActualPage(page);
  };

  const handleSearch = (e) => {
    setUserSearch(e.target.value);
    setNotFound(false);
  };

  const delayedSearch = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.length >= 3) {
        getASpecificUser(searchTerm);
      }

      if (searchTerm.length === 0) {
        getUsers();
      }
    }, 500),
    [selectedChoosenMod, selectedBootcamp]
  );

  useEffect(() => {
    getUsers();
    getBootcamps();
  }, []);

  useEffect(() => {
    delayedSearch(userSearch);
  }, [userSearch]);

  useEffect(() => {
    getUsers();
  }, [actualPage, selectedChoosenMod, selectedBootcamp]);

  return (
    <div className="mx-auto">
      <Container>
        {error && <h4>{error}</h4>}

        <>
          {users && users.length > 0 ? (
            <div>
              <div className="text-center my-5">
                <h3>{t("admin:see_users_progression")}</h3>
              </div>
              <Row className="mb-4">
                <Form.Control
                  as="select"
                  value={selectedChoosenMod.value}
                  onChange={handleModChoice}
                  className="col-6 mr-auto mb-4"
                >
                  {dropdownChoosenMod.map((choice) => (
                    <option key={choice.value} value={choice.value}>
                      {choice.label}
                    </option>
                  ))}
                </Form.Control>

                {selectedChoosenMod.value === "bootcamp" && (
                  <Form.Control
                    as="select"
                    value={selectedBootcamp}
                    onChange={handleCompanyChoice}
                    className="col-4 ml-auto mb-4"
                  >
                    {existingsBootcamps.map((choice) => (
                      <option key={choice.id} value={choice.id}>
                        {choice.name}
                      </option>
                    ))}
                  </Form.Control>
                )}
              </Row>
              <Form.Control
                ref={searchInputRef}
                className="col-8 mx-auto mb-4"
                type="text"
                placeholder={t("company:search_an_user")}
                value={userSearch}
                onChange={(e) => handleSearch(e)}
              />
              {notFound ? (
                <p className="text-center">{t("company:user_not_found")}</p>
              ) : (
                <Table striped bordered hover>
                  <thead className="text-center">
                    <tr>
                      <th></th>
                      <th>{t("admin:first_name")}</th>
                      <th>{t("admin:last_name")}</th>
                      <th>{t("common:show_user")}</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {!notFound &&
                      users.map((user) => {
                        return user && <UserLine key={user.id} user={user} />;
                      })}
                  </tbody>
                </Table>
              )}
            </div>
          ) : (
            <p className="text-center">{t("company:no_users_found")}</p>
          )}
          {totalPages && (
            <div className="d-flex justify-content-center">
              <Pagination className="pagination-orange">
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === actualPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          )}
        </>
      </Container>
    </div>
  );
};

export default SeeUsersCourses;
