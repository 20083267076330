import "./Login.scss";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import { fetchToCompanyLogin } from "stores/authentication/authMiddleware";
import { useTranslation } from "react-i18next";
import logincover from "assets/covers/right-side-login.png";
import leftSidePicture from "assets/covers/left-side-login.png";
import {
  Row,
  Col,
  Image,
  Form,
  FormGroup,
  FormControl,
  Button,
  Container,
  Modal,
} from "react-bootstrap";
import { LINKEDIN_URL } from "helpers/linkedinAuth";
import { GrLinkedin } from "react-icons/gr";
import Cookies from "js-cookie";
import { displayError } from "stores/flashmessages/flashMiddleware";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState(false);
  const [show, setShow] = useState(false);
  const { t } = useTranslation("login");
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const login = async (e) => {
    const data = {
      company: {
        email: email,
        password: password,
      },
    };
    e.preventDefault();

    if (await dispatch(fetchToCompanyLogin(data))) {
      history.push("/");
    }
  };

  const handleLinkedInLogin = () => {
    const token = Cookies.get("token");
    if (token) {
      Cookies.remove("token");
    }
    window.location.href = LINKEDIN_URL;
  };

  useEffect(() => {
    const error = query.get("error");
    if (error) {
      console.log("error", error);
      dispatch(displayError(t("login:login_linkedin_error")));
    }
  }, [query]);

  return (
    <Container fluid className="Login">
      <Row style={{ minHeight: "100%" }}>
        <Col
          xs={12}
          md={6}
          className="Login_col d-flex flex-column justify-content-center col-lg-6 col-md-12"
        >
          <Image
            src={leftSidePicture}
            alt="left side login page picture"
            className="left-side-picture"
            height={700}
          />
          <div className="login-text-block">
            <h2 className="happy p-3">{t("login:good_to_see_you")} 🔥</h2>
            <button
              className="company-btn btn btn-warning btn-lg mx-auto mb-4"
              onClick={() => handleShow()}
            >
              {t("login:company_connexion")}
            </button>
            <Modal
              show={show}
              onHide={handleClose}
              centered
              className="company-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: "20px" }}>
                  {t("login:company_connexion")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form
                  className="mt-3 mb-4 d-flex flex-column justify-content-around"
                  onSubmit={login}
                >
                  <FormGroup>
                    <FormControl
                      className="p-4 mb-3"
                      type="email"
                      placeholder={t("login:placeholderemail")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControl
                      className="p-4 mb-3"
                      type="password"
                      placeholder={t("login:placeholderpassword")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <button
                    type="submit"
                    className="company-login-btn w-75 mx-auto"
                    size="lg"
                  >
                    {t("login:labelbutton")}
                  </button>
                </Form>
              </Modal.Body>
            </Modal>
            <button
              className="linkedin-btn btn btn-primary btn-lg mx-auto font-weight-bold"
              size="lg"
              onClick={handleLinkedInLogin}
            >
              <GrLinkedin className="mb-1" />
              <a href={LINKEDIN_URL} className="linkedin-link ml-2">
                <span>{t("login:linkedin_connexion")}</span>
              </a>
            </button>
            <p className="mt-3">
              <Link to="/password">
                <span> {t("password:forgot_password")}</span>
              </Link>
            </p>
          </div>
        </Col>
        <Col md={6} className="Login_col d-none d-lg-block text-center">
          <Image src={logincover} alt="Illustration login page" height={800} />
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
