import { Button, Card, Row } from "react-bootstrap";
import { useLocation, useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/fr";
import "./ArticleCard.scss";

const ArticleCard = ({ article }) => {
  const { t } = useTranslation("profile");
  const location = useLocation();
  const history = useHistory();
  const route = location.pathname;

  const handleArticleToRead = (articleId) => {
    history.push({
      pathname: `/articles/${articleId}`,
      state: { readyToRead: true },
    });
  };

  return (
    <Card
      style={{ minHeight: "100%", minWidth: "100%" }}
      data-aos="fade-up"
      className="ArticleCard mx-3 mb-4 p-0"
    >
      <Card.Body className="d-flex flex-column py-0 mt-3">
        {article?.title && article.title !== null && (
          <p className="article-title">{article.title}</p>
        )}
        {article?.created_at !== null && (
          <p className="article-date">
            {moment(article.created_at).locale("fr").format("DD MMMM YYYY")}{" "}
          </p>
        )}
        {article?.description != null && (
          <p className="article-description">{article.description}</p>
        )}

        {article?.tags && article.tags.length > 0 && (
          <Row className="mx-1 mt-0">
            {article?.tags.map((tag) => (
              <span
                key={tag.id}
                className="badge badge-warning my-2 mx-1"
                style={{
                  fontSize: "0.9rem",
                  borderRadius: 0,
                  backgroundColor: "#fff7df",
                }}
              >
                {" "}
                {tag.name}{" "}
              </span>
            ))}
          </Row>
        )}

        <div className="w-100 text-center">
          {route === "/profile/myarticlesubscriptions" && (
            <Button
              className=" btn btn-reader text-white"
              onClick={() => handleArticleToRead(article.id)}
            >
              {t("read_this_memo")}
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ArticleCard;
