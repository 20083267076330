import { Modal, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UpdateGlossaryDefinition from "./UpdateGlossaryDefinition";
import UpdateGlossaryLanguage from "./UpdateGlossaryLanguage";
import UpdateGlossaryTitle from "./UpdateGlossaryTitle";
import UpdateGlossaryType from "./UpdateGlossaryType";

const ModalEditGlossary = ({ show, handleClose, reload, definition }) => {
  const { t } = useTranslation("admin");
  return (
    <Modal
      dialogClassName="modal-80w"
      aria-labelledby="custom-modal-styling"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("manage_glossary")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="profile" id="uncontrolled-tab" className="mb-3">
          <Tab eventKey="title" title={t("edit_title")}>
            <UpdateGlossaryTitle
              definition={definition}
              handleClose={handleClose}
              reload={reload}
            />
          </Tab>
          <Tab eventKey="definition" title={t("edit_definition")}>
            <UpdateGlossaryDefinition
              definition={definition}
              handleClose={handleClose}
              reload={reload}
            />
          </Tab>
          <Tab eventKey="language" title={t("edit_language")}>
            <UpdateGlossaryLanguage
              definition={definition}
              handleClose={handleClose}
              reload={reload}
            />
          </Tab>
          <Tab eventKey="definitionType" title={t("edit_type")}>
            <UpdateGlossaryType
              definition={definition}
              handleClose={handleClose}
              reload={reload}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEditGlossary;
