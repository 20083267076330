import { useTranslation } from "react-i18next";
import { Col, Row, Table, Form } from "react-bootstrap";
import DefinitionLine from "./components/DefinitionLine";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import ModalEditGlossary from "./components/ModalEditGlossary";
import { capitalize } from "tools/capitalize";

const EditOrDeleteDefinition = () => {
  const { t } = useTranslation("admin");
  const { get, error, data, isLoading } = useFetch();
  const [toReload, setToReload] = useState(false);
  const [selectedDefinition, setSelectedDefinition] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [languageError, setLanguageError] = useState(false);
  const [languageId, setLanguageId] = useState(null);
  const [terms, setTerms] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [glossaryTypeError, setGlossaryTypeError] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setSelectedDefinition(id);
  };

  const glossaryTypes = [
    {
      id: 0,
      name: "bim",
    },
    { id: 1, name: "btp" },
  ];

  const getTermsWithSpecificLanguageAndSpecificType = async () => {
    const data = await get(
      `/admin/glossaries?language_id=${languageId}&definition_type=${selectedType}`
    );
    setTerms(data);
  };

  const getTermsWithSpecificType = async () => {
    const data = await get(`/admin/glossaries?definition_type=${selectedType}`);
    setTerms(data);
  };

  const getTermsWithSpecificLanguage = async () => {
    const data = await get(`/admin/glossaries?language_id=${languageId}`);
    setTerms(data);
  };

  const getLanguages = async () => {
    const data = await get("/admin/languages");
    setLanguages(data);
  };

  const getAllterms = async () => {
    const data = await get("/admin/glossaries");
    setTerms(data);
  };

  useEffect(() => {
    getLanguages();
    getAllterms();
  }, []);

  useEffect(() => {
    checkIfSelectedLanguageExist();
  }, [languageId]);

  useEffect(() => {
    checkIfSelectedTypeExist();
  }, [selectedType]);

  useEffect(() => {
    if (
      languageId !== null &&
      selectedType !== null &&
      languageId !== "all_languages" &&
      selectedType !== "all_types"
    )
      return getTermsWithSpecificLanguageAndSpecificType();
    if (
      languageId !== null &&
      languageId !== "all_languages" &&
      glossaryTypeError
    )
      return getTermsWithSpecificLanguage();
    if (
      languageId !== null &&
      languageId === "all_languages" &&
      glossaryTypeError
    )
      return getAllterms();
    if (selectedType !== null && selectedType !== "all_types" && languageError)
      return getTermsWithSpecificType();
    if (selectedType !== null && selectedType === "all_types" && languageError)
      return getAllterms();
    if (languageId === "all_languages" && selectedType === "all_types")
      return getAllterms();
    if (languageId === "all_languages" && glossaryTypeError)
      return getTermsWithSpecificLanguage();
    if (selectedType === "all_types" && languageError)
      return getTermsWithSpecificType();
    if (selectedType === "all_types" && languageId)
      return getTermsWithSpecificLanguage();
    if (selectedType !== null && !glossaryTypeError)
      return getTermsWithSpecificType();
    if (languageId !== null && !languageError)
      return getTermsWithSpecificLanguage();
  }, [languageId, selectedType]);

  useEffect(() => {
    get("/admin/glossaries");
    return;
  }, [toReload]);

  const reload = () => {
    setToReload(!toReload);
  };

  const checkIfSelectedLanguageExist = () => {
    let existingLanguagesIds = [];
    languages.map((language) => {
      existingLanguagesIds.push(language.id);
    });
    if (
      !existingLanguagesIds.includes(Number(languageId)) &&
      languageId !== "all_languages"
    )
      setLanguageError(true);
    return;
  };

  const checkIfSelectedTypeExist = () => {
    let existingTypesNames = [];
    glossaryTypes.map((type) => {
      existingTypesNames.push(type.name);
    });

    if (
      !existingTypesNames.includes(selectedType) &&
      selectedType !== "all_types"
    )
      setGlossaryTypeError(true);
    return;
  };

  const handleChange = (event, errorHandler, selectedState) => {
    errorHandler(false);
    selectedState(event.target.value);
    return;
  };

  return (
    <div>
      <h3 className="text-center mt-5 mb-3">{t("manage_glossary")}</h3>
      {error && <h4>{error}</h4>}
      {(isLoading && t("loading")) ||
        (terms && (
          <div>
            <Row>
              <Col sm={12} md={6}>
                <div>
                  <Form.Label>{t("choose_a_language")} :</Form.Label>
                  <Form.Control
                    as="select"
                    style={{ border: languageError ? "1px solid red" : "" }}
                    className="mt-0 mb-0"
                    value={languageId !== null ? languageId : null}
                    onChange={(e) =>
                      handleChange(e, setLanguageError, setLanguageId)
                    }
                  >
                    <option value="all_languages">{t("all_languages")}</option>
                    {languages.map((language) => (
                      <option key={language.id} value={language.id}>
                        {language.name}
                      </option>
                    ))}
                  </Form.Control>
                  {languageError === true && (
                    <small className="text-danger">
                      {t("any_language_was_choosen")}
                    </small>
                  )}
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div>
                  <Form.Label>{t("choose_a_type")} :</Form.Label>
                  <Form.Control
                    as="select"
                    style={{
                      border: glossaryTypeError ? "1px solid red" : "",
                    }}
                    className="mt-0 mb-0"
                    value={selectedType !== null ? selectedType : null}
                    onChange={(e) =>
                      handleChange(e, setGlossaryTypeError, setSelectedType)
                    }
                  >
                    <option value="all_types">{t("all_types")}</option>
                    {glossaryTypes.map((type) => (
                      <option key={type.id} value={type.name}>
                        {capitalize(type.name)}
                      </option>
                    ))}
                  </Form.Control>
                  {glossaryTypeError === true && (
                    <small className="text-danger">
                      {t("any_types_was_choosen")}
                    </small>
                  )}
                </div>
              </Col>
            </Row>
            {terms && terms.length > 0 ? (
              <Table striped bordered hover className="my-4">
                <thead>
                  <tr>
                    <th>{t("title")}</th>
                    <th>{t("defintion")}</th>
                    <th>{t("language")}</th>
                    <th>{t("type")}</th>
                    <th>{t("edit")}</th>
                    <th>{t("delete")}</th>
                  </tr>
                </thead>
                <tbody>
                  {terms.map((definition) => (
                    <DefinitionLine
                      key={definition.id}
                      definition={definition}
                      reload={reload}
                      handleShow={handleShow}
                    />
                  ))}
                </tbody>
              </Table>
            ) : (
              <p className="mt-3">{t("any_definition_was_found")} </p>
            )}
          </div>
        ))}
      <div>
        {handleShow && (
          <ModalEditGlossary
            definition={selectedDefinition}
            show={show}
            handleClose={handleClose}
            reload={reload}
          />
        )}
      </div>
    </div>
  );
};

export default EditOrDeleteDefinition;
