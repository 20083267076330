import AdminUsersSectionFilter from "components/AdminUsersSectionFilter/AdminUsersSectionFilter";
import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UserLine from "./components/UserLine/UserLine";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [toReload, setToReload] = useState(false);
  const [filterValue, setFilterValue] = useState(null);
  const [filteredName, setFilteredName] = useState("");
  const [bootcamps, setBootcamps] = useState([]);
  const [selectedBootcamp, setSelectedBootcamp] = useState(null);
  const { data, error, isLoading, get } = useFetch();
  const { t } = useTranslation("admin");

  const handleDeletedUser = () => {
    get("/admin/users");
  };

  const getUsers = async () => {
    setFilterValue(null);
    const response = await get("/admin/users?only_with_subscriptions=true");
    console.log(response);
    setUsers(response);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getUsers();
  }, [toReload]);

  const getFilteredUsersByName = () => {
    setUsers(
      data.filter(
        (user) =>
          user.last_name?.toLowerCase().includes(filteredName?.toLowerCase()) ||
          user.first_name?.toLowerCase().includes(filteredName?.toLowerCase())
      )
    );
  };

  const keepOnlyBootcampUsers = () => {
    if (selectedBootcamp === "null") {
      setUsers(data);
    } else {
      setUsers(
        data.filter(
          (user) => Number(user.company_id) === Number(selectedBootcamp)
        )
      );
    }
  };

  useEffect(() => {
    if (filterValue === "name" && filteredName?.length > 0) {
      getFilteredUsersByName();
    }

    if (filterValue !== "bootcamp") {
      setSelectedBootcamp(null);
    }

    if (filteredName?.length === 0) {
      setUsers(data);
    }
  }, [filterValue, filteredName]);

  useEffect(() => {
    if (filterValue === "bootcamp" && selectedBootcamp) {
      keepOnlyBootcampUsers();
    }
  }, [selectedBootcamp]);

  return (
    <div className="ManageUser">
      {error && <h4>{error}</h4>}
      {(isLoading && <div className="m-4 text-center">{t("loading")}</div>) ||
        (users && (
          <>
            <div className="text-center">
              <h3>{t("manage_users")}</h3>
            </div>
            <div className="my-4 w-100">
              <AdminUsersSectionFilter
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                setFilteredName={setFilteredName}
                setSelectedBootcamp={setSelectedBootcamp}
                setBootcamps={setBootcamps}
                bootcamps={bootcamps}
              />
            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{t("first_name")}</th>
                  <th>{t("last_name")}</th>
                  <th>{t("email")}</th>
                  <th>{t("remaining_sessions")}</th>
                  <th>{t("role")}</th>
                  <th>{t("subscriptions")}</th>
                  <th>{t("edit")}</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <UserLine
                    key={user.id}
                    user={user}
                    onDeleteUser={handleDeletedUser}
                    toReload={toReload}
                    setToReload={setToReload}
                  />
                ))}
              </tbody>
            </Table>
          </>
        ))}
    </div>
  );
};

export default ManageUsers;
