import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const DescriptionForm = ({ setDescription }) => {
  const { t } = useTranslation("profile");
  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>{t("profile:create_description")}</Form.Label>
          <Form.Control
            as="textarea"
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default DescriptionForm;
