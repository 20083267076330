import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFetch from "hooks/useFetch";
import { useSelector } from "react-redux";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";

const UpdateSessionStatusButton = ({ session, reload, handleClose }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("profile");
  const { patch } = useFetch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = { finished: !session.finished };
    await patch(
      `/users/${currentUser.id}/mentoring_sessions/${session.id}`,
      data,
      displaySuccess(t("status_updated")),
      displayError(t("status_not_updated"))
    );
    handleClose();
    reload();
  };

  return (
    session &&
    currentUser && (
      <div className="d-flex flex-column align-items-center mt-4 mb-4 ">
        <Button className="btn btn-warning text-white mt-2" onClick={handleSubmit}>
          {t("edit_status")}
        </Button>
      </div>
    )
  );
};

export default UpdateSessionStatusButton;
