import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
import { capitalize } from "tools/capitalize";

const UpdateGlossaryType = ({ definition, handleClose, reload }) => {
  const { get, patch } = useFetch();
  const { t } = useTranslation("admin");
  const [selectedType, setSelectedType] = useState(null);
  const [glossaryTypeError, setGlossaryTypeError] = useState(false);

  const glossaryTypes = [
    {
      id: 0,
      name: "bim",
    },
    { id: 1, name: "btp" },
  ];

  useEffect(() => {
    checkIfSelectedTypeExist();
  }, [selectedType]);

  const checkIfSelectedTypeExist = () => {
    let existingTypesNames = [];
    glossaryTypes.map((type) => {
      existingTypesNames.push(type.name);
    });

    if (!existingTypesNames.includes(selectedType)) setGlossaryTypeError(true);
    return;
  };

  const handleChange = (event) => {
    setGlossaryTypeError(false);
    setSelectedType(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await patch(
      `/admin/glossaries/${definition.id}`,
      { definition_type: selectedType },
      displaySuccess(t("glossary_type_updated")),
      displayError(t("glossary_type_not_updated"))
    );
    handleClose();
    reload();
  };

  return (
    <div>
      <div className="mb-4">
        <Form.Control
          as="select"
          style={{ border: glossaryTypeError ? "1px solid red" : "" }}
          className="mt-3 mb-0"
          onChange={handleChange}
        >
          <option>{t("choose_a_type")}</option>
          {glossaryTypes.map((type) => (
            <option key={type.id} value={type.name}>
              {capitalize(type.name)}
            </option>
          ))}
        </Form.Control>
        {glossaryTypeError === true && (
          <small className="text-danger">{t("not_good_type")}</small>
        )}
      </div>
      {!glossaryTypeError && (
        <div className="text-center mt-4">
          <Button className="btn-warning text-white" onClick={handleSubmit}>
            {t("edit_type")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default UpdateGlossaryType;
