import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LearningPathLine from "./LearningPathLine";
import NewLearningPath from "./NewLearningPath";
import "./ManageLearning.scss";

const ManageLearningPath = () => {
  const { data, error, isLoading, get } = useFetch();
  const { t } = useTranslation("admin");
  const [toReload, setToReload] = useState(false);

  const handleEdit = () => {
    get("/admin/learning_paths");
    window.location.reload();
  };

  useEffect(() => {
    get("/admin/learning_paths");
  }, []);

  useEffect(() => {
    get("/admin/learning_paths");
  }, [toReload]);

  const reload = () => {
    setToReload(!toReload);
  };

  return (
    <div className="ManageLearningPath">
      {error && <h4>{error}</h4>}
      {(isLoading && t("loading")) ||
        (data && (
          <>
            <div className="text-center">
              <h3>{t("manage_learning_paths")}</h3>
            </div>
            <NewLearningPath handleEdit={handleEdit} />
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t("title")}</th>
                  <th className="price-column">{t("price_in_cents")}</th>
                  <th>{t("courses_list")}</th>
                  <th>{t("admin:state")}</th>
                  <th>{t("edit")}</th>
                  <th>{t("delete")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((learning_path) => (
                  <LearningPathLine
                    key={learning_path.id}
                    learningPath={learning_path}
                    handleEdit={handleEdit}
                    reload={reload}
                  />
                ))}
              </tbody>
            </Table>
          </>
        ))}
    </div>
  );
};

export default ManageLearningPath;
