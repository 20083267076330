import useFetch from "hooks/useFetch";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

const SelectedBootcampUsers = () => {
  const { t } = useTranslation("admin");
  const { get } = useFetch();
  const { pathname } = useLocation();
  const params = useParams();
  const [users, setUsers] = useState(null);
  const [bootcamp, setBootcamp] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    pathname.split("/")[3]
  );

  const getBootcamp = async () => {
    const response = await get(`/admin/companies/${selectedCompanyId}`);
    setBootcamp(response);
  };

  useEffect(() => {
    setSelectedCompanyId(pathname.split("/")[3]);
  }, []);

  useEffect(() => {
    getBootcamp();
  }, [selectedCompanyId]);

  useEffect(() => {
    if (bootcamp?.users) {
      setUsers(bootcamp?.users);
    }
  }, [bootcamp]);

  return (
    <div>
      {bootcamp && (
        <>
          <h3 className="text-center">
            {t("participants_of")} {bootcamp.name}{" "}
          </h3>
          <Table striped bordered hover className="my-4">
            <thead>
              <tr>
                <th>{t("first_name")}</th>
                <th>{t("last_name")}</th>
                <th>{t("participant_presences")}</th>
                <th>{t("generate_presence_pdf")}</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user, index) => (
                  <tr key={index}>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>
                      <Link
                        to={`/admin/bootcamp/${selectedCompanyId}/user/${user.id}/presences`}
                        className="btn btn-outline-secondary text-dark"
                      >
                        {t("participant_presences")}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/admin/bootcamp/${selectedCompanyId}/user/${user.id}/pdf`}
                        className="btn btn-outline-warning text-dark"
                      >
                        {t("go_to_pdf_generation")}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default SelectedBootcampUsers;
