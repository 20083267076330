import { useState } from "react";
import { AiFillCloseCircle, AiFillEdit } from "react-icons/ai";
import moment from "moment";
import "moment/locale/fr";
import BootcampSessionModal from "./BootcampSessionModal";

const BootcampSessionLine = ({ bootcampSession, handleDelete, reload }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <tr key={bootcampSession.id}>
      <td>{bootcampSession.id}</td>
      <td>{bootcampSession.company.name}</td>
      <td>
        {moment(bootcampSession.start_date).locale("fr").format("DD MMMM YYYY")}
      </td>
      <td>
        {moment(bootcampSession.end_date).locale("fr").format("DD MMMM YYYY")}
      </td>
      <td>
        <AiFillEdit size={30} style={{color: "orange"}} onClick={handleShow} />
        <BootcampSessionModal show={show} handleClose={handleClose} session={bootcampSession} reload={reload} />
      </td>
      <td>
        {" "}
        <AiFillCloseCircle
          size={30}
          style={{ color: "red" }}
          onClick={() => handleDelete(bootcampSession.id)}
        />{" "}
      </td>
    </tr>
  );
};

export default BootcampSessionLine;
