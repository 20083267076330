import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  fetchCurrentCompany,
  fetchCurrentUser,
  fetchCurrentUserMinimalsDetails
} from "stores/authentication/authMiddleware";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import Cookies from "js-cookie";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "./Menu/Menu";
import Feed from "./Feed/Feed";
import "./Profile.scss";
import GeneralTermsOfUse from "components/GeneralTermsOfUse/GeneralTermsOfUse";

const Profile = ({ currentLanguageId }) => {
  const [userUpdated, setUserUpdated] = useState(false);
  const isCompany = useSelector((state) => state.auth.isCompany);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const token = Cookies.get("token");
    if (userUpdated && !isCompany) {
      dispatch(fetchCurrentUserMinimalsDetails(token));
    } else if (userUpdated && isCompany) {
      dispatch(fetchCurrentCompany(token, currentUser));
    }
    setUserUpdated(false);
  }, [userUpdated]);

  useEffect(() => {
    if (search === "?payment_successful") {
      dispatch(displaySuccess(t("payment:success")));
    }
  }, [search]);

  return (
    <Container fluid className="Profile p-0">
      {currentUser && !currentUser.has_agreed_terms && <GeneralTermsOfUse setUserUpdated={setUserUpdated} isCompany={isCompany} />}
      {currentUser && currentUser.has_agreed_terms && (
        <Row>
          <Col lg={3} md={12} sm={12} className="d-lg-block">
            <Menu />
          </Col>
          <Col lg={9} md={12} sm={12} className="feed-column">
            <Feed
              reload={setUserUpdated}
              currentLanguageId={currentLanguageId}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Profile;
