import { useEffect, useState } from "react";

const HubspotMeeting = ({ hubspotLink }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const loadScheduler = () => {
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => setScriptLoaded(true);
  };

  useEffect(() => {
    loadScheduler();
  }, []);

  useEffect(() => {
    loadScheduler();
  }, [hubspotLink]);

  console.log(hubspotLink);

  return (
    <div>
      {scriptLoaded && (
        <div
          className="meetings-iframe-container"
          style={{
            height: "800px",
          }}
          data-src={`${hubspotLink}/?embed=true`}
        ></div>
      )}
    </div>
  );
};

export default HubspotMeeting;
