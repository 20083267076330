import { useHistory } from "react-router-dom";
import "./CourseCard.scss";
import { useSelector } from "react-redux";
import paymentFetch from "hooks/paymentFetch";
import freeSubscription from "hooks/freeSubscription";
import { useDispatch } from "react-redux";
import { Card, Badge, Accordion, Row, ProgressBar } from "react-bootstrap";
import ButtonPrimary from "components/ButtonPrimary/ButtonPrimary";
import { useTranslation } from "react-i18next";
import defaultcover from "assets/covers/defaultcover.svg";
import { displayWarning } from "stores/flashmessages/flashMiddleware";
import { useLocation } from "react-router-dom";
import CustomToggle from "components/CustomToggle/CustomToggle";
import { capitalize } from "tools/capitalize";

const CourseCard = ({
  course,
  subscribed,
  noSubscription,
  imgHeight,
  learningPath,
}) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isLogged = useSelector((state) => state.auth.isLogged);
  const { title, price_in_cents, id, slug, description, categories } = course;
  const { newPayment } = paymentFetch();
  const { newFreeSubscription } = freeSubscription();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  let route = useLocation();
  const profileCoursesPath = "/profile/mycourses";

  const handleSubscription = (e) => {
    if (isLogged) {
      e.preventDefault();
      if (parseInt(price_in_cents) !== 0) {
        return;
        //newPayment(price_in_cents, id);
      } else {
        console.log(currentUser.id, id);
        newFreeSubscription(currentUser.id, id);
        history.push("/profile/mycourses");
      }
    } else {
      dispatch(displayWarning(t("need_to_login")));
      history.push("/login");
    }
  };

  const getProgress = (course) => {
    if (route.pathname === profileCoursesPath) {
      return course.progress_states.find(
        (progress) => progress.user_id === currentUser.id
      );
    } else if (route.pathname !== profileCoursesPath) {
      const selectedUserId = route.pathname.split("/")[3];
      return course.progress_states.find(
        (progress) => Number(progress.user_id) === Number(selectedUserId)
      );
    }
  };

  const handleChapter = (courseId) => {
    history.push(`/chapters?courseId=${courseId}`);
  };

  const giveMeGoodImageURLForMyCoursesPage = (learningPath) => {
    if (route.pathname === "/profile/mycourses" && learningPath.large_image_url)
      return learningPath.large_image_url;
    if (
      route.pathname === "/profile/mycourses" &&
      learningPath.large_image_url === null
    )
      return defaultcover;
  };

  const giveMeGoodImageURLForCoursesHome = (path) => {
    if (route.pathname === "/courses" && path.courses[0].image_url)
      return course.courses[0].image_url;
    if (route.pathname === "/courses" && path.courses[0].image_url === null)
      return defaultcover;
    if (route.pathname.split("/")[1] === "admin" && path.image_url)
      return course.image_url;
    if (route.pathname.split("/")[1] === "admin" && path.image_url === null)
      return defaultcover;
  };

  return (
    <Accordion
      className={`${
        route.pathname === profileCoursesPath
          ? "CourseCard m-3 col-md-8 col-sm-12"
          : "CourseCard m-3 col-lg-5 col-sm-12"
      }`}
      data-aos="fade-up"
    >
      <Card>
        <Card.Img
          variant="top"
          src={
            route.pathname === "/profile/mycourses"
              ? giveMeGoodImageURLForMyCoursesPage(learningPath)
              : giveMeGoodImageURLForCoursesHome(course)
          }
          alt="IMAGE"
          style={{ height: `${imgHeight}` }}
        />
        <Card.Header className="CourseCard__title">{title}</Card.Header>
        <Card.Body className="d-flex flex-column align-items-center py-0">
          <Row>
            {!subscribed && description != null && (
              <CustomToggle
                eventKey="0"
                className="btn btn-warning btn-sm mr-3"
              >
                💡 {capitalize(t("admin:description"))}
              </CustomToggle>
            )}
            {!noSubscription && !subscribed && (
              <button
                className="btn btn-warning mx-1"
                style={{
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "#ff8a00",
                }}
                onClick={handleSubscription}
              >
                ✨ {t("register_to_course")}
              </button>
            )}
          </Row>
          <Accordion.Collapse eventKey="0">
            <Card.Body>{!subscribed && <h6>{description}</h6>}</Card.Body>
          </Accordion.Collapse>
          {subscribed && (
            <div className="d-flex flex-column align-items-center">
              <p className="small m-1">
                {`${
                  route.pathname === profileCoursesPath
                    ? t("your_progress")
                    : t("profile:progression")
                } : ${getProgress(course).progression || 0} %`}{" "}
                {`${getProgress(course).progression ? "🔥" : "😕"}`}
              </p>
              <ProgressBar
                className={`${
                  route.pathname === profileCoursesPath ? "w-100" : "w-100 mb-3"
                }`}
                variant={
                  getProgress(course).progression === 100
                    ? "success"
                    : "warning"
                }
                now={
                  getProgress(course).progression
                    ? getProgress(course).progression
                    : 0
                }
              />
              {route.pathname === profileCoursesPath && (
                <ButtonPrimary
                  handleClick={() => handleChapter(course.id)}
                  label={t("common:access_to_courses")}
                  sizeClass="sm py-1 px-2 my-2"
                />
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </Accordion>
  );
};

export default CourseCard;
