import useFetch from "hooks/useFetch";
import UserLine from "pages/Company/components/SeeEmployees/UserLine";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Card,
  Col,
  Row,
  Image,
  ProgressBar,
  Form,
} from "react-bootstrap";
import noAvatar from "assets/noavatar.jpg";
import { Link } from "react-router-dom";
import UsersConnexionsGraph from "components/UsersConnexionsGraph/UsersConnexionsGraph.jsx";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";
import { enGB } from "date-fns/locale";
import moment from "moment-timezone";
import "moment/locale/fr";
import "moment/locale/en-gb";
moment.tz.setDefault("Europe/Paris");
import "./SeeStatisticsForAdmin.scss";

const SeeStatisticsForAdmin = () => {
  const { t, i18n } = useTranslation();
  const { error, isLoading, get } = useFetch();
  const [usersConnexions, setUsersConnexions] = useState([]);
  const [dataForConnexionGraph, setDataForConnexionGraph] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalConnexions, setTotalConnexions] = useState(0);
  const [lastConnexions, setLastConnexions] = useState([]);
  const [limitOfDisplayedUsers, setLimitOfDisplayedUsers] = useState(6);
  const [companyProgression, setCompanyProgression] = useState(null);
  const [takedMentoringSessions, setTakedMentoringSessions] = useState(null);
  const [totalStudents, setTotalStudents] = useState(null);
  const [coursesWithProgression, setCoursesWithProgression] = useState(null);
  const [selectedBootcamp, setSelectedBootcamp] = useState(null);
  const [existingsBootcamps, setExistingBootcamps] = useState([]);
  const [selectedChoosenMod, setSelectedChoosenMod] = useState({
    label: t("admin:general"),
    value: "general",
  });
  const [
    selectedDropdownConnexionsPeriod,
    setSelectedDropdownConnexionsPeriod,
  ] = useState({ label: t("company:passed_month"), value: "month" });

  const dropdownConnexionsPeriod = [
    { label: t("company:passed_month"), value: "month" },
    { label: t("company:passed_week"), value: "week" },
  ];

  const dropdownChoosenMod = [
    { label: t("admin:general"), value: "general" },
    { label: t("admin:by_bootcamp"), value: "bootcamp" },
  ];

  const getBootcamps = async () => {
    const response = await get("/admin/companies?is_bootcamp=true");
    setSelectedBootcamp(response[0].id);
    setExistingBootcamps(response);
  };

  const handleModChoice = (event) => {
    const value = event.target.value;
    const choice = dropdownChoosenMod.find((d) => d.value === value);
    setSelectedChoosenMod(choice);
  };

  const handleCompanyChoice = (event) => {
    const value = event.target.value;
    console.log(value);
    setSelectedBootcamp(value);
  };

  const calculateOverallAverageProgress = (progressStates) => {

    const filteredStates = {};
    progressStates.forEach((state) => {
      const key = `${state.user_id}_${state.course_id}`;
      if (
        !filteredStates[key] ||
        parseInt(filteredStates[key].progression) < parseInt(state.progression)
      ) {
        filteredStates[key] = state;
      }
    });

    console.log(filteredStates);

    const totalProgress = Object.values(filteredStates).reduce(
      (total, state) => total + parseInt(state.progression),
      0
    );
    const averageProgress =
      Object.keys(filteredStates).length > 0
        ? totalProgress / Object.keys(filteredStates).length
        : 0;

    return Math.round(averageProgress);
  };

  const getUsersAndConnexions = async () => {
    let response = null;
    try {
      if (selectedChoosenMod.value === "bootcamp") {
        response = await get(
          `/admin/user_connexions?start_date=${startDate}&end_date=${endDate}&bootcamp_id=${selectedBootcamp}`
        );
      } else {
        response = await get(
          `/admin/user_connexions?start_date=${startDate}&end_date=${endDate}`
        );
      }

      if (response?.progress_states && response.progress_states.length > 0) {
        const averageProgress = calculateOverallAverageProgress(
          response.progress_states
        );
        setCompanyProgression(averageProgress);
      }

      if (response?.courses_with_progression?.length > 0) {
        const sortedCourses = response.courses_with_progression.sort(
          (a, b) => b.progression - a.progression
        );

        setCoursesWithProgression(sortedCourses);
      }

      if (response?.total_taked_mentoring_sessions) {
        setTakedMentoringSessions(response.total_taked_mentoring_sessions);
      }

      if (response?.number_of_users) {
        setTotalStudents(response.number_of_users);
      }

      setUsersConnexions(response?.users_connexions);
    } catch (error) {
      console.error(error);
    }
  };

  const updateLimitOfDisplayedUsers = () => {
    setLimitOfDisplayedUsers((prevLimit) => prevLimit + 6);
  };

  const getLastConnexionForEachUser = () => {
    const usersConnexionsWithLastConnexion = [];

    if (!usersConnexions) return [];

    usersConnexions?.forEach((userConnexion) => {
      const index = usersConnexionsWithLastConnexion.findIndex(
        (u) => u.user_id === userConnexion.user_id
      );
      if (index === -1) {
        usersConnexionsWithLastConnexion.push(userConnexion);
      } else {
        if (
          moment(userConnexion.created_at).isAfter(
            moment(usersConnexionsWithLastConnexion[index].created_at)
          )
        ) {
          usersConnexionsWithLastConnexion[index] = userConnexion;
        }
      }
    });

    const sortedConnexions = usersConnexionsWithLastConnexion.sort((a, b) =>
      moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1
    );
    return sortedConnexions.slice(0, 14);
  };

  const formatDistanceToNowLocale = (dateString, language) => {
    const locale = language.split("-")[0] === "fr" ? fr : enGB;
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true, locale: locale });
  };

  useEffect(() => {
    const dates = getDatesForCurrentMonth();

    setStartDate(dates[0]);
    setEndDate(dates[dates.length - 1]);

    getBootcamps();
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      getUsersAndConnexions();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      getUsersAndConnexions();
    }
  }, [selectedBootcamp, startDate, endDate]);

  useEffect(() => {
    setUsersConnexions([]);
    setDataForConnexionGraph(null);
    setTotalConnexions(0);
    setLastConnexions([]);
    setLimitOfDisplayedUsers(6);
    setCompanyProgression(null);
    setTakedMentoringSessions(null);
    setTotalStudents(null);
    setCoursesWithProgression(null);

    if (startDate && endDate) {
      getUsersAndConnexions();
    }
  }, [selectedChoosenMod, selectedBootcamp]);

  useEffect(() => {
    if (selectedDropdownConnexionsPeriod.value === "week") {
      const dates = getDatesForPassedWeek();
      setDataForConnexionGraph(formatDataForConnexionGraph(dates));
      setLastConnexions(getLastConnexionForEachUser());
      //setLastConnexions(fakeUsers);
    } else if (selectedDropdownConnexionsPeriod.value === "month") {
      const dates = getDatesForCurrentMonth();
      setDataForConnexionGraph(formatDataForConnexionGraph(dates));
      setLastConnexions(getLastConnexionForEachUser());
      //setLastConnexions(fakeUsers);
    }
  }, [usersConnexions]);

  useEffect(() => {
    if (!dataForConnexionGraph) return;
    setTotalConnexions(
      dataForConnexionGraph.reduce((acc, curr) => acc + curr.connections, 0)
    );
  }, [dataForConnexionGraph]);

  useEffect(() => {
    if (lastConnexions.length === 0) return;
  }, [lastConnexions]);

  const getDatesForCurrentMonth = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    const startDate = moment(currentDate).subtract(1, "months");
    const dates = [];
    while (startDate.isSameOrBefore(currentDate)) {
      dates.push(startDate.format("YYYY-MM-DD"));
      startDate.add(1, "days");
    }
    return dates;
  };

  const getDatesForPassedWeek = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    const startDate = moment(currentDate).subtract(7, "days");
    const dates = [];
    while (startDate.isSameOrBefore(currentDate)) {
      dates.push(startDate.format("YYYY-MM-DD"));
      startDate.add(1, "days");
    }
    return dates;
  };

  const formatDataForConnexionGraph = (dates) => {
    if (usersConnexions?.length === 0 || usersConnexions === null) return;

    let data = dates.map((date) => ({ date, connections: 0 }));

    usersConnexions?.forEach((connexion) => {
      const date = moment(connexion.created_at).format("YYYY-MM-DD");
      const index = data.findIndex((d) => d.date === date);
      if (index !== -1) {
        data[index].connections++;
      }
    });

    return data;
  };

  const handleSelectConnexionsPeriod = (event) => {
    const value = event.target.value;
    const choice = dropdownConnexionsPeriod.find((d) => d.value === value);
    setSelectedDropdownConnexionsPeriod(choice);
  };

  useEffect(() => {
    if (selectedDropdownConnexionsPeriod.value === "week") {
      const dates = getDatesForPassedWeek();
      setStartDate(dates[0]);
      setEndDate(dates[dates.length - 1]);
    } else if (selectedDropdownConnexionsPeriod.value === "month") {
      const dates = getDatesForCurrentMonth();
      setStartDate(dates[0]);
      setEndDate(dates[dates.length - 1]);
    }
  }, [selectedDropdownConnexionsPeriod]);

  return (
    <div className="mx-auto">
      <Container fluid>
        {error && <h4>{error}</h4>}
        {(isLoading && <p className="text-center">{t("admin:loading")}</p>) || (
          <>
            <h3 className="text-center mt-3 mb-4">{t("profile:dashboard")}</h3>
            <Row className="mb-4">
              <Form.Control
                as="select"
                value={selectedChoosenMod.value}
                onChange={handleModChoice}
                className="col-6 mr-auto mb-4"
              >
                {dropdownChoosenMod.map((choice) => (
                  <option key={choice.value} value={choice.value}>
                    {choice.label}
                  </option>
                ))}
              </Form.Control>

              {selectedChoosenMod.value === "bootcamp" && (
                <Form.Control
                  as="select"
                  value={selectedBootcamp}
                  onChange={handleCompanyChoice}
                  className="col-4 ml-auto mb-4"
                >
                  {existingsBootcamps.map((choice) => (
                    <option key={choice.id} value={choice.id}>
                      {choice.name}
                    </option>
                  ))}
                </Form.Control>
              )}
            </Row>
            {totalStudents && (
              <h6 className="text-center text-secondary mb-4">
                {totalStudents} {t("company:students_enrolled")}
              </h6>
            )}
            {dataForConnexionGraph && totalConnexions && (
              <UsersConnexionsGraph
                data={dataForConnexionGraph}
                totalConnexions={totalConnexions}
                dropdownConnexionsPeriod={dropdownConnexionsPeriod}
                selectedDropdownConnexionsPeriod={
                  selectedDropdownConnexionsPeriod
                }
                handleSelectConnexionsPeriod={handleSelectConnexionsPeriod}
              />
            )}
            <Card className="my-5 statistics-cards">
              <Card.Body className="px-4">
                <Card.Title className="text-center font-weight-bold">
                  {t("last_connected_persons")}
                </Card.Title>
                {!isLoading && lastConnexions.length === 0 && (
                  <p>{t("no_users_to_display")}</p>
                )}
                {lastConnexions.length > 0 && (
                  <Row className="pl-4 mt-3">
                    {lastConnexions
                      .slice(0, limitOfDisplayedUsers)
                      .map((connexion) => (
                        <Col md={4} sm={12} key={connexion.id}>
                          <div
                            className="d-flex align-items-center m-3 justify-content-between"
                            style={{ textAlign: "left" }}
                          >
                            <Link
                              to={`/admin/seeuser/${connexion.user_id}`}
                              params={{ id: connexion.user_id }}
                            >
                              <div className="d-flex flex-row align-items-center justify-content-center">
                                <Image
                                  className="rounded-avatar mr-3"
                                  src={
                                    connexion.picture_url
                                      ? connexion.picture_url
                                      : noAvatar
                                  }
                                  style={{ objectFit: "cover" }}
                                  alt="user picture"
                                  width="40"
                                  height="40"
                                  roundedCircle
                                />
                                <div className="d-flex flex-column">
                                  <p className="last-connected-persons-names">
                                    {connexion.first_name} {connexion.last_name}
                                  </p>
                                  <div className="d-flex flex-column">
                                    <p
                                      className="text-dark mb-0"
                                      style={{ fontSize: "14px" }}
                                    >
                                      {formatDistanceToNowLocale(
                                        new Date(connexion.created_at),
                                        i18n.language
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </Col>
                      ))}
                  </Row>
                )}
                <div className="w-100 text-center">
                  {limitOfDisplayedUsers < lastConnexions.length && (
                    <button
                      className="btn btn-update-limit"
                      onClick={updateLimitOfDisplayedUsers}
                    >
                      {t("show_more_users")}
                    </button>
                  )}
                </div>
              </Card.Body>
            </Card>
            <Row className="my-5">
              <Col sm={12} md={6}>
                <Card className="statistics-cards h-100 p-4">
                  <Card.Title className="text-center font-weight-bold">
                    {t("company:team_progression")} 🎯
                  </Card.Title>
                  <Card.Body className="d-flex flex-column justify-content-center">
                    {companyProgression ? (
                      <ProgressBar
                        now={companyProgression}
                        className="company-admin-progressbar"
                        variant="ORANGE_BG"
                        label={`${companyProgression}%`}
                      />
                    ) : (
                      <p>{t("company:the_team_need_time_come_later")} </p>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Card className="statistics-cards right-stats-card h-100">
                  <Card.Body>
                    <Row className="d-flex align-items-center">
                      <Col sm={12} md={4}>
                        {takedMentoringSessions ? (
                          <p className="taked-sessions-number">
                            {takedMentoringSessions}
                          </p>
                        ) : (
                          <p className="taked-sessions-number">0</p>
                        )}
                      </Col>
                      <Col sm={12} md={8}>
                        <p className="text-left">
                          {t("company:taked_mentoring_sessions")}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {coursesWithProgression && (
              <Col sm={12} md={12} className="my-5">
                <Card className="statistics-cards">
                  <Card.Body>
                    <Card.Title className="text-center font-weight-bold">
                      {t("company:most_followed_courses")} 👌🏻
                    </Card.Title>

                    <div className="mt-4">
                      {coursesWithProgression
                        .slice(0, 5)
                        .map((course, index) => (
                          <div
                            key={course.id}
                            className="d-flex align-items-center mb-3"
                          >
                            <div className="rounded-orange-box-course-number">
                              <p style={{ margin: 0 }}>{index + 1}</p>
                            </div>
                            <p className="text-center m-0">{course.title}</p>
                          </div>
                        ))}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default SeeStatisticsForAdmin;
