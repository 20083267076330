import CardAccordion from "components/CardAccordion/CardAccordion";
import { useTranslation } from "react-i18next";
import { Button, Card, Container, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import DescriptionForm from "./components/DescriptionForm";
import useFetch from "hooks/useFetch";
import CategoryForm from "./components/CategoryForm";
import MentorForm from "./components/MentorForm";
import { AiFillCheckCircle } from "react-icons/ai";
import CalendlyComponent from "./components/CalendlyComponent";
import { useDispatch } from "react-redux";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import MentorProfile from "pages/private/MentorProfile/MentorProfile";
import HubspotMeeting from "./components/HubspotMeeting";
import "./NewMentoringSession.scss";


const NewMentoringSession = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("profile");
  const { get, post } = useFetch();
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [choosedCategoryIndex, setChoosedCategoryIndex] = useState(null);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [mentor, setMentor] = useState(null);
  const [confirmedDate, setConfirmedDate] = useState(false);
  const iconsSucessClass = "text-success";
  const iconsSucessStyle = { fontSize: "20px" };
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const redirectAfterSessionCreation = () => {
    dispatch(displaySuccess(t("mentoring_session_created")));
    history.push("/profile/dashboard");
  };
  const handleSubmit = (event) => {
    const data = {
      student_id: currentUser.id,
      description: description,
      category_id: categories[choosedCategoryIndex].id,
      mentor_id: mentor.id,
    };
    event.preventDefault();
    post(
      `/users/${currentUser.id}/mentoring_sessions`,
      data,
      redirectAfterSessionCreation
    );
  };

  useEffect(() => {
    const getCategories = async () => {
      const allCategories = await get("/categories");
      setCategories(allCategories);
    };
    getCategories();
  }, []);

  useEffect(() => {
    setMentor(null);
    const getMentorProfile = async () => {
      const mentorProfile = await get(
        `/users/${selectedMentor}?mentor_id=${selectedMentor}`
      );
      setMentor(mentorProfile);
    };
    getMentorProfile();
  }, [selectedMentor]);



  return (
    <Card className="NewMentoringSessionCard d-flex flex-column align-items-center">
      <h4 className="new-session-title mb-4">{t("new_mentoring_session")}</h4>
      <Container>
        <p className="font-weight-bold">{t("what_we_need")} :</p>
        <ul className="ml-3">
          <li>
            {t("an_explanation")}{" "}
            {description && description.length > 50 && (
              <AiFillCheckCircle
                className={iconsSucessClass}
                style={iconsSucessStyle}
              />
            )}
          </li>
          <li>
            {t("a_category")}{" "}
            {choosedCategoryIndex &&
              choosedCategoryIndex !== null &&
              choosedCategoryIndex !== "" && (
                <AiFillCheckCircle
                  className={iconsSucessClass}
                  style={iconsSucessStyle}
                />
              )}
          </li>
          <li>
            {t("a_mentor")}{" "}
            {selectedMentor && selectedMentor !== null && (
              <AiFillCheckCircle
                className={iconsSucessClass}
                style={iconsSucessStyle}
              />
            )}
          </li>
          <li>
            {t("a_date")}{" "}
            {confirmedDate && confirmedDate !== "" && (
              <AiFillCheckCircle
                className={iconsSucessClass}
                style={iconsSucessStyle}
              />
            )}
          </li>
        </ul>
        <div className="my-3">
          <CardAccordion
            header={t("why_a_new_mentoring_session")}
            content={<DescriptionForm setDescription={setDescription} />}
          />
        </div>
        {categories && categories.length > 0 && (
          <div className="my-3">
            <CardAccordion
              header={t("select_category")}
              content={
                <CategoryForm
                  categories={categories}
                  choosedCategoryIndex={choosedCategoryIndex}
                  setChoosedCategoryIndex={setChoosedCategoryIndex}
                />
              }
            />
          </div>
        )}
        {choosedCategoryIndex &&
          choosedCategoryIndex !== null &&
          choosedCategoryIndex !== "" && (
            <div className="my-3">
              <CardAccordion
                header={t("select_a_mentor")}
                content={
                  (categories[choosedCategoryIndex].mentors.length > 0 && (
                    <MentorForm
                      categories={categories}
                      choosedCategoryIndex={choosedCategoryIndex}
                      setSelectedMentor={setSelectedMentor}
                      selectedMentor={selectedMentor}
                      handleShow={handleShow}
                    />
                  )) || <p>{t("no_mentor_yet")}</p>
                }
              />
            </div>
          )}
        {mentor &&
          mentor.calendly_username !== null &&
          mentor.calendly_username !== "" && (
            <div className="my-3">
              <CardAccordion
                header={t("schedule_a_session")}
                content={
                  categories[choosedCategoryIndex].mentors.length > 0 && (
                    <div>
                      <HubspotMeeting hubspotLink={mentor.calendly_username} />
                      <div className="text-center">
                        <Button
                          className="btn mentoring-session-btns text-white mb-4"
                          onClick={() => setConfirmedDate(true)}
                        >
                          {t("confirm_session_date")}
                        </Button>
                      </div>
                    </div>
                  )
                }
              />
            </div>
          )}
          {mentor && (mentor.calendly_username === null || mentor.calendly_username === "") && (
            <p className="text-center">{t("no_link_for_meetings")}</p>
          )}
        {description &&
          description.length > 50 &&
          choosedCategoryIndex &&
          choosedCategoryIndex !== null &&
          mentor &&
          mentor !== null &&
          confirmedDate &&
          confirmedDate !== false && (
            <div className="text-center">
              <Button
                className="btn btn-lg mentoring-session-btns text-white"
                onClick={handleSubmit}
              >
                {t("create_my_session")}
              </Button>
            </div>
          )}
      </Container>
      <div className="mx-auto" style={{ width: "100%" }}>
        {handleShow && selectedMentor && (
          <MentorProfile
            selectedMentor={selectedMentor}
            show={show}
            handleClose={handleClose}
          />
        )}
      </div>
    </Card>
  );
};

export default NewMentoringSession;
