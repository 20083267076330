import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { useSelector } from "react-redux";
import ArticleCard from "components/ArticleCard/ArticleCard";
import NoArticlePicture from "assets/cactus.png";

import "./ArticleSubscriptions.scss";

const ArticleSubscriptions = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { t } = useTranslation("profile");
  const { get, isLoading } = useFetch();
  const [subscriptions, setSubscriptions] = useState([]);

  const getCurrentUserArticleSubscriptions = async () => {
    const response = await get(
      `/users/${currentUser?.id}/article_subscriptions`
    );
    setSubscriptions(response);
  };

  useEffect(() => {
    getCurrentUserArticleSubscriptions();
  }, [currentUser]);

  return (
    <div>
      <h3 className="articles-subscribed-title text-center mb-5">{t("my_memos")}</h3>
      <div>
        {subscriptions?.map((subscription) => (
          <ArticleCard key={subscription.id} article={subscription.article} />
        ))}
        {!isLoading && subscriptions.length === 0 && (
          <div className="no-article-section">
            <p>{t("no_articles_to_display")} </p>
            <img className="no-article-image" src={NoArticlePicture} alt="Aucun article à afficher" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleSubscriptions;
