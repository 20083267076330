import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router";

const CompanyForm = ({ handleChange, setAllCompaniesIds, noLabel }) => {
  const { data, error, isLoading, get } = useFetch();
  const [allCompanies, setAllCompanies] = useState([]);
  const { t } = useTranslation("admin");
  const route = useLocation();

  const getAllCompaniesAndSetIds = async () => {
    const data = await get("/admin/companies");
    setAllCompanies(data);

    if (route.pathname === "/admin/company/usersrequests") {
      let allCompaniesIds = [];

      data.forEach((company) => {
        allCompaniesIds.push(company.id);
      });

      setAllCompaniesIds(allCompaniesIds);
    }
  };

  useEffect(() => {
    getAllCompaniesAndSetIds();
  }, []);

  return (
    <div className="my-4">
      {error && <h4>{error}</h4>}
      {(isLoading && t("loading")) ||
        (allCompanies && allCompanies.length > 0 && (
          <div>
            {noLabel === false && (
              <h6>{t("choose_a_company")}</h6>
            )}
            <Form.Control as="select" className="my-3" onChange={handleChange}>
              <option>{t("choose_a_company")}</option>
              {allCompanies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.id} - {company.name} - {company.email}
                </option>
              ))}
            </Form.Control>
          </div>
        ))}
    </div>
  );
};

export default CompanyForm;
