import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const StandardCompanyForm = ({ companies, noLabel, handleChange }) => {
  const { t } = useTranslation("admin");
  return (
    <div className="my-3">
      {noLabel === false && <h6>{t("choose_a_company")}</h6>}
      <Form.Control as="select" className="my-3" onChange={handleChange}>
        <option>{t("choose_a_company")}</option>
        {companies?.map((company) => (
          <option key={company.id} value={company.id}>
            {company.id} - {company.name} - {company?.email}
          </option>
        ))}
      </Form.Control>
    </div>
  );
};

export default StandardCompanyForm;
