import "./RecoverPassword.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logincover from "assets/covers/right-side-login.png";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  Button,
  Container,
  Image,
} from "react-bootstrap";
import {
  displaySuccess,
  displayWarning,
} from "stores/flashmessages/flashMiddleware";
import useFetch from "hooks/useFetch";
import leftSidePicture from "assets/covers/left-side-login.png";

const RecoverPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { t } = useTranslation("password");
  const { put } = useFetch();
  const dispatch = useDispatch();
  const history = useHistory();
  const reset_password_token = useLocation().search.split("=")[1];

  const recover = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      dispatch(displayWarning(t("password:password_difference")));
      return;
    }
    const data = {
      user: {
        password: password,
        reset_password_token,
      },
    };
    await put("/password", data);
    dispatch(displaySuccess(t("password:changed")));
    history.push("/login");
  };

  return (
    <Container fluid className="RecoverPassword">
      <Row style={{ minHeight: "100%" }}>
        <Col
          xs={12}
          md={6}
          className="RecoverPassword_col d-flex flex-column justify-content-center col-lg-6 col-md-12"
        >
          <Image
            src={leftSidePicture}
            alt="left side login page picture"
            className="left-side-picture"
            height={700}
          />
          <div className="forgot-text-block">
            <h2 className="forgot-password-title p-3">
              {t("password:new_password_creation")}
            </h2>
            <Form
              className="form-div d-flex flex-column"
              onSubmit={recover}
            >
              <FormControl
                className="p-4 mb-1"
                type="password"
                placeholder={t("password:placeholder_new_password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <FormControl
                className="p-4 mb-1"
                type="password"
                placeholder={t("password:placeholder_repeat_new_password")}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />

              <div className="text-center">
                <button
                  type="submit"
                  className="submit-password-btn w-75"
                  size="lg"
                >
                  {t("password:submit")}
                </button>
              </div>
              <p className="already-account mt-4">
                {t("register:accountquestion")}
                <Link to="/login">
                  <span> {t("register:loginnow")}</span>
                </Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col
          md={6}
          className="RecoverPassword_col d-none d-lg-block text-center"
        >
          <Image
            src={logincover}
            alt="Illustration forgot password page"
            height={800}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default RecoverPassword;
