import useFetch from "hooks/useFetch";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ChooseTagsForArticle from "./components/ChooseTagsForArticle";

const AddTagToArticle = ({ articleId, tags, reload }) => {
  const { t } = useTranslation("admin");
  const { get } = useFetch();
  const [allExistingArticleTags, setAllExistingArticleTags] = useState(null);

  const getAllLinkedTags = async () => {
    const response = await get(`/admin/article_tags?article_id=${articleId}`);
    setAllExistingArticleTags(response);
  };

  useEffect(() => {
    getAllLinkedTags();
  }, []);

  useEffect(() => {
    getAllLinkedTags();
  }, [reload]);

  return (
    <div>
      {allExistingArticleTags && allExistingArticleTags !== null &&
        tags &&
        tags.map((tag) => (
          <ChooseTagsForArticle
            key={tag.id}
            tag={tag}
            allExistingArticleTags={allExistingArticleTags}
            articleId={articleId}
            reload={reload}
          />
        ))}
    </div>
  );
};

export default AddTagToArticle;
