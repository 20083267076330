import { Modal, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import UpdateSessionConfirmationDate from "./components/UpdateSessionConfirmationDate/UpdateSessionConfirmationDate";
import UpdateSessionLink from "./components/UpdateSessionLink";
import UpdateSessionReport from "./components/UpdateSessionReport";
import UpdateSessionStatusButton from "./components/UpdateSessionStatusButton";
import "./ModalMentorSessionEdit.scss";

const ModalMentorSessionEdit = ({
  show,
  handleClose,
  session,
  toReload,
  setToReload,
}) => {
  const { t } = useTranslation("profile");
  const reload = () => {
    setToReload(!toReload);
  };

  return (
    <Modal
      dialogClassName="modal-80w"
      aria-labelledby="custom-modal-styling"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("edit_session")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="profile" id="uncontrolled-tab" className="mb-3">
          <Tab eventKey="date" title={t("edit_date")}>
            <UpdateSessionConfirmationDate
              reload={reload}
              handleClose={handleClose}
              session={session}
            />
          </Tab>
          <Tab eventKey="link" title={t("edit_link")}>
            <UpdateSessionLink
              reload={reload}
              handleClose={handleClose}
              session={session}
            />
          </Tab>
          <Tab eventKey="report" title={t("edit_report")}>
            <UpdateSessionReport
              reload={reload}
              session={session}
              handleClose={handleClose}
            />
          </Tab>
          <Tab eventKey="status" title={t("edit_status")}>
            <UpdateSessionStatusButton
              reload={reload}
              session={session}
              handleClose={handleClose}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ModalMentorSessionEdit;
