import Datetime from "react-datetime";
import { useState } from "react";
import "moment/locale/fr";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
import "./UpdateSessionConfirmationDate.scss";

const UpdateSessionConfirmationDate = ({ session, reload, handleClose }) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { patch } = useFetch();
  const { t } = useTranslation("profile");
  const [date, setDate] = useState(null);

  const submitConfirmationDate = (event) => {
    event.preventDefault();
    patch(
      `/users/${currentUser.id}/mentoring_sessions/${session.id}`,
      { session_date: date },
      displaySuccess(t("date_updated")),
      displayError(t("date_not_updated"))
    );
    handleClose();
    reload();
  };

  return (
    <div className="d-flex flex-row justify-content-center">
      <Datetime
        locale="fr-fr"
        closeOnClickOutside={true}
        onChange={(e) => setDate(e.toDate())}
      />
      {date && date !== null && (
        <button
          className="btn btn-warning text-white ml-2"
          onClick={submitConfirmationDate}
        >
          {t("submit_date")}
        </button>
      )}
    </div>
  );
};

export default UpdateSessionConfirmationDate;
