import { useTranslation } from "react-i18next";
import { Form, Button, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import useFetch from "hooks/useFetch";
import MentorExperienceLine from "./components/MentorExperienceLine";

const AddExperienceForm = ({ selectedMentor, notValidMentor, reload }) => {
  const { t } = useTranslation("admin");
  const { post, get } = useFetch();
  const [society, setSociety] = useState("");
  const [job, setJob] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedMentorExperiences, setSelectedMentorExperiences] = useState(
    []
  );

  const handleSubmit = (event) => {
    const data = {
      mentor_profile_id: selectedMentor,
      society_name: society,
      job: job,
      localisation: localisation,
      job_description: jobDescription,
      start_date: startDate,
      end_date: endDate,
    };
    event.preventDefault();
    post(
      "/admin/mentor_experiences",
      data,
      null,
      displaySuccess(t("mentor_experience_created"))
    );
    setSociety("");
    setJob("");
    setLocalisation("");
    setJobDescription("");
    setStartDate("");
    setEndDate("");
    reload();
  };

  useEffect(() => {
    const getSelectedMentorExperiences = async () => {
      const data = await get(
        `/admin/mentor_experiences?profile_id=${selectedMentor}`
      );
      setSelectedMentorExperiences(data);
    };

    getSelectedMentorExperiences();
  }, [selectedMentor, reload]);

  console.log(selectedMentorExperiences);

  return (
    <div className="my-4">
      {(selectedMentor && !notValidMentor && (
        <>
          <p className="font-weight-bold">{t("add_an_experience")} :</p>
          <Form>
            <div className="my-4">
              <Form.Label>{t("society_name")} :</Form.Label>
              <Form.Control
                type="text"
                placeholder="Vinci, buildingSMART..."
                value={society}
                onChange={(e) => setSociety(e.target.value)}
              />
            </div>
            <div className="my-4">
              <Form.Label>{t("job")} :</Form.Label>
              <Form.Control
                type="text"
                placeholder="BIM manager..."
                value={job}
                onChange={(e) => setJob(e.target.value)}
              />
            </div>
            <div className="my-4">
              <Form.Label>{t("localisation")} :</Form.Label>
              <Form.Control
                type="text"
                placeholder="Paris, New york..."
                value={localisation}
                onChange={(e) => setLocalisation(e.target.value)}
              />
            </div>
            <div className="my-4">
              <Form.Label>{t("job_description")} :</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                value={jobDescription}
                onChange={(e) => setJobDescription(e.target.value)}
              />
            </div>
            <div className="my-4">
              <Form.Label>{t("start_date")} :</Form.Label>
              <Form.Control
                type="text"
                placeholder="2020/11/03"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="my-4">
              <Form.Label>{t("end_date")} :</Form.Label>
              <Form.Control
                type="text"
                placeholder="2021/12/05"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            {job &&
              job.length > 2 &&
              society &&
              society.length > 2 &&
              localisation &&
              localisation.length > 2 &&
              jobDescription &&
              jobDescription.length > 2 &&
              startDate &&
              startDate.length > 2 &&
              endDate &&
              endDate.length > 2 && (
                <div className="d-flex row justify-content-center my-4">
                  <Button
                    type="submit"
                    className="ButtonPrimary w-50"
                    onClick={handleSubmit}
                  >
                    {t("send")}
                  </Button>
                </div>
              )}
          </Form>
          {(selectedMentorExperiences &&
            selectedMentorExperiences.length > 0 && (
              <div>
                <p className="font-weight-bold">
                  {t("all_mentor_experiences")} :
                </p>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>{t("title")}</th>
                      <th>{t("job")}</th>
                      <th>{t("delete")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedMentorExperiences &&
                      selectedMentorExperiences.map((experience) => (
                        <MentorExperienceLine
                          key={experience.id}
                          experience={experience}
                          reload={reload}
                        />
                      ))}
                  </tbody>
                </Table>
              </div>
            )) || (
            <p className="font-weight-bold">{t("not_experiences_yet")}</p>
          )}
        </>
      )) || (
        <small className="text-danger">
          {t("choose_profile_to_update_before_adding_an_experience")}
        </small>
      )}
    </div>
  );
};

export default AddExperienceForm;
