import React from "react";
import { Button } from "react-bootstrap";

const AlphabetNav = ({ terms, onLetterClick, selectedLetter }) => {

  const deleteAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const letters = [
    ...new Set(terms.map((term) => deleteAccents(term.title.charAt(2)))),
  ].sort();

  return (
    <div className="d-flex flex-column" style={{position: "fixed"}}>
      {letters.map((letter) => (
        <Button key={letter} variant="" onClick={() => onLetterClick(letter)} style={{padding: 0}}>
          {selectedLetter === letter.toUpperCase() ? (
            <strong style={{fontSize: 38}}>{letter.toUpperCase()}</strong>
          ) : (
            letter.toUpperCase()
          )}
        </Button>
      ))}
    </div>
  );
};

export default AlphabetNav;
