import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import MentoringSessionLine from "./MentoringSessionLine";

const ShowAllMentoringSessions = () => {
  const { get, isLoading } = useFetch();
  const { t } = useTranslation("admin");
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    const getAllMentoringSessions = async () => {
      const data = await get("/admin/mentoring_sessions");
      setSessions(data);
    };
    getAllMentoringSessions();
  }, []);

  return (
    <div>
      {sessions && sessions.length > 0 ? (
        <Table striped bordered hover className="mt-5">
          <thead>
            <tr>
              <th>{t("student")}</th>
              <th>{t("mentor")}</th>
              <th>{t("date")}</th>
              <th>{t("show")}</th>
            </tr>
          </thead>
          <tbody>
            {(sessions &&
              sessions.length > 0 &&
              !isLoading &&
              sessions.map((session) => (
                <MentoringSessionLine key={session.id} session={session} />
              ))) ||
              (isLoading && <p className="p-2">{t("loading")}</p>)}
          </tbody>
        </Table>
      ) : <p className="d-flex justify-content-center">{t("no_sessions_yet_to_display")}</p>}
    </div>
  );
};

export default ShowAllMentoringSessions;
