import { Link, NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { fetchToLogout } from "stores/authentication/authMiddleware";
import Avatar from "../components/Avatar";
import ProfileDisplay from "../components/ProfileDisplay/ProfileDisplay";
import { RiLogoutCircleLine } from "react-icons/ri";
import "./Menu.scss";

const ProfileMenu = () => {
  const token = useSelector((state) => state.auth.token);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isCompany = useSelector((state) => state.auth.isCompany);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const logout = () => {
    dispatch(fetchToLogout(token));
    history.push("/login");
  };

  return (
    <div className="ProfileMenu">
      <div className="card-body text-start content-div">
        <Avatar />
        <ProfileDisplay />
        {!isCompany && (
          <div>
            <NavLink
              to="/profile/dashboard"
              activeClassName="is-active"
              className="user-menu-link"
            >
              <span>🖥️ {t("profile:dashboard")}</span>
            </NavLink>
            <NavLink
              to="/profile/mycourses"
              activeClassName="is-active"
              className="user-menu-link"
            >
              <span>📋 {t("profile:menucourses")}</span>
            </NavLink>
            <NavLink
              to="/profile/myarticlesubscriptions"
              activeClassName="is-active"
              className="user-menu-link"
            >
              <span>📰 {t("profile:my_memos")}</span>
            </NavLink>
            <NavLink
              to="/profile/edit"
              activeClassName="is-active"
              className="user-menu-link"
            >
              <span>🧑 {t("profile:menuedit")}</span>
            </NavLink>
            <NavLink
              to="/profile/signature"
              activeClassName="is-active"
              className="user-menu-link"
            >
              <span>🖊️ {t("profile:manage_my_signature")}</span>
            </NavLink>
            {currentUser?.have_active_bootcamp && (
              <NavLink
                to="/profile/presences"
                activeClassName="is-active"
                className="user-menu-link"
              >
                <span>✅ {t("profile:manage_active_session_presences")}</span>
              </NavLink>
            )}
            {currentUser && currentUser.provider !== "linkedin" && (
              <NavLink
                to="/profile/avatar"
                activeClassName="is-active"
                className="user-menu-link"
              >
                <span>📸 {t("profile:menuavatar")}</span>
              </NavLink>
            )}
          </div>
        )}
        {isCompany && (
          <>
            <NavLink
              to="/profile/edit"
              activeClassName="is-active"
              className="user-menu-link"
            >
              <span>🧑 {t("profile:menuedit")}</span>
            </NavLink>
            <NavLink
              to="/profile/avatar"
              activeClassName="is-active"
              className="user-menu-link"
            >
              <span>📸 {t("profile:menuavatar")}</span>
            </NavLink>
          </>
        )}
        <div className="logout-div">
          <Link className="user-menu-link logout-link" onClick={logout}>
            <RiLogoutCircleLine className="logout-icon" />
            <span className="logout-text">{t("navigation:linkLogout")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileMenu;
