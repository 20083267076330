import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import UserRequestLine from "./UserRequestLine";

const SeeCompanyUserRequests = () => {
  const { t } = useTranslation("company");
  const { get } = useFetch();
  const [requests, setRequests] = useState([]);
  const [solved, setSolved] = useState("");
  const [toReload, setToReload] = useState(false);
  const solvedOptions = [
    { solved: true, label: t("solved") },
    { solved: false, label: t("pending") },
  ];

  const reload = () => {
    setToReload(!toReload);
  };

  const getRequests = async () => {
    const response = await get("/company/company_user_requests");
    setRequests(response);
  };

  useEffect(() => {
    getRequests();
  }, []);

  useEffect(() => {
    getRequests();
  }, [toReload]);

  useEffect(() => {
    const getRequestsByState = async () => {
      const response = await get(
        `/company/company_user_requests?solved=${solved}`
      );
      setRequests(response);
    };
    getRequestsByState();
  }, [solved]);

  const handleChange = (e) => {
    setSolved(e.target.value);
  };

  return (
    <div>
      <div className="text-center mt-3">
        <h3>{t("see_company_user_requests")}</h3>
      </div>
      <Row className="align-items-center mt-5">
        <Col sm={12} md={3} lg={3}>
          {t("filter_by_state")} :
        </Col>
        <Col sm={12} md={9} lg={9}>
          <div>
            <Form.Control as="select" className="my-3" onChange={handleChange}>
              <option>{t("filter_by_state")}</option>
              {solvedOptions.map((option) => (
                <option key={option.solved} value={option.solved}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          </div>
        </Col>
      </Row>
      {requests && requests.length > 0 && (
        <Table striped bordered hover className="my-3">
          <thead>
            <tr>
              <th>{t("admin:email")}</th>
              <th>{t("admin:first_name")}</th>
              <th>{t("admin:last_name")}</th>
              <th>{t("admin:state")}</th>
              <th>{t("admin:edit")}</th>
              <th>{t("admin:show")}</th>
            </tr>
          </thead>
          <tbody>
            {requests.map((request) => (
              <UserRequestLine key={request.id} request={request} reload={reload} />
            ))}
          </tbody>
        </Table>
      )}
      {requests && requests.length === 0 && (
        <p className="text-center mt-3">{t("no_requests_founded")}</p>
      )}
    </div>
  );
};

export default SeeCompanyUserRequests;
