import { useTranslation } from "react-i18next";
import CreateCompanyForm from "./Components/CreateCompanyForm";

const CreateCompany = () => {
  const { t } = useTranslation("admin");

  return (
    <div>
      <div className="text-center">
        <h3>{t("create_company")}</h3>
      </div>
      <div>
        <CreateCompanyForm />
      </div>
    </div>
  );
};

export default CreateCompany;
