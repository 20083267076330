import { useState } from "react";
import { useTranslation } from "react-i18next";
import CourseStructure from "assets/repo_course_structure.png";
import { Form, Button, Row } from "react-bootstrap";
import useFetch from "hooks/useFetch";
import { displaySuccess } from "stores/flashmessages/flashMiddleware";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading";
import CourseForm from "./Components/CourseForm";

const EditCourse = () => {
  const { t } = useTranslation("admin");
  const [course, setCourse] = useState("");
  const { put, error, isLoading } = useFetch();
  const [notionId, setNotionId] = useState();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    put(`/admin/courses/${course}`, { notion_course_id: notionId }, EditSuccess);
  };

  const EditSuccess = () => {
    dispatch(displaySuccess(t("admin:course_edited")));
    history.push("/admin/courses");
  };

  return (
    <div className="EditCourse">
      <div>
        <div>
          <CourseForm handleChange={(event) => setCourse(event.target.value)} />
        </div>
        <Form.Label>{t("notion_page_id")}</Form.Label>
        <Form.Control
          placeholder={t("notion_page_id")}
          onChange={(event) => setNotionId(event.target.value)}
        />
        <Row>
          <Button
            type="button"
            className="btn btn-primary btn-lg m-3 "
            onClick={handleClick}
          >
            {t("validate_update_of_course")}
          </Button>
          {isLoading && <Loading />}
          {error && <p>{error}</p>}
        </Row>
        <h4>{t("how_to_import")}</h4>
        <p>{t("notion_import_explanation")}</p>
      </div>
    </div>
  );
};

export default EditCourse;
