import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Card } from "react-bootstrap";
import { useHistory } from "react-router";
import useFetch from "hooks/useFetch";

const DashboardUsers = () => {
  const { t } = useTranslation("admin");
  const { get } = useFetch();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const getUsers = async () => {
      const data = await get("/admin/users?is_student=true");
      setUsers(data);
    };
    getUsers();
  }, []);

  const displaySelectedUser = (event, userId) => {
    if (userId === undefined) return; 
    setSelectedUser(userId);
    history.push({pathname: `/admin/displayuser/${userId}`, state: {selectedUser: userId} });
  };

  return (
    <div>
      <h1 className="d-flex justify-content-center">
        {t("dashboard_users")} 👀
      </h1>
      <Container>
        <Row>
          {users && users.length > 0 ? (
            users.map((user) => (
              <Card
                className="my-3 mx-2"
                style={{ width: "18rem", cursor: "pointer" }}
                key={user.id}
                onClick={(e) => displaySelectedUser(e, user.id)}
              >
                <Card.Img
                  style={{ height: "250px", objectFit: "cover" }}
                  variant="top"
                  src={
                    user.picture_url
                      ? user.picture_url
                      : "https://cdn-icons-png.flaticon.com/512/3135/3135810.png"
                  }
                />
                <Card.Body>
                  <Card.Title
                    style={{fontWeight: "bolder" }}
                  > {user.first_name} {user.last_name}</Card.Title>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p className="mx-auto">{t("admin:loading")}</p>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default DashboardUsers;
