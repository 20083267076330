import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";

const ChooseMentorForm = ({ existingsMentors, setExistingsMentors, setSelectedMentor }) => {
  const { t } = useTranslation("admin");
  const { get, isLoading, data, post } = useFetch();

  useEffect(() => {
    const getExistingMentorProfiles = async () => {
      const data = await get("/admin/mentor_profiles");
      setExistingsMentors(data);
    };

    getExistingMentorProfiles();
  }, []);
  
  return (
    <div>
      <Form>
        <div className="mt-2 my-4">
          {(!isLoading && existingsMentors && existingsMentors.length > 0 && (
            <div>
              <Form.Label>
                {t("choose_profile_to_update")} :
              </Form.Label>
              <Form.Control
                sm="10"
                as="select"
                onChange={(event) => setSelectedMentor(event.target.value)}
              >
                <option>{t("choose_profile_to_update")}</option>
                {existingsMentors &&
                  existingsMentors.length > 0 &&
                  existingsMentors.map((mentor) => (
                    <option
                      key={mentor.id}
                      value={mentor.id}
                      name={mentor.first_name}
                    >
                      {mentor.id} - {mentor.mentor.first_name} {mentor.mentor.last_name}
                    </option>
                  ))}
              </Form.Control>
            </div>
          )) || <p>{t("loading")}</p>}
        </div>
      </Form>
    </div>
  );
};

export default ChooseMentorForm;
