import { Lottie } from "lottie-react";

export default function LottieAnimation({ lotti, width, height }) {
  const options = {
    loop: true,
    autoplay: true,
    animationData: lotti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie animationData={lotti} style={{ width, height }} {...options} />
  );
}
