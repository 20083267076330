import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const CategoryForm = ({ categories, choosedCategoryIndex, setChoosedCategoryIndex }) => {
  const { t } = useTranslation("profile");
  return (
    <div>
      <Form.Group controlId="SelectedCategory">
        <Form.Control
          as="select"
          value={choosedCategoryIndex}
          onChange={(event) => setChoosedCategoryIndex(event.target.value)}
        >
          <option value="">{t("select_category")}</option>
          {categories.map((category, index) => (
            <option key={category.id} value={index}>
              {category.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export default CategoryForm;
