import React, { useState } from "react";
import useFetch from "hooks/useFetch";
import { AiFillCloseCircle, AiOutlineEdit } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import "./UserLine.scss";
import UserSubscriptionsModal from "../UserSubscriptionsModal/UserSubscriptionsModal";
import UserEditionModal from "../UserEditionModal/UserEditionModal";

const UserLine = ({ user, onDeleteUser, toReload, setToReload }) => {
  const { destroy } = useFetch();
  const { t } = useTranslation("admin");
  const [show, setShow] = useState(false);
  const [editing, setEditing] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => { 
    setShow(false);
    setEditing(false);
  };

  const userDelete = (id) => {
    if (window.confirm(t("are_you_sure"))) {
      destroy(`/admin/users/${id}`, onDeleteUser);
    }
  };

  const handleUserEdition = () => {
    handleShow();
    setEditing(true);
  };

  return (
    <tr key={user.id}>
      <td>{user.first_name}</td>
      <td>{user.last_name}</td>
      <td>{user.email}</td>
      <td>{user.remaining_session}</td>
      <td>{user.role}</td>
      <td className="list-emoji">
        <span className="list-emoji-selected ml-4" onClick={handleShow}>
          🗒️
        </span>
      </td>
      <td>
        <span className="ml-3">
          <AiOutlineEdit
            size={30}
            onClick={handleUserEdition}
            style={{ color: "orange" }}
          />
        </span>
      </td>
      <td>
        {(user.role === "admin" && <p>Admin </p>) || (
          <AiFillCloseCircle
            size={30}
            style={{ color: "red" }}
            onClick={() => userDelete(user.id)}
          />
        )}
      </td>
      {handleShow && !editing && (
        <UserSubscriptionsModal
          show={show}
          handleClose={handleClose}
          user={user}
          onSubscriptionDelete={onDeleteUser}
        />
      )}
      {handleShow && editing && (
        <UserEditionModal show={show} handleClose={handleClose} user={user} toReload={toReload} setToReload={setToReload} />
      )}
    </tr>
  );
};

export default UserLine;
