import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { Col, Row, Image, Table, Form } from "react-bootstrap";
import noavatar from "assets/noavatar.jpg";
import CourseCard from "components/CourseCard/CourseCard";
import LearningPathCard from "components/LearningPathCard/LearningPathCard";
import ProgressLessonLine from "./ProgressLessonLine";

const SelectedUserDashboard = () => {
  const location = useLocation();
  const { get } = useFetch();
  const { t } = useTranslation("admin");
  const [selectedUserId, setSelectedUserId] = useState(
    location.pathname.split("/")[3]
  );
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedUserSubscriptions, setSelectedUserSubscriptions] = useState(
    []
  );
  const [courseId, setCourseId] = useState(null);
  const [selectedCourseError, setSelectedCourseError] = useState(false);
  const [noDuplicateCourse, setNoDuplicateCourse] = useState([]);
  const [selectedCourseObject, setSelectedCourseObject] = useState({});
  const [selectedCourseProgressState, setSelectedCourseProgressState] =
    useState({});

  const getUser = async () => {
    const data = await get(`/admin/users/${selectedUserId}`);
    setSelectedUser(data);
  };

  const getUserSubscriptions = async () => {
    const data = await get(`/admin/subscriptions?user_id=${selectedUserId}`);
    setSelectedUserSubscriptions(data);
  };

  useEffect(() => {
    getUser();
    getUserSubscriptions();
  }, [selectedUserId]);

  const handleChange = (event) => {
    let allCourseIds = [];

    if (selectedUserSubscriptions.length > 0) {
      selectedUserSubscriptions.map((subscription) => {
        subscription.learning_path.courses &&
          subscription.learning_path.courses.map((course) =>
            allCourseIds.push(course.id)
          );
      });
    }

    allCourseIds = [...new Set(allCourseIds)];
    if (!allCourseIds.includes(Number(event.target.value))) {
      setSelectedCourseError(true);
      setCourseId(event.target.value);
    }

    if (allCourseIds.includes(Number(event.target.value))) {
      setSelectedCourseError(false);
      setCourseId(event.target.value);
    }
  };

  useEffect(() => {
    let uniqCoursesSubscriptions = [];

    selectedUserSubscriptions.map((subscription) => {
      subscription.learning_path.courses &&
        subscription.learning_path.courses.map((course) =>
          uniqCoursesSubscriptions.push(course)
        );
    });

    const getUniqueListBy = (arr, key) => {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    };

    setNoDuplicateCourse(getUniqueListBy(uniqCoursesSubscriptions, "id"));
  }, [selectedUserSubscriptions]);

  useEffect(() => {
    if (courseId !== null) {
      setSelectedCourseObject(
        noDuplicateCourse.find(
          (course) => Number(course.id) === Number(courseId)
        )
      );
    }
  }, [courseId]);

  useEffect(() => {
    if (
      selectedCourseObject &&
      Object.values(selectedCourseObject).length > 0
    ) {
      setSelectedCourseProgressState(
        selectedCourseObject.progress_states.find(
          (state) => Number(state.user_id) === Number(selectedUserId)
        )
      );
    }
  }, [selectedCourseObject]);

  console.log(selectedCourseProgressState);

  return (
    <div>
      {selectedUser && selectedUser.first_name && (
        <div>
          <h1 className="d-flex justify-content-center align-items-center">
            {selectedUser.first_name} {selectedUser.last_name}{" "}
            <Image
              src={
                (selectedUser.picture_url && selectedUser.picture_url) ||
                noavatar
              }
              alt="Real avatar"
              className="user-avatar-navbar mx-4"
              style={{
                height: "100px",
                width: "70px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              roundedCircle
            />
          </h1>
          <Row>
            <p className="mx-auto">
              {selectedUser.first_name} {selectedUser.last_name}{" "}
              {t("subscribed_to")} {selectedUser.subscriptions.length}{" "}
              {t("paths")}
            </p>
          </Row>
          <h6>{t("uniq_course_path")} :</h6>
          <Row className="mt-3">
            {selectedUserSubscriptions &&
              selectedUserSubscriptions.length > 0 &&
              selectedUserSubscriptions.map(
                (subscription) =>
                  subscription.learning_path.courses &&
                  subscription.learning_path.is_single_course && (
                    <CourseCard
                      learningPath={subscription.learning_path}
                      key={subscription.id}
                      course={subscription.learning_path.courses[0]}
                      subscribed={true}
                      imgHeight="200px"
                    />
                  )
              )}
          </Row>
          <h6>{t("multiple_courses_path")} :</h6>
          <Row className="mt-3">
            {selectedUserSubscriptions &&
              selectedUserSubscriptions.length > 0 &&
              selectedUserSubscriptions.map(
                (subscription) =>
                  subscription.learning_path.courses &&
                  !subscription.learning_path.is_single_course && (
                    <LearningPathCard
                      key={subscription.learning_path.id}
                      learningPath={subscription.learning_path}
                      subscribed={true}
                      imgHeight="200px"
                    />
                  )
              )}
          </Row>
        </div>
      )}
      {noDuplicateCourse && noDuplicateCourse.length > 0 && (
        <div>
          <Row className="mt-3 d-flex align-items-center">
            <h6>{t("discover_results_for_this_course")} :</h6>
            <Form.Control
              as="select"
              className="mb-3 w-50 mx-auto"
              onChange={(e) => handleChange(e)}
              value={courseId === null ? "" : courseId}
              style={{ border: selectedCourseError ? "1px solid red" : "" }}
            >
              <option>{t("choose_a_course")}</option>
              {noDuplicateCourse &&
                noDuplicateCourse.length > 0 &&
                noDuplicateCourse.map((course) => (
                  <option key={course.id} value={course.id}>
                    {course.id} - {course.title}
                  </option>
                ))}
            </Form.Control>
          </Row>
        </div>
      )}
      {(selectedCourseProgressState &&
        Object.values(selectedCourseProgressState).length > 0 &&
        selectedCourseProgressState.progress_lessons.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t("course_id")}</th>
                <th>{t("lesson_title")}</th>
                <th>{t("quizz_result")}</th>
              </tr>
            </thead>
            <tbody>
              {selectedCourseProgressState.progress_lessons.map(
                (progressLesson) => (
                  <ProgressLessonLine
                    key={progressLesson.id}
                    selectedCourseProgressState={selectedCourseProgressState}
                    progressLesson={progressLesson}
                  />
                )
              )}
            </tbody>
          </Table>
        )) || <p>{t("this_student_dont_have_quizz_answer_yet")}</p>}
    </div>
  );
};
export default SelectedUserDashboard;
