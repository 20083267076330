import { useEffect } from "react";
import useFetch from "hooks/useFetch";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

const LearningPathForm = ({ handleChange }) => {
  const { data, error, isLoading, get } = useFetch();
  const { t } = useTranslation("admin");

  useEffect(() => {
    get("/admin/learning_paths");
    return;
  }, []);

  return (
    <div className="my-4">
      {error && <h4>{error}</h4>}
      {(isLoading && t("loading")) ||
        (data && (
          <div>
            <h6>{t("admin:choose_learning_path")}</h6>
            <Form.Control as="select" className="my-3" onChange={handleChange}>
              <option>{t("admin:choose_learning_path")}</option>
              {data.map((learningPath) => (
                <option key={learningPath.id} value={learningPath.id}>
                  {learningPath.id} - {learningPath.title}
                </option>
              ))}
            </Form.Control>
          </div>
        ))}
    </div>
  );
};

export default LearningPathForm;
