import { useState } from "react";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import {
  displaySuccess,
  displayError,
} from "stores/flashmessages/flashMiddleware";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const freeSubscription = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.currentUser);

  const newFreeSubscription = async (userId, learningPath) => {
    setIsLoading(true);
    setError(null);

    const NEW_FREE_SUBSCRIPTION_URL =
      API_URL + `/users/${currentUser.id}/subscriptions`;

    try {
      const response = await fetch(NEW_FREE_SUBSCRIPTION_URL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          learning_path_id: learningPath,
          user_id: userId,
        }),
      });
      const responseData = await response.json();

      if (!response.ok) {
        throw responseData;
      }
      setIsLoading(false);
      dispatch(displaySuccess(t("admin:subscription_done")));
    } catch (error) {
      const errMessage = error.errors ? error.errors : "An error has occurred.";
      setError(errMessage);
      console.log(errMessage);
      dispatch(displayError(t("admin:subscription_failed")));
    }
  };
  return {
    isLoading,
    error,
    newFreeSubscription,
  };
};

export default freeSubscription;
